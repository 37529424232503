import { Timestamp } from '@angular/fire/firestore';
import { AssetFile } from '../shared';
import { CustomField } from '../shared/custom-field.model';

export enum TenantFeature {
  partnerPortal = 'PARTNER_PORTAL',
  consentManager = 'CONSENT_MANAGER',
}

export enum TenantToggle {
  assetAutoTagModels = 'ASSET_AUTO_TAG_MODELS',
  assetFaceSuggestions = 'ASSET_FACE_SUGGESTIONS',
  videoUpload = 'VIDEO_UPLOAD',
  minorModels = 'MINOR_MODELS',
}

export interface Tenant {
  id?: string;
  name: string;
  creationDate: Timestamp;
  features: TenantFeature[];
  lythoConfig?: {
    apiKey: string;
    lythoUrl: string;
  };
  bynderConfig?: {
    bynderUrl: string;
    clientId: string;
    clientSecret: string;
  };
  fprintApiKey?: string;
  deleted?: boolean;
  validSignConfig?: {
    apiKey: string;
    validSignUrl: string;
  };
  customProjectFields?: CustomField[];
  customModelFields?: CustomField[];
  logo?: AssetFile[];
  websiteUrl?: string;
  projectExpireNotification: number[];
  emailSignature?: string;
  nameSub?: string;
  identityProviders: { type: 'microsoft'; tenantId?: string }[];
  toggles?: TenantToggle[];
}
