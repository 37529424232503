<h4 mat-dialog-title>
  {{ (dialogData?.project ? "base.edit" : "base.add") | translate }}
  {{ "project.project" | translate }}
</h4>

<mat-dialog-content>
  <div class="form" [formGroup]="form">
    <mat-form-field>
      <mat-label>{{ "project.name" | translate }}</mat-label>
      <input matInput type="text" formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "project.description" | translate }}</mat-label>
      <input matInput type="text" formControlName="description" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "project.instructions" | translate }}</mat-label>
      <textarea matInput formControlName="instructions" rows="4"></textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "project.photoshoot-date" | translate }}</mat-label>
      <input
        matInput
        formControlName="shootDate"
        [matDatepicker]="photoshootPicker"
      />
      <mat-hint>DD-MM-YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="photoshootPicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #photoshootPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "project.expiration-date" | translate }}</mat-label>
      <input
        matInput
        formControlName="expirationDate"
        [matDatepicker]="expirationPicker"
      />
      <mat-hint>DD-MM-YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="expirationPicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #expirationPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{
        "model-release-form.model-release-form" | translate
      }}</mat-label>
      <mat-select formControlName="formTemplateIds" multiple>
        <mat-option
          *ngFor="let formTemplate of formTemplates$ | async"
          [value]="formTemplate.id"
          >{{ formTemplate.name }}</mat-option
        >
        <mat-optgroup
          *ngIf="(genericFormTemplates$ | async)?.length > 0"
          [label]="'model-release-form.generic-forms' | translate"
        >
          <mat-option
            *ngFor="let formTemplate of genericFormTemplates$ | async"
            [value]="formTemplate.id"
            >{{ formTemplate.name }}</mat-option
          >
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "project.assignees" | translate }}</mat-label>
      <mat-select formControlName="assignees" multiple>
        <mat-option
          *ngFor="let user of assignableUsers$ | async"
          [value]="user.id"
          >{{ user.firstName }} {{ user.lastName }}</mat-option
        >
      </mat-select>
      <button mat-icon-button matSuffix (click)="onInviteUser($event)">
        <mat-icon>person_add</mat-icon>
      </button>
    </mat-form-field>

    <mat-slide-toggle class="slide-toggle" formControlName="enableRevoke">{{
      "project.enable-revoke" | translate
    }}</mat-slide-toggle>

    <mat-slide-toggle class="slide-toggle" formControlName="resizeImages">{{
      "project.resize-images" | translate
    }}</mat-slide-toggle>

    <mat-slide-toggle
      *ngIf="TenantToggle.minorModels | hasToggle"
      class="slide-toggle"
      formControlName="allowMinors"
      >{{ "project.allow-minors" | translate }}</mat-slide-toggle
    >

    <ng-container
      *ngFor="let field of (tenant$ | async)?.customProjectFields"
      formGroupName="customData"
    >
      <ng-container [ngSwitch]="field.type.toString()">
        <mat-form-field *ngSwitchCase="'NUMBER'">
          <mat-label>{{ field.name }}</mat-label>
          <input matInput type="number" [formControlName]="field.name" />
          <mat-hint *ngIf="field.description">{{ field.description }}</mat-hint>
        </mat-form-field>
        <mat-form-field *ngSwitchCase="'DATE'">
          <mat-label>{{ field.name }}</mat-label>
          <input
            matInput
            [formControlName]="field.name"
            [matDatepicker]="customFieldPicker"
          />
          <mat-hint>{{ field.description || "DD-MM-YYYY" }}</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="customFieldPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #customFieldPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngSwitchCase="'PERMISSIONS'">
          <mat-label>{{ field.name }}</mat-label>
          <mat-select [formControlName]="field.name" multiple>
            <mat-option
              *ngFor="let permission of field.permissions"
              [value]="permission"
              >{{ permission }}</mat-option
            >
          </mat-select>
          <mat-hint *ngIf="field.description">{{ field.description }}</mat-hint>
        </mat-form-field>
        <mat-form-field *ngSwitchCase="'ASSET_DESCRIPTION'">
          <mat-label>{{ field.name }}</mat-label>
          <textarea matInput [formControlName]="field.name" rows="4"></textarea>
          <mat-hint *ngIf="field.description">{{ field.description }}</mat-hint>
        </mat-form-field>
        <mat-form-field *ngSwitchDefault>
          <mat-label>{{ field.name }}</mat-label>
          <input matInput type="text" [formControlName]="field.name" />
          <mat-hint *ngIf="field.description">{{ field.description }}</mat-hint>
        </mat-form-field>
      </ng-container>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="secondary" (click)="onClose()">
    {{ "base.cancel" | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onSave()"
    [disabled]="form.invalid"
  >
    {{ (dialogData?.project ? "base.save" : "base.add") | translate }}
  </button>
</mat-dialog-actions>
