import { Injectable, inject } from '@angular/core';
import { Timestamp, limit, orderBy, where } from '@angular/fire/firestore';
import { CollectionName } from '@verify/shared-components/helpers';
import { Notification } from '@verify/shared-components/models';
import {
  AuthService,
  BatchActionType,
  FirestoreService,
} from '@verify/shared-components/services';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private firestore = inject(FirestoreService);
  private authService = inject(AuthService);

  constructor() {}

  getNotifications(): Observable<Notification[]> {
    return this.firestore.getQuery<Notification>(
      CollectionName.notifications,
      where('userId', '==', this.authService.currentUser.id),
      orderBy('creationDate', 'desc'),
      limit(20),
    );
  }

  getNotificationCounter(): Observable<number> {
    return this.firestore
      .getQuery<Notification>(
        CollectionName.notifications,
        where('userId', '==', this.authService.currentUser.id),
        where('read', '!=', true),
      )
      .pipe(map((notifications) => notifications.length));
  }

  markNotificationsAsRead(notificationIds: string[]): Observable<void> {
    return this.firestore.batchWrite(
      notificationIds.map((notificationId) => ({
        documentPath: `${CollectionName.notifications}/${notificationId}`,
        data: { read: true, readDate: Timestamp.now() },
        type: BatchActionType.update,
      })),
    );
  }
}
