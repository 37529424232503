import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { CustomField, CustomFieldType } from '@verify/shared-components/models';

@Component({
  selector: 'app-edit-custom-field-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatSlideToggleModule,
    MatListModule,
    TranslateModule,
  ],
  templateUrl: './edit-custom-field-dialog.component.html',
  styleUrl: './edit-custom-field-dialog.component.scss',
})
export class EditCustomFieldDialogComponent {
  private dialogRef = inject(MatDialogRef<Partial<CustomField>>);
  private formBuilder = inject(FormBuilder);

  dialogData: { field?: CustomField } = inject(DIALOG_DATA);
  CustomFieldType = CustomFieldType;
  @ViewChild('permissionInput')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissionInput: ElementRef<any>;

  readonly form = this.formBuilder.nonNullable.group({
    name: ['', Validators.required],
    type: ['', Validators.required],
    description: [''],
    required: [false],
    permissions: new FormControl<string[]>([]),
  });

  constructor() {
    if (this.dialogData?.field) {
      this.form.patchValue(this.dialogData.field);
    }
  }

  get customFieldTypes(): CustomFieldType[] {
    return Object.values(CustomFieldType) as CustomFieldType[];
  }

  get permissions(): string[] {
    return this.form.value.permissions.sort((a, b) => (a < b ? -1 : 1));
  }

  onAddPermission(permission: string): void {
    if (!this.form.value.permissions.includes(permission)) {
      this.form.patchValue(
        { permissions: [...this.form.value.permissions, permission] },
        { emitEvent: false },
      );
      this.permissionInput.nativeElement.value = '';
    }
  }

  onRemovePermission(permission: string): void {
    if (this.form.value.permissions.includes(permission)) {
      this.form.patchValue(
        {
          permissions: this.form.value.permissions.filter(
            (p) => p !== permission,
          ),
        },
        { emitEvent: false },
      );
    }
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onSave(): void {
    this.dialogRef.close(this.form.value);
  }
}
