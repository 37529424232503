<ng-container *ngIf="user">
  <mat-toolbar class="layout-toolbar" [class.search-toggle]="searchToggle">
    <div class="layout-toolbar-left">
      <img
        *ngIf="logoUrl$ | async; else logoTemplate"
        class="layout-toolbar-logo"
        [src]="logoUrl$ | async"
        routerLink="/"
      />
      <ng-template #logoTemplate
        ><img
          class="layout-toolbar-logo"
          src="assets/logo-darkblue.png"
          routerLink="/"
      /></ng-template>
    </div>

    <mat-form-field
      subscriptSizing="dynamic"
      class="layout-toolbar-search"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
    >
      <mat-label>{{ "search.search" | translate }}</mat-label>
      <input matInput type="text" [formControl]="searchInput" />
      <button
        matPrefix
        mat-icon-button
        color="primary"
        (click)="onSearch(searchInput.value)"
      >
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <button
      *ngIf="searchToggle"
      mat-icon-button
      class="layout-toolbar-search-icon"
      (click)="onToggleSearch()"
    >
      <mat-icon>{{ "close" }}</mat-icon>
    </button>

    <div class="layout-toolbar-right">
      <button
        mat-icon-button
        class="layout-toolbar-search-icon"
        (click)="onToggleSearch()"
      >
        <mat-icon>{{ "search" }}</mat-icon>
      </button>
      <button
        class="layout-toolbar-button"
        mat-icon-button
        color="primary"
        (click)="onToggleNotifications(); notifications.toggle()"
        [matBadge]="notificationCount$ | async"
        [matBadgeHidden]="(notificationCount$ | async) < 1"
      >
        <mat-icon>notifications</mat-icon>
      </button>
      <div class="layout-toolbar-profile">
        <img
          *ngIf="selfieUrl$ | async; else initialsTemplate"
          class="layout-toolbar-profile-imageicon"
          [src]="selfieUrl$ | async"
        />
        <ng-template #initialsTemplate
          ><div class="layout-toolbar-profile-icon">
            {{ initials }}
          </div></ng-template
        >
        <h6 class="layout-toolbar-profile-name">{{ user | userName }}</h6>
        <button mat-icon-button [matMenuTriggerFor]="settingsMenu">
          <mat-icon> arrow_drop_down </mat-icon>
        </button>
        <mat-menu #settingsMenu="matMenu">
          <button mat-menu-item routerLink="/settings/profile">
            <mat-icon>person</mat-icon>
            {{ "menu.user-profile" | translate }}
          </button>
          <button
            *ngIf="UserRole.projectAdmin | hasRole"
            mat-menu-item
            routerLink="/settings/forms"
          >
            <mat-icon>contract</mat-icon>
            {{ "menu.model-release-forms" | translate }}
          </button>
          <button
            *ngIf="UserRole.projectAdmin | hasRole"
            mat-menu-item
            routerLink="/settings/custom-fields"
          >
            <mat-icon>variable_add</mat-icon>
            {{ "menu.custom-fields" | translate }}
          </button>
          <button
            *ngIf="UserRole.userManagement | hasRole"
            mat-menu-item
            routerLink="/settings/users"
          >
            <mat-icon>group</mat-icon>
            {{ "menu.user-management" | translate }}
          </button>
          <button
            *ngIf="UserRole.userManagement | hasRole"
            mat-menu-item
            routerLink="/settings/deleted"
          >
            <mat-icon>delete_sweep</mat-icon>
            {{ "menu.deleted-items" | translate }}
          </button>
          <button
            *ngIf="UserRole.userManagement | hasRole"
            mat-menu-item
            routerLink="/settings/tenant"
          >
            <mat-icon>settings_account_box</mat-icon>
            {{ "menu.tenant" | translate }}
          </button>
          <button mat-menu-item (click)="onLogout()">
            <mat-icon>logout</mat-icon>
            {{ "menu.logout" | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
    <mat-sidenav-content
      ><div class="layout-content"><router-outlet /></div
    ></mat-sidenav-content>
    <mat-sidenav #notifications position="end"
      ><div class="notifications" *ngIf="notificationsOpen">
        <app-notification
          *ngFor="
            let notification of notifications$ | async;
            trackBy: trackByNotification
          "
          [notification]="notification"
          (closeNotification)="onToggleNotifications()"
        ></app-notification>
        <p
          *ngIf="(notifications$ | async)?.length < 1"
          class="notifications-notfound text-body-2"
        >
          {{ "notification.no-notifications" | translate }}
        </p>
      </div></mat-sidenav
    >
  </mat-sidenav-container>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="searchOpen"
    [cdkConnectedOverlayDisposeOnNavigation]="true"
    [cdkConnectedOverlayFlexibleDimensions]="true"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayPositionStrategy]=""
    [cdkConnectedOverlayPanelClass]="'search-results'"
    [cdkConnectedOverlayViewportMargin]="8"
    (overlayOutsideClick)="onCloseSearch()"
    [cdkConnectedOverlayPositions]="[
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top'
      }
    ]"
  >
    <app-search-results [searchValue]="searchValue"></app-search-results>
  </ng-template>
</ng-container>
