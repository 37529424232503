import { CommonModule } from '@angular/common';
import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Asset, Model, Project } from '@verify/shared-components/models';
import { Observable } from 'rxjs';
import { ModelNamePipe } from '../../../pipes';
import { AssetService, ModelService, ProjectService } from '../../../services';

@Component({
    selector: 'app-search-results',
    imports: [
        CommonModule,
        TranslateModule,
        ModelNamePipe,
        MatIconModule,
        MatBadgeModule,
    ],
    templateUrl: './search-results.component.html',
    styleUrl: './search-results.component.scss'
})
export class SearchResultsComponent implements OnChanges {
  private projectService = inject(ProjectService);
  private modelService = inject(ModelService);
  private assetService = inject(AssetService);
  private router = inject(Router);

  @Input()
  searchValue: string;

  projects$: Observable<{ totalHits: number; projects: Project[] }>;
  models$: Observable<{ totalHits: number; models: Model[] }>;
  assets$: Observable<{ totalHits: number; assets: Asset[] }>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchValue']?.currentValue) {
      this.search();
    }
  }

  onOpenProject(project: Project): void {
    this.router.navigate(['project', project.id]);
  }

  onOpenModel(model: Model): void {
    this.router.navigate(['models'], {
      queryParams: { query: this.searchValue, model: model.id },
    });
  }

  onOpenAsset(asset: Asset): void {
    this.router.navigate(['asset', asset.id]);
  }

  trackById(_: number, { id }: { id?: string }): string {
    return id;
  }

  private search(): void {
    this.projects$ = this.projectService.searchProjects({
      queryString: this.searchValue,
      from: 0,
      size: 4,
    });
    this.models$ = this.modelService.searchModels({
      queryString: this.searchValue,
      from: 0,
      size: 4,
    });
    this.assets$ = this.assetService.searchAssets({
      queryString: this.searchValue,
      from: 0,
      size: 4,
    });
  }
}
