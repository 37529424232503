<app-header [title]="'user.users' | translate"
  ><button mat-stroked-button color="primary" (click)="onAddUser()">
    <mat-icon>add</mat-icon> {{ "user.add-user" | translate }}
  </button></app-header
>

<div class="users-list">
  <div class="users-list-toolbar">
    <button mat-icon-button color="primary" [matMenuTriggerFor]="roleMenu">
      <mat-icon>filter_alt</mat-icon>
    </button>
    <mat-form-field subscriptSizing="dynamic" class="users-list-search">
      <mat-label>{{ "search.search" | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="searchValue"
        type="text"
        (keyup)="onSearch()"
      />
      <button matPrefix mat-icon-button color="primary" (click)="onSearch()">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <mat-chip-set>
      <mat-chip-set>
        <mat-chip *ngFor="let filter of filters">
          <ng-container [ngSwitch]="filter.type">
            <ng-container *ngSwitchCase="'role'">
              {{ filter.value }}
            </ng-container>
            <ng-container *ngSwitchCase="'search'"
              >{{ "search.search" | translate }} "{{
                filter.value
              }}"</ng-container
            >
          </ng-container>
          <button matChipRemove (click)="onRemoveFilter(filter.type)">
            <mat-icon>cancel</mat-icon>
          </button></mat-chip
        >
      </mat-chip-set>
    </mat-chip-set>
  </div>

  <div class="users-list-tablecontainer">
    <table
      mat-table
      matSort
      [dataSource]="filteredUsers$ | async"
      class="users-list-table"
      (matSortChange)="onSortChange($event)"
    >
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "user.first-name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "user.last-name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.lastName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "user.email" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row">
            {{ element.email }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "user.language" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row">
            {{ element.language }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="sso">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>SSO</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row">
            {{ (element.provisioned ? "base.yes" : "base.no") | translate }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef>
          {{ "user.roles" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <p class="roles">{{ element.roles?.join(", ") }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "user.creation-date" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.creationDate
              ? (element.creationDate.toDate() | timestamp: "mediumDate")
              : ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-button (click)="onEditUser(element)">
            {{ "base.edit" | translate }}
          </button>
          <button mat-button (click)="onDeleteUser(element)">
            {{ "base.delete" | translate }}
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        class="users-list-table-header"
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>

<mat-menu #roleMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let role of roles"
    (click)="onAddFilter('role', role)"
  >
    {{ role }}
  </button>
</mat-menu>
