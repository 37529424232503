import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import { Project } from '@verify/shared-components/models';
import { ProjectService } from '../../../services';

@Component({
  selector: 'app-extend-project-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatDialogModule,
    TranslateModule,
    SpinnerComponent,
  ],
  templateUrl: './extend-project-dialog.component.html',
  styleUrl: './extend-project-dialog.component.scss',
})
export class ExtendProjectDialogComponent {
  private dialogRef = inject(MatDialogRef);
  private projectService = inject(ProjectService);
  private formBuilder = inject(FormBuilder);
  private snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);

  dialogData: { project?: Project } = inject(DIALOG_DATA);
  loading = false;

  readonly form = this.formBuilder.nonNullable.group({
    description: [''],
    expirationDate: new FormControl<Date>(null),
  });

  onExtend(): void {
    this.loading = true;
    const { description, expirationDate } = this.form.value;
    this.projectService
      .extendProject(
        this.dialogData.project.id,
        description,
        Timestamp.fromDate(expirationDate),
      )
      .subscribe(() => {
        this.dialogRef.close();
        this.snackBar.open(
          this.translateService.instant('project.extension-request-send'),
          null,
          {
            duration: 3000,
          },
        );
      });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
