import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import {
  Model,
  ModelConsent,
  TenantToggle,
} from '@verify/shared-components/models';
import { HasTogglePipe } from '@verify/shared-components/services';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ModelNamePipe } from '../../../pipes';
import { ModelTileComponent } from '../model-tile/model-tile.component';

@Component({
    selector: 'app-model-select-menu',
    imports: [
        CommonModule,
        MatMenuModule,
        ModelTileComponent,
        ModelNamePipe,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTooltipModule,
        TranslateModule,
        HasTogglePipe,
        NgScrollbarModule,
    ],
    templateUrl: './model-select-menu.component.html',
    styleUrl: './model-select-menu.component.scss'
})
export class ModelSelectMenuComponent implements OnChanges {
  @Input()
  modelConsents?: Array<{ model?: Model; modelConsent: ModelConsent }>;
  @Input()
  multiselect = false;
  @Input()
  selectedModels?: string[];

  @Output()
  selectModel = new EventEmitter<Model>();
  @Output()
  removeModel = new EventEmitter<Model>();
  @Output()
  closeMenu = new EventEmitter<Model>();
  @Output()
  autoTag = new EventEmitter<void>();

  TenantToggle = TenantToggle;
  searchValue = '';
  filteredModelConsents?: Array<{ model?: Model; modelConsent: ModelConsent }>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modelConsents']) {
      this.onSearch();
    }
  }

  onSelectModel(model: Model) {
    if (this.selectedModels?.includes(model.id)) {
      this.removeModel.emit(model);
    } else {
      this.selectModel.emit(model);
    }
  }

  onSearch(): void {
    const searchParts = this.searchValue.toLocaleLowerCase().trim().split(' ');
    this.filteredModelConsents = this.searchValue
      ? this.modelConsents.filter((modelConsent) => {
          const { firstName, lastName, email } = modelConsent.model;
          for (const searchPart of searchParts) {
            const found =
              firstName?.toLowerCase().includes(searchPart) ||
              lastName?.toLowerCase().includes(searchPart) ||
              email?.toLowerCase().includes(searchPart);
            if (found) {
              return true;
            }
          }
          return false;
        })
      : this.modelConsents;
  }

  onAutoTag(): void {
    this.autoTag.emit();
  }

  onClose(): void {
    this.closeMenu.emit();
  }

  trackByModelConsent(
    _: number,
    modelConsent: { modelConsent?: ModelConsent },
  ): string | undefined {
    return modelConsent.modelConsent?.modelId;
  }
}
