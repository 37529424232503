<h1 mat-dialog-title>
  {{
    (dialogData?.field ? "custom-field.edit" : "custom-field.add") | translate
  }}
</h1>

<mat-dialog-content>
  <div class="form" [formGroup]="form">
    <mat-form-field>
      <mat-label>{{ "custom-field.name" | translate }}</mat-label>
      <input matInput type="text" formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "custom-field.type" | translate }}</mat-label>
      <mat-select formControlName="type">
        <mat-option
          *ngFor="let fieldType of customFieldTypes"
          [value]="fieldType"
          >{{ fieldType }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "custom-field.description" | translate }}</mat-label>
      <input matInput type="text" formControlName="description" />
    </mat-form-field>

    <mat-slide-toggle class="slide-toggle" formControlName="required">{{
      "custom-field.required" | translate
    }}</mat-slide-toggle>

    <ng-container *ngIf="form.value.type === CustomFieldType.permissions">
      <mat-form-field>
        <mat-label>{{ "custom-field.add-permission" | translate }}</mat-label>
        <input
          type="text"
          matInput
          #permissionInput
          (keyup.enter)="onAddPermission(permissionInput.value)"
        />
        <mat-icon
          class="clickable-icon"
          matSuffix
          (click)="onAddPermission(permissionInput.value)"
          >add</mat-icon
        >
      </mat-form-field>

      <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let permission of permissions">
          <mat-icon
            class="clickable-icon"
            matListItemIcon
            (click)="onRemovePermission(permission)"
            >delete</mat-icon
          >
          <p>{{ permission }}</p></mat-list-item
        >
      </mat-list>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="secondary" (click)="onClose()">
    {{ "base.cancel" | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onSave()"
    [disabled]="form.invalid"
  >
    {{ (dialogData?.field ? "base.save" : "base.add") | translate }}
  </button>
</mat-dialog-actions>
