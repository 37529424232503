import { AssetFile } from '../shared';
import { ClientTimestamp } from '../shared-types';

export enum ModelConsentStatus {
  pending = 'PENDING',
  signed = 'SIGNED',
  expired = 'EXPIRED',
  extendRequest = 'EXTEND_REQUEST',
  revoked = 'REVOKED',
}

export interface ModelConsent {
  id: string;
  modelId: string;
  projectId?: string;
  projectIds?: string[];
  formTemplateId: string;
  formVariantId?: string;
  status: ModelConsentStatus;
  creationDate: ClientTimestamp;
  signedDate?: ClientTimestamp;
  expirationDate?: ClientTimestamp;
  revokedDate?: ClientTimestamp;
  revokedBy?: string;
  unrevokedBy?: string;
  deleted?: boolean;
  validSignPackageId?: string;
  invited?: boolean;
  pdfFile?: AssetFile;
  generic?: boolean;
}
