<dts-select-container
  #container="dts-select-container"
  [custom]="true"
  [selectWithShortcut]="true"
  [selectedItems]="selectedAssets"
  (select)="onSetSelection($event)"
>
  <div class="assets-header">
    <div class="assets-header-title">
      <h6>{{ "asset.assets" | translate }}</h6>
      <p class="text-body-2">
        {{ filteredAssets?.length }} {{ "base.total" | translate }} -
        {{ statusCounts?.signed }}
        {{ "model.status-signed" | translate }} -
        {{ statusCounts?.pending }}
        {{ "model.status-pending" | translate }} -
        {{ statusCounts?.revoked }}
        {{ "model.status-revoked" | translate }}
      </p>
    </div>
    <div class="assets-header-filters">
      <mat-chip-set>
        <mat-chip *ngFor="let filter of filters">
          <ng-container [ngSwitch]="filter.type">
            <ng-container *ngSwitchCase="'model'">{{
              filter.value | modelName
            }}</ng-container>
            <ng-container *ngSwitchCase="'status'">{{
              "model.status-" + filter.value.toLowerCase() | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'verified'">{{
              (filter.value ? "asset.verified" : "asset.unverified") | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'no-exports'">{{
              "asset.not-exported-assets" | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'no-models'">{{
              "asset.untagged-assets" | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'search'"
              >{{ "search.search" | translate }} "{{
                filter.value
              }}"</ng-container
            >
          </ng-container>
          <button matChipRemove (click)="onRemoveFilter(filter.type)">
            <mat-icon>cancel</mat-icon>
          </button></mat-chip
        >
      </mat-chip-set>
      <div class="assets-header-filters-sort">
        <button
          mat-stroked-button
          color="primary"
          [matMenuTriggerFor]="orderMenu"
        >
          <mat-icon>sort</mat-icon>
          {{ orderByColumn.translation | translate }}
        </button>
        <button
          class="stroked-icon-button-square"
          mat-stroked-button
          color="primary"
          (click)="onOrderByDirection()"
        >
          <mat-icon>{{
            orderBy.direction === "desc"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <button
        class="stroked-icon-button-square"
        mat-stroked-button
        color="primary"
        [matMenuTriggerFor]="filterMenu"
      >
        <mat-icon>filter_list</mat-icon>
      </button>
      <button
        mat-stroked-button
        color="primary"
        class="stroked-icon-button-square list-view-button"
        (click)="onToggleAssetView()"
      >
        <mat-icon>{{
          viewMode === "list" ? "view_list" : "grid_view"
        }}</mat-icon>
      </button>
    </div>
  </div>

  <div class="assets-grid" *ngIf="viewMode === 'tile'; else listView">
    <app-asset-tile
      *ngFor="let asset of filteredAssets; trackBy: trackByAsset"
      [asset]="asset"
      [selected]="selectedAssets.includes(asset.id)"
      [selectable]="true"
      [dtsSelectItem]="asset.id"
      (openAsset)="onOpenAsset($event)"
      (selectAsset)="onSelectAsset($event.id)"
    ></app-asset-tile>
  </div>

  <ng-template #listView>
    <div class="list-container">
      <table
        mat-table
        matSort
        [dataSource]="filteredAssets"
        class="list-container-table"
        (matSortChange)="onSortChange($event)"
      >
        <ng-container matColumnDef="preview">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td class="asset-preview-cell" mat-cell *matCellDef="let element">
            <img
              class="asset-preview"
              appImagePreview
              [files]="element.files"
              [fileSize]="AssetFileSize.size300"
            />
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "asset.name" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "asset.creation-date" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.creationDate
                ? (element.creationDate.toDate() | timestamp: "mediumDate")
                : ""
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="modificationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "asset.modification-date" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.modificationDate
                ? (element.modificationDate.toDate() | timestamp: "mediumDate")
                : ""
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            {{ "asset.status" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.modelConsentStatus?.toLowerCase() || "" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="models">
          <th mat-header-cell *matHeaderCellDef>
            {{ "model.tagged-models" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.modelIds?.length || 0 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="verified">
          <th mat-header-cell *matHeaderCellDef>
            {{ "asset.verified" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              (element.status === AssetStatus.verified ? "base.yes" : "base.no")
                | translate
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="matches">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "tracking.matches" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.matches?.length || 0 }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
          class="list-container-table-header"
        ></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: displayedColumns"
          class="list-container-table-row"
          [dtsSelectItem]="element.id"
          (click)="onOpenAsset(element)"
          [class.assets-list-table-row-selected]="
            selectedAssets.includes(element.id)
          "
        ></tr>
      </table>
    </div>
  </ng-template>
</dts-select-container>

<mat-menu #orderMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let column of sortableColumns"
    (click)="onOrderBy(column.field)"
  >
    {{ column.translation | translate }}
  </button>
</mat-menu>

<mat-menu #filterMenu="matMenu">
  <mat-form-field
    class="asset-search"
    subscriptSizing="dynamic"
    (click)="$event.stopPropagation()"
  >
    <mat-label>{{ "search.search" | translate }}</mat-label>
    <input
      matInput
      type="text"
      [(ngModel)]="searchValue"
      (keyup)="onSearch()"
    />
    <button matSuffix mat-icon-button (click)="onSearch()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.signed)"
  >
    <span class="status-filter-signed"></span
    >{{ "model.status-signed" | translate }}
  </button>
  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.pending)"
  >
    <span class="status-filter-pending"></span
    >{{ "model.status-pending" | translate }}
  </button>
  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.revoked)"
  >
    <span class="status-filter-revoked"></span
    >{{ "model.status-revoked" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('verified', false)">
    <mat-icon>question_mark</mat-icon>
    {{ "asset.unverified" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('verified', true)">
    <mat-icon>check</mat-icon>
    {{ "asset.verified" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('no-exports', null)">
    <mat-icon>ios_share</mat-icon>
    {{ "asset.not-exported-assets" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('no-models', null)">
    <mat-icon>location_home</mat-icon>
    {{ "asset.untagged-assets" | translate }}
  </button>
</mat-menu>
