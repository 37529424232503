import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import {
  FormTemplate,
  Model,
  ModelConsent,
  Project,
} from '@verify/shared-components/models';
import { QRCodeModule } from 'angularx-qrcode';
import { combineLatest, map, Observable, Subject, take, takeUntil } from 'rxjs';
import { ModelNamePipe } from '../../../pipes';
import { ModelService, ProjectService } from '../../../services';
import { FormService } from '../../../services/form.service';
import { ModelConsentService } from '../../../services/model-consent.service';
import { ModelTileComponent } from '../model-tile/model-tile.component';

@Component({
  selector: 'app-add-model-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    QRCodeModule,
    MatSelectModule,
    MatDialogModule,
    TranslateModule,
    MatTabsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerComponent,
    ModelNamePipe,
    ModelTileComponent,
  ],
  templateUrl: './add-model-dialog.component.html',
  styleUrl: './add-model-dialog.component.scss',
})
export class AddModelDialogComponent implements OnDestroy {
  private projectService = inject(ProjectService);
  private formService = inject(FormService);
  private modelService = inject(ModelService);
  private modelConsentService = inject(ModelConsentService);
  private dialogRef = inject(DialogRef<AddModelDialogComponent>);
  private dialogData: { projectId: string; formTemplateIds: string[] } =
    inject(DIALOG_DATA);

  private destroy$ = new Subject<void>();

  project$: Observable<Project>;
  formTemplates$?: Observable<FormTemplate[]>;
  genericFormTemplates$?: Observable<FormTemplate[]>;
  selectedFormTemplateId?: string;
  emailInvites: string[] = [];
  selectedTab: number;
  emailControl = new FormControl('', Validators.email);
  loading = false;
  modelFilter = '';
  genericModelConsents?: { model?: Model; modelConsent: ModelConsent }[];
  filteredModelConsents?: { model?: Model; modelConsent: ModelConsent }[];

  constructor() {
    this.project$ = this.projectService.getProject(this.dialogData.projectId);
    this.selectedFormTemplateId = this.dialogData.formTemplateIds[0];
    const formTemplates$ = this.formService.getFormsById(
      this.dialogData.formTemplateIds,
    );
    this.formTemplates$ = formTemplates$.pipe(
      map((templates) => templates.filter((template) => !template.generic)),
    );
    this.genericFormTemplates$ = formTemplates$.pipe(
      map((templates) => templates.filter((template) => template.generic)),
    );

    combineLatest([
      this.modelConsentService.getGenericModelsAndConsent(),
      this.project$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([modelConsents]) => {
        this.genericModelConsents = modelConsents;
        this.onFilterGenericModels();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get qrCode() {
    return `${window.location.origin}/ext/model-release/${this.dialogData.projectId}/${this.selectedFormTemplateId}`;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onOpenRegistrationForm() {
    window.open(this.qrCode);
  }

  onAddEmailInvite(): void {
    if (this.emailControl.valid && this.emailControl.value) {
      const email = this.emailControl.value;
      if (!this.emailInvites.includes(email)) {
        this.emailInvites = [...this.emailInvites, email];
        this.emailControl.setValue('');
      }
    }
  }

  onRemoveEmailInvite(email: string): void {
    if (this.emailInvites.includes(email)) {
      this.emailInvites = this.emailInvites.filter((p) => p !== email);
    }
  }

  onAddGenericModel(modelConsent: ModelConsent): void {
    this.modelConsentService.addProjectToModelConsent(
      modelConsent,
      this.dialogData.projectId,
    );
  }

  onFilterGenericModels(): void {
    this.project$.pipe(take(1)).subscribe((project) => {
      const genericModelConsents = this.genericModelConsents.filter(
        (modelConsent) =>
          !(project.modelIds || []).includes(modelConsent.model?.id),
      );
      const searchParts = this.modelFilter.trim().split(' ');
      this.filteredModelConsents = this.modelFilter
        ? genericModelConsents.filter((modelConsent) => {
            const { firstName, lastName, email } = modelConsent.model;
            for (const searchPart of searchParts) {
              const found =
                firstName.toLowerCase().includes(searchPart) ||
                lastName.toLowerCase().includes(searchPart) ||
                email.toLowerCase().includes(searchPart);
              if (found) {
                return true;
              }
            }
            return false;
          })
        : genericModelConsents;
    });
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTab = event.index;
  }

  onSendEmailInvites(): void {
    this.onAddEmailInvite();
    this.loading = true;
    this.modelService
      .inviteModelsByEmail({
        projectId: this.dialogData.projectId,
        formTemplateId: this.selectedFormTemplateId,
        formVariantId: 'NL',
        emails: this.emailInvites,
      })
      .subscribe(() => {
        this.dialogRef.close();
      });
  }
}
