import { ComponentType } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialog: MatDialog = inject(MatDialog);

  readonly widths = {
    small: '352px',
    medium: '416px',
    mediumLarge: '624px',
    large: '1440px',
  };
  readonly heights = {
    medium: '60vh',
    mediumLarge: '75vh',
    large: '90vh',
  };

  constructor() {}

  openDialog<T>(
    component: ComponentType<unknown>,
    {
      data,
      width,
      height,
    }: { data?: unknown; width?: string; height?: string },
  ): Observable<T | undefined> {
    return this.dialog
      .open(component, {
        data,
        width,
        height,
      })
      .afterClosed();
  }
}
