<div class="search-group" *ngIf="(projects$ | async)?.totalHits > 0">
  <div class="search-group-header">
    <mat-icon>folder</mat-icon>
    <h6>
      {{ "project.projects" | translate }}
    </h6>
    <div class="search-group-header-total">
      {{ (projects$ | async)?.totalHits }}
    </div>
  </div>
  <div
    *ngFor="let project of (projects$ | async)?.projects; trackBy: trackById"
    class="search-item"
    (click)="onOpenProject(project)"
  >
    {{ project.name }}
  </div>
</div>
<div class="search-group" *ngIf="(models$ | async)?.totalHits > 0">
  <div class="search-group-header">
    <mat-icon>person</mat-icon>
    <h6>
      {{ "model.models" | translate }}
    </h6>
    <div class="search-group-header-total">
      {{ (models$ | async)?.totalHits }}
    </div>
  </div>
  <div
    *ngFor="let model of (models$ | async)?.models; trackBy: trackById"
    class="search-item"
    (click)="onOpenModel(model)"
  >
    {{ model | modelName }}
  </div>
</div>
<div class="search-group" *ngIf="(assets$ | async)?.totalHits > 0">
  <div class="search-group-header">
    <mat-icon>image</mat-icon>
    <h6>
      {{ "asset.assets" | translate }}
    </h6>
    <div class="search-group-header-total">
      {{ (assets$ | async)?.totalHits }}
    </div>
  </div>
  <div
    *ngFor="let asset of (assets$ | async)?.assets; trackBy: trackById"
    class="search-item"
    (click)="onOpenAsset(asset)"
  >
    {{ asset.name }}
  </div>
</div>
<p
  class="no-results"
  *ngIf="
    !(projects$ | async)?.totalHits &&
    !(models$ | async)?.totalHits &&
    !(assets$ | async)?.totalHits
  "
>
  {{ "search.no-results" | translate }}
</p>
