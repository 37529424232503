<app-header [title]="'menu.tenant' | translate"></app-header>

<div class="container form" [formGroup]="form">
  <mat-form-field>
    <mat-label>{{ "tenant.name" | translate }}</mat-label>
    <input matInput type="text" formControlName="name" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ "tenant.name-sub" | translate }}</mat-label>
    <input matInput type="text" formControlName="nameSub" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ "tenant.email-signature" | translate }}</mat-label>
    <textarea matInput type="text" formControlName="emailSignature"></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ "tenant.website-url" | translate }}</mat-label>
    <input matInput type="text" formControlName="websiteUrl" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{
      "tenant.project-expiration-notification" | translate
    }}</mat-label>
    <mat-select formControlName="projectExpireNotification" multiple>
      <mat-option *ngFor="let day of notificationDays" [value]="day"
        >{{ day }} {{ "base.days" | translate }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <input
    class="file-input"
    type="file"
    #logo
    name="logo"
    accept="image/*"
    capture="user"
    (change)="onSelectLogo($event)"
  />
  <button
    class="logo-button"
    mat-stroked-button
    color="secondary"
    (click)="logo.click()"
  >
    {{ "tenant.logo" | translate }}
  </button>
  <img
    *ngIf="logoPreview; else logoImage"
    class="logo-preview"
    [src]="logoPreview"
  />
  <ng-template #logoImage
    ><img *ngIf="imageUrl$" class="logo-preview" [src]="imageUrl$ | async"
  /></ng-template>

  <div class="form-footer">
    <button
      mat-flat-button
      color="primary"
      (click)="onSave()"
      [disabled]="form.invalid"
    >
      {{ "base.save-changes" | translate }}
    </button>
  </div>
</div>

<app-header
  [title]="'fprint.usage' | translate"
  [hideBackButton]="true"
></app-header>

<div class="container form" [formGroup]="form">
  <table mat-table [dataSource]="usage$ | async" class="usage-table">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>{{ "fprint.date" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex-row">
          {{ getDate(element) | date: "MMM y" }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef>
        {{ "fprint.created" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.created }}</td>
    </ng-container>

    <ng-container matColumnDef="removed">
      <th mat-header-cell *matHeaderCellDef>
        {{ "fprint.removed" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.removed }}</td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>
        {{ "fprint.total" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.total }}
      </td>
    </ng-container>

    <ng-container matColumnDef="quota">
      <th mat-header-cell *matHeaderCellDef>
        {{ "fprint.quota" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.quota }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
