import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime } from 'rxjs';
import { SearchResultsComponent } from '../search-results/search-results.component';

@Component({
  selector: 'app-search',
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    TranslateModule,
    SearchResultsComponent,
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent implements OnInit {
  private dialogRef = inject(MatDialogRef<void>);

  searchToggle = false;
  searchOpen = false;

  searchInput = new FormControl('');
  searchValue = '';

  ngOnInit(): void {
    this.searchInput.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (value) {
        this.onSearch(value);
      } else {
        this.onCloseSearch();
      }
    });
  }

  onSearch(searchValue: string): void {
    this.searchValue = searchValue;
    this.searchOpen = true;
  }

  onCloseSearch(): void {
    this.dialogRef.close();
  }
}
