import { Component } from '@angular/core';

@Component({
  selector: 'app-ai-prompt',
  standalone: true,
  imports: [],
  templateUrl: './ai-prompt.component.html',
  styleUrl: './ai-prompt.component.scss'
})
export class AiPromptComponent {

}
