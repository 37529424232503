import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserNamePipe } from '@verify/shared-components/helpers';
import {
  AssetFileSize,
  Notification,
  TenantInfo,
  User,
} from '@verify/shared-components/models';
import {
  AssetUrlPipe,
  AuthService,
  DialogService,
} from '@verify/shared-components/services';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollReached } from 'ngx-scrollbar/reached-event';
import { filter, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import tinycolor from 'tinycolor2';
import { NotificationService } from '../../services';
import { NotificationComponent } from './notification/notification.component';
import { SearchComponent } from './search/search.component';

interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Component({
  selector: 'app-layout',
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,

    MatSidenavModule,
    UserNamePipe,
    MatBadgeModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    NotificationComponent,
    TranslateModule,
    NgScrollbarModule,
    NgScrollReached,
    AssetUrlPipe,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit, OnDestroy {
  private authService = inject(AuthService);
  private notificationService = inject(NotificationService);
  private translateService = inject(TranslateService);
  private dialogService = inject(DialogService);

  private destroy$ = new Subject<void>();

  AssetFileSize = AssetFileSize;
  notificationCount$: Observable<number>;
  notifications$: Observable<Notification[]>;
  notifications: Notification[];
  notificationsOpen = false;
  searchToggle = false;
  searchOpen = false;

  searchInput = new FormControl('');
  searchValue = '';
  notificationMax = 20;
  menuHover = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuTimer: any;
  defaultPrimaryColor = '#60769b';

  ngOnInit(): void {
    this.authService.tenant$
      .pipe(takeUntil(this.destroy$))
      .subscribe((tenant) => {
        this.savePrimaryAndAccentColor(tenant?.brandColor);
      });
    this.authService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter((user) => !!user),
      )
      .subscribe((currentUser) => {
        if (
          currentUser?.language &&
          this.translateService.currentLang !== currentUser.language
        ) {
          this.translateService.use(currentUser.language.toLocaleLowerCase());
        }
        this.notificationCount$ =
          this.notificationService.getNotificationCounter();
        this.notifications$ = this.notificationService.getNotifications();
        this.onLoadNotifications();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get tenantInfo$(): Observable<TenantInfo> {
    return this.authService.tenantInfo$;
  }

  get user(): User {
    return this.authService.currentUser;
  }

  get initials(): string {
    if (this.user) {
      const { firstName, lastName, email } = this.user;
      if (firstName || lastName) {
        return `${firstName?.substring(0, 1)}${lastName?.substring(0, 1)}`;
      } else {
        return email.substring(0, 1);
      }
    } else {
      return '';
    }
  }

  onMenuEnter(): void {
    this.menuTimer = setTimeout(() => {
      this.menuHover = true;
    }, 500);
  }

  onMenuLeave(): void {
    clearTimeout(this.menuTimer);
    this.menuHover = false;
  }

  onSearch(searchValue: string): void {
    this.searchValue = searchValue;
    this.searchOpen = true;
  }

  onCloseSearch(): void {
    this.searchOpen = false;
  }

  onToggleNotifications(): void {
    this.notificationsOpen = !this.notificationsOpen;
    if (this.notificationsOpen) {
      this.notifications$.pipe(take(1)).subscribe((notifications) => {
        const unreadNotifications = notifications.filter(
          (notification) => !notification.read,
        );
        if (unreadNotifications.length > 0) {
          this.notificationService.markNotificationsAsRead(
            unreadNotifications.map((notification) => notification.id),
          );
        }
      });
    }
  }

  onToggleSearch(): void {
    //this.searchToggle = !this.searchToggle;
    this.dialogService.openDialog<void>(SearchComponent, {
      width: this.dialogService.widths.mediumLarge,
      data: {},
      autoFocus: 'input',
    });
  }

  onLoadNotifications(): void {
    this.notifications$ = this.notificationService
      .getNotifications(this.notificationMax)
      .pipe(
        tap((notifications) => {
          this.notifications = notifications;
        }),
      );
    this.notificationMax += 20;
  }

  trackByNotification(_: number, notification: Notification): string {
    return notification.id;
  }

  private savePrimaryAndAccentColor(primaryColor?: string) {
    const dynamicColorPalette = this.computeThemePalette(
      primaryColor || this.defaultPrimaryColor,
    );

    dynamicColorPalette.forEach((color) => {
      document.documentElement.style.setProperty(
        `--theme-dynamic-palette-${color.name}`,
        color.hex,
      );
      document.documentElement.style.setProperty(
        `--theme-dynamic-palette-contrast-${color.name}`,
        color.darkContrast ? 'rgba(black, 0.87)' : 'white',
      );
    });
  }

  private computeThemePalette(primaryHex: string): Color[] {
    // Uses tinycolor library to get lighter and darker versions of primary
    // and accent colors, so that we can make a full theme palette from them
    return [
      this.getColorObject(tinycolor(primaryHex).lighten(52), '50'),
      this.getColorObject(tinycolor(primaryHex).lighten(37), '100'),
      this.getColorObject(tinycolor(primaryHex).lighten(26), '200'),
      this.getColorObject(tinycolor(primaryHex).lighten(12), '300'),
      this.getColorObject(tinycolor(primaryHex).lighten(6), '400'),
      this.getColorObject(tinycolor(primaryHex), '500'),
      this.getColorObject(tinycolor(primaryHex).darken(6), '600'),
      this.getColorObject(tinycolor(primaryHex).darken(12), '700'),
      this.getColorObject(tinycolor(primaryHex).darken(18), '800'),
      this.getColorObject(tinycolor(primaryHex).darken(24), '900'),
      // this.getColorObject(tinycolor(accentHex).lighten(50), 'A100'),
      // this.getColorObject(tinycolor(accentHex), 'A200'),
      // this.getColorObject(tinycolor(accentHex).darken(20), 'A400'),
      // this.getColorObject(tinycolor(accentHex).darken(50), 'A700'),
    ];
  }

  private getColorObject(value: tinycolor.Instance, name: string): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight(), // determine if the contrast for this color should be black or white
    };
  }
}
