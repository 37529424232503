import { inject, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class MatPaginatorIntlProvider implements MatPaginatorIntl {
  private translateService: TranslateService = inject(TranslateService);

  changes = new Subject<void>();

  get firstPageLabel(): string {
    return this.translateService.instant('pagination.first-page');
  }
  get itemsPerPageLabel(): string {
    return this.translateService.instant('pagination.items-per-page');
  }
  get lastPageLabel(): string {
    return this.translateService.instant('pagination.last-page');
  }
  get nextPageLabel(): string {
    return this.translateService.instant('pagination.next-page');
  }
  get previousPageLabel(): string {
    return this.translateService.instant('pagination.previous-page');
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translateService.instant('pagination.range', {
        page: 1,
        amountPages: 1,
      });
    }
    const amountPages = Math.ceil(length / pageSize);
    return this.translateService.instant('pagination.range', {
      page: page + 1,
      amountPages,
    });
  }
}
