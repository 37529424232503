<app-header [title]="'custom-field.project-fields' | translate"
  ><button mat-stroked-button color="primary" (click)="onAddProjectField()">
    <mat-icon>add</mat-icon> {{ "custom-field.add" | translate }}
  </button></app-header
>

<table mat-table [dataSource]="projectfields$ | async" class="bordered-table">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>
      {{ "custom-field.name" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>
      {{ "custom-field.type" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.type }}
    </td>
  </ng-container>

  <ng-container matColumnDef="required">
    <th mat-header-cell *matHeaderCellDef>
      {{ "custom-field.required" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element.required">check</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell class="field-actions" *matCellDef="let element">
      <button mat-button (click)="onEditProjectField(element)">
        {{ "base.edit" | translate }}
      </button>
      <button mat-button (click)="onRemoveProjectField(element)">
        {{ "base.remove" | translate }}
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<app-header
  [title]="'custom-field.model-fields' | translate"
  [hideBackButton]="true"
  ><button mat-stroked-button color="primary" (click)="onAddModelField()">
    <mat-icon>add</mat-icon> {{ "custom-field.add" | translate }}
  </button></app-header
>

<table mat-table [dataSource]="modelfields$ | async" class="bordered-table">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>
      {{ "custom-field.name" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>
      {{ "custom-field.type" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.type }}
    </td>
  </ng-container>

  <ng-container matColumnDef="required">
    <th mat-header-cell *matHeaderCellDef>
      {{ "custom-field.required" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element.required">check</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell class="field-actions" *matCellDef="let element">
      <button mat-button (click)="onEditModelField(element)">
        {{ "base.edit" | translate }}
      </button>
      <button mat-button (click)="onRemoveModelField(element)">
        {{ "base.remove" | translate }}
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
