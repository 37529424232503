<app-model-tile
  class="model-info-selfie"
  [model]="model"
  [modelConsent]="modelConsent"
  (selectModel)="onSelectModel()"
></app-model-tile>
<div class="model-info-title">
  <h6>{{ model | modelName }}</h6>
  <p class="text-body-2 model-info-small">
    @if (modelConsent.generic) {
      {{ "model.generic-model" | translate }} -
    }
    {{ "model.status-" + modelConsent.status.toLowerCase() | translate }}
  </p>
  <p class="text-body-2 model-info-large" *ngIf="modelConsent.generic">
    {{ "model.generic-model" | translate }}
  </p>
</div>
<div class="status-tag-dark model-info-large">
  <mat-icon
    [class]="
      'status-tag-icon status-tag-icon-' + modelConsent.status.toLowerCase()
    "
    *ngIf="modelConsent.status !== ModelConsentStatus.signed"
    >error</mat-icon
  >
  <span>{{
    "model.status-" + modelConsent.status.toLowerCase() | translate
  }}</span>
</div>
<div class="status-tag-dark model-info-large" *ngIf="!cancelOption">
  <mat-icon class="status-tag-icon">language</mat-icon>
  <span>{{ matchCount }}</span>
</div>
<button
  class="model-info-large"
  mat-stroked-button
  color="primary"
  (click)="onOpenContract()"
>
  <mat-icon>search</mat-icon>
  {{ "model.contract" | translate }}
</button>
<ng-container *ngIf="UserRole.projectManagement | hasRole">
  <ng-container [ngSwitch]="modelConsent.status">
    <button
      *ngSwitchCase="ModelConsentStatus.signed"
      class="model-info-large"
      mat-icon-button
      color="primary"
      [matTooltip]="'model.revoke-consent' | translate"
      (click)="onRevoke()"
    >
      <mat-icon>contract_delete</mat-icon>
    </button>
    <button
      *ngSwitchCase="ModelConsentStatus.revoked"
      class="model-info-large"
      mat-icon-button
      color="primary"
      [matTooltip]="'model.unrevoke-consent' | translate"
      (click)="onUnrevoke()"
    >
      <mat-icon>contract_edit</mat-icon>
    </button>
    <button
      *ngSwitchDefault
      class="model-info-large"
      mat-icon-button
      color="primary"
      [matTooltip]="'model.send-reminder' | translate"
      (click)="onSendReminder()"
    >
      <mat-icon>forward_to_inbox</mat-icon>
    </button>
  </ng-container>
</ng-container>
<button
  class="model-info-large"
  mat-icon-button
  color="primary"
  [matTooltip]="'base.remove' | translate"
  [disabled]="!canRemove(modelConsent)"
  (click)="onRemove()"
>
  <mat-icon>{{ cancelOption ? "close" : "delete" }}</mat-icon>
</button>

<button
  mat-stroked-button
  color="primary"
  class="stroked-icon-button-square model-info-small"
  [matMenuTriggerFor]="modelMenu"
>
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #modelMenu="matMenu">
  <button mat-menu-item (click)="onOpenContract()">
    {{ "model.contract" | translate }}
  </button>
  <ng-container *ngIf="UserRole.projectManagement | hasRole">
    <ng-container [ngSwitch]="modelConsent.status">
      <button
        *ngSwitchCase="ModelConsentStatus.signed"
        mat-menu-item
        (click)="onRevoke()"
      >
        {{ "model.revoke-consent" | translate }}
      </button>
      <button
        *ngSwitchCase="ModelConsentStatus.revoked"
        mat-menu-item
        (click)="onUnrevoke()"
      >
        {{ "model.unrevoke-consent" | translate }}
      </button>
      <button *ngSwitchDefault mat-menu-item (click)="onSendReminder()">
        {{ "model.send-reminder" | translate }}
      </button>
    </ng-container>
  </ng-container>
  <button mat-menu-item (click)="onRemove()">
    {{ "base.remove" | translate }}
  </button></mat-menu
>
