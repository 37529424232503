import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, HostListener, inject, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import { StoragePath } from '@verify/shared-components/helpers';
import { Asset } from '@verify/shared-components/models';
import { StorageService } from '@verify/shared-components/services';
import { Observable, take, tap } from 'rxjs';
import { AssetService } from '../../services';
import { AssetTileComponent } from '../shared/asset-tile/asset-tile.component';
import { HeaderComponent } from '../shared/header/header.component';

@Component({
  selector: 'app-assets',
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    AssetTileComponent,
    HeaderComponent,
    SpinnerComponent,
  ],
  templateUrl: './assets.component.html',
  styleUrl: './assets.component.scss',
})
export class AssetsComponent implements OnInit {
  private assetService = inject(AssetService);
  private storageService = inject(StorageService);
  private router = inject(Router);
  private document = inject(DOCUMENT);

  assets$: Observable<{ totalHits: number; assets: Asset[] }>;

  searchInput = new FormControl('');
  loading = false;

  ngOnInit(): void {
    console.log('init');

    // this.searchInput.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
    //   if (value) {
    //     this.onSearch(value);
    //   } else {
    //     this.onCloseSearch();
    //   }
    // });
  }

  onSearch(): void {
    this.loading = true;
    this.assets$ = this.assetService
      .searchAssetsKnn({
        queryString: this.searchInput.value,
        from: 0,
        size: 30,
      })
      .pipe(tap(() => (this.loading = false)));
  }

  onImageSearch(event: unknown): void {
    const files = (event as { target: { files: FileList } }).target.files;
    if (files.length > 0) {
      this.loading = true;
      const file = files[0];

      const uploadTask = this.storageService.uploadFile(
        `${StoragePath.tmp}/${file.name}`,
        file,
        'search',
      );
      uploadTask.on('state_changed', null, null, () => {
        console.log(uploadTask.snapshot.ref.fullPath);
        // Upload completed successfully, now we can get the download URL
        // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        //   console.log('File available at', downloadURL);
        // });
        this.assets$ = this.assetService.searchAssetsKnn({
          queryImage: uploadTask.snapshot.ref.fullPath,
          from: 0,
          size: 30,
        });

        this.assets$.pipe(take(1)).subscribe(() => {
          console.log('search done');
          this.loading = false;
        });
      });
      // this.loading = true;
      // this.assets$ = this.assetService
      //   .searchAssetsKnn({
      //     queryString: this.searchInput.value,
      //     from: 0,
      //     size: 30,
      //   })
      //   .pipe(tap(() => (this.loading = false)));
    }
  }

  onOpenAsset(asset: Asset): void {
    this.router.navigate(['asset', asset.id]);
  }

  trackByAsset(_: number, asset: Asset): string {
    return asset.id;
  }

  @HostListener('window:scroll')
  onScroll(): void {
    const scrollPosition =
      this.document.documentElement.scrollTop || this.document.body.scrollTop;
    const windowHeight = this.document.body.clientHeight;
    const threshold = 200;

    console.log({
      scrollPosition,
      scrollY: window.scrollY,
      scrollHeight: this.document.documentElement.scrollHeight,
      windowHeight,
    });
    if (
      scrollPosition + windowHeight >=
      this.document.documentElement.scrollHeight - threshold
    ) {
      console.log('load more');
    }
  }
}
