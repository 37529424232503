<div class="header">
  <div class="header-back-button" *ngIf="!hideBackButton">
    <button mat-icon-button [routerLink]="backUrl">
      <mat-icon>chevron_left</mat-icon>
    </button>
  </div>
  <div class="header-title" [class.header-long-title]="longTitle">
    {{ title }}<ng-content select="[title]"></ng-content>
  </div>
  <div class="header-buttons">
    <ng-content></ng-content>
  </div>
</div>
