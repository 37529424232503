import { Observable } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AssetFileSize,
  Model,
  ModelConsent,
  ModelConsentStatus,
} from '@verify/shared-components/models';
import { StorageService } from '@verify/shared-components/services';

@Component({
    selector: 'app-model-tile',
    imports: [CommonModule],
    templateUrl: './model-tile.component.html',
    styleUrl: './model-tile.component.scss'
})
export class ModelTileComponent implements OnChanges {
  private storageService = inject(StorageService);

  @Input({ required: true })
  model!: Model;
  @Input()
  modelConsent?: ModelConsent;
  @Input()
  size: 'small' | 'medium' | 'zoomable' = 'medium';

  @Output()
  selectModel = new EventEmitter<Model>();

  @HostBinding('class')
  hostClasses: string;

  imageUrl$?: Observable<string>;
  imageLoaded = false;

  ngOnChanges(changes: SimpleChanges): void {
    const file =
      this.model.selfie?.find(
        (file) =>
          file.size ===
          (this.size === 'medium'
            ? AssetFileSize.size200
            : AssetFileSize.size100),
      ) || this.model.selfie?.[0];
    if (
      file &&
      `${changes['model']?.currentValue?.selfie?.[0]?.fileName}` !==
        `${changes['model']?.previousValue?.selfie?.[0]?.fileName}`
    ) {
      this.imageUrl$ = this.storageService.getDownloadUrl(file);
      this.imageLoaded = true;
    } else {
      this.imageLoaded = true;
    }
    this.onImageLoad();
  }

  onImageLoad(): void {
    this.hostClasses = `${this.size} ${this.imageLoaded ? 'image-loaded' : ''} status-${(this.modelConsent?.status || ModelConsentStatus.signed).toLowerCase()}`;
  }

  onSelectModel(): void {
    this.selectModel.emit(this.model);
  }
}
