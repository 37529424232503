<img class="background-image" src="assets/login-background.jpg" />
<div class="login-container">
  <img class="logo-image" src="assets/verify-logo-white.png" />
  <firebase-ui
    *ngIf="isTenantSet"
    (signInSuccessWithAuthResult)="successCallback()"
    (signInFailure)="errorCallback($event)"
    (uiShown)="uiShownCallback()"
  ></firebase-ui>
</div>
