import { BehaviorSubject, combineLatest, map, Observable, tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import {
  Asset,
  AssetExport,
  AssetFileSize,
  AssetStatus,
  Model,
  ModelConsent,
} from '@verify/shared-components/models';
import {
  AuthService,
  StorageService,
  TimestampPipe,
} from '@verify/shared-components/services';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

import { ModelConsentService } from '../../../services/model-consent.service';
import { ModelTileComponent } from '../model-tile/model-tile.component';

@Component({
  selector: 'app-asset-tile',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    ModelTileComponent,
    MatCheckboxModule,
    TranslateModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    TimestampPipe,
  ],
  templateUrl: './asset-tile.component.html',
  styleUrl: './asset-tile.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AssetTileComponent implements OnChanges, OnInit {
  private storageService = inject(StorageService);
  private modelConsentService = inject(ModelConsentService);
  private changeDetector = inject(ChangeDetectorRef);
  private authService = inject(AuthService);

  @Input({ required: true })
  asset!: Asset;
  @Input()
  selectable?: boolean;
  @Input()
  @HostBinding('class.asset-selected')
  selected?: boolean;

  @Output()
  openAsset = new EventEmitter<Asset>();
  @Output()
  selectAsset = new EventEmitter<Asset>();

  @HostBinding('class')
  class = 'asset';

  imageUrl$?: Observable<string>;
  loadingPercentage$?: Observable<number | undefined>;
  modelsIds$ = new BehaviorSubject<string[]>([]);
  modelConsent$?: Observable<
    Array<{ model?: Model; modelConsent: ModelConsent }> | undefined
  >;
  disableCheckbox = false;

  constructor() {}

  ngOnInit(): void {
    this.loadingPercentage$ = this.storageService.uploads$.pipe(
      tap(() => {
        this.changeDetector.detectChanges();
      }),
      map(
        (uploads) =>
          uploads.find((upload) => upload.fileId === this.asset.id)?.percentage,
      ),
    );

    this.modelConsent$ = combineLatest([
      this.modelsIds$,
      this.modelConsentService.getModelsAndConsent(this.asset.projectId),
    ]).pipe(
      map(([modelIds, modelConsents]) =>
        modelIds?.length > 0
          ? modelConsents.filter((modelConsent) =>
              modelIds.includes(modelConsent.model?.id),
            )
          : [],
      ),
    );
  }

  ngOnChanges(): void {
    const file = this.asset.files?.find(
      (file) => file.size == AssetFileSize.size300,
    );
    if (file && !this.imageUrl$) {
      this.imageUrl$ = this.storageService.getDownloadUrl(file);
    }
    if (this.asset?.modelTags) {
      this.modelsIds$.next(
        this.asset?.modelTags.map((modelTag) => modelTag.modelId),
      );
    }
  }

  get isLoading(): boolean {
    return this.asset.status === AssetStatus.uploading;
  }

  onOpenAsset(event: MouseEvent): void {
    if (!event.metaKey && !event.ctrlKey) {
      this.openAsset.emit(this.asset);
    }
  }

  onSelectAsset(event: MouseEvent): void {
    if (!event.metaKey && !event.ctrlKey) {
      this.selectAsset.emit(this.asset);
    }
  }

  onExportClick(assetExport: AssetExport): void {
    if (assetExport.type === 'LYTHO') {
      window.open(
        `${this.authService.tenant?.lythoConfig?.lythoUrl}/dam/asset/${assetExport.externalId}`,
      );
    } else if (assetExport.type === 'BYNDER') {
      window.open(
        `${this.authService.tenant?.bynderConfig?.bynderUrl}/media/?mediaId=${assetExport.externalId}`,
      );
    }
  }

  trackByModelConsent(_: number, modelConsent: { model?: Model }): string {
    return modelConsent.model.id;
  }
}
