<app-header [title]="'model.models' | translate">
  <button
    *ngIf="UserRole.projectManagement | hasRole"
    mat-stroked-button
    color="primary"
    (click)="onAddModel()"
  >
    <mat-icon>add</mat-icon> {{ "model.add-model" | translate }}
  </button>
</app-header>

<div class="models-list">
  <div class="models-list-toolbar">
    <button mat-icon-button color="primary" [matMenuTriggerFor]="columnMenu">
      <mat-icon>tune</mat-icon>
    </button>
    <!-- <button mat-icon-button color="primary" [matMenuTriggerFor]="orderMenu">
      <mat-icon>filter_list</mat-icon>
    </button> -->
    <mat-form-field subscriptSizing="dynamic" class="models-list-search">
      <mat-label>{{ "search.search" | translate }}</mat-label>
      <input
        matInput
        #searchInput
        type="text"
        (keyup)="onSearchKeyUp($event, searchInput.value)"
      />
      <button
        matPrefix
        mat-icon-button
        color="primary"
        (click)="onSearch(searchInput.value)"
      >
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <mat-chip-set>
      <mat-chip *ngIf="searchValue"
        >{{ searchValue }}
        <button matChipRemove (click)="onSearch()">
          <mat-icon>cancel</mat-icon>
        </button></mat-chip
      >
    </mat-chip-set>
    <lib-spinner
      matPrefix
      class="models-list-spinner"
      *ngIf="isSearching"
      [size]="30"
    ></lib-spinner>
  </div>

  <div class="models-list-tablecontainer">
    <table
      mat-table
      matSort
      multiTemplateDataRows
      [dataSource]="modelsDatasource$ | async"
      (matSortChange)="onSortChange($event)"
      class="models-list-table"
    >
      <ng-container matColumnDef="selfie">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.model" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="model-info">
          <app-model-tile
            class="model-info-selfie"
            [model]="element.model"
            size="small"
          ></app-model-tile>
          <div class="model-info-title">
            <p class="text-body-2">
              {{ element.model | modelName }}
            </p>
            <p
              class="text-caption"
              *ngIf="
                (element.genericModelConsent$ | async) || element.model.minor
              "
            >
              @if (element.genericModelConsent$ | async) {
                <span>{{ "model.generic-model" | translate }}</span>
              }
              @if (element.model.minor) {
                <span>{{ "model.minor" | translate }}</span>
              }
            </p>
          </div>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.first-name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.model.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.last-name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.model.lastName }}
        </td>
      </ng-container> -->

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.email" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.model.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="genericConsent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.generic-model" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            ((element.genericModelConsent$ | async) ? "base.yes" : "base.no")
              | translate
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.creation-date" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.model.creationDate
              ? (element.model.creationDate.toDate() | timestamp: "mediumDate")
              : ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="modificationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.modification-date" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.model.modificationDate
              ? (element.model.modificationDate.toDate()
                | timestamp: "mediumDate")
              : ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="expirationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.expiration-date" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            (element.expirationDate$ | async)
              ? (element.expirationDate$ | async | timestamp: "mediumDate")
              : ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="projecten">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "project.projects" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.model.projectIds?.length || 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pendingForms">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.status-pending" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.pendingForms$ | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="revokedForms">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.status-revoked" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.revokedForms$ | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="assets">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "asset.assets" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.assetCount$ | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="matches">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "tracking.matches" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.assetMatches$ | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="minor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.minor" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element.model.minor ? "base.yes" : "base.no") | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="parentName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.parent-name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.model.parentName }}
        </td>
      </ng-container>

      <ng-container
        *ngFor="let customField of tenant?.customModelFields"
        [matColumnDef]="customField.name"
      >
        <th mat-header-cell *matHeaderCellDef>
          {{ customField.name }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            customField.type === "DATE"
              ? (getCustomValue(element.model, customField.name)
                | timestamp: "mediumDate")
              : getCustomValue(element.model, customField.name)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <!-- <button mat-button (click)="onEditUser(element)">
        {{ "base.edit" | translate }}
      </button>
      <button mat-button (click)="onDeleteUser(element)">
        {{ "base.delete" | translate }}
      </button> -->
          <button
            mat-icon-button
            aria-label="expand row"
            (click)="onOpenModel(element.model?.id); $event.stopPropagation()"
          >
            @if (expandedModel === element.model?.id) {
              <mat-icon>keyboard_arrow_up</mat-icon>
            } @else {
              <mat-icon>keyboard_arrow_down</mat-icon>
            }
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            class="models-list-table-detail-content"
            [@detailExpand]="
              element.model?.id === expandedModel ? 'expanded' : 'collapsed'
            "
          >
            <ng-container
              *ngFor="
                let modelConsentProjects of modelConsentsProjects$ | async;
                trackBy: trackByModelConsentId
              "
            >
              <div class="model-consent">
                <div class="model-consent-project-title">
                  <i *ngIf="modelConsentProjects.modelConsent.generic">
                    {{ "model-release-form.generic" | translate }}
                  </i>
                  <h6 *ngIf="!modelConsentProjects.modelConsent.generic">
                    {{ modelConsentProjects.projects?.[0]?.name }}
                  </h6>
                </div>
                <div class="status-tag-dark">
                  <mat-icon
                    [class]="
                      'status-tag-icon status-tag-icon-' +
                      modelConsentProjects.modelConsent.status.toLowerCase()
                    "
                    *ngIf="
                      modelConsentProjects.modelConsent.status !==
                      ModelConsentStatus.signed
                    "
                    >error</mat-icon
                  >
                  <span>{{
                    "model.status-" +
                      modelConsentProjects.modelConsent.status.toLowerCase()
                      | translate
                  }}</span>
                </div>
                <ng-container *ngIf="UserRole.projectManagement | hasRole">
                  <ng-container
                    [ngSwitch]="modelConsentProjects.modelConsent.status"
                  >
                    <button
                      *ngSwitchCase="ModelConsentStatus.signed"
                      class="model-info-large"
                      mat-icon-button
                      color="primary"
                      [matTooltip]="'model.revoke-consent' | translate"
                      (click)="
                        onRevokeModelConsent(modelConsentProjects.modelConsent)
                      "
                    >
                      <mat-icon>contract_delete</mat-icon>
                    </button>
                    <button
                      *ngSwitchCase="ModelConsentStatus.revoked"
                      class="model-info-large"
                      mat-icon-button
                      color="primary"
                      [matTooltip]="'model.unrevoke-consent' | translate"
                      (click)="
                        onUnrevokeModelConsent(
                          modelConsentProjects.modelConsent
                        )
                      "
                    >
                      <mat-icon>contract_edit</mat-icon>
                    </button>
                    <button
                      *ngSwitchDefault
                      class="model-info-large"
                      mat-icon-button
                      color="primary"
                      [matTooltip]="'model.send-reminder' | translate"
                      (click)="
                        onSendReminder(modelConsentProjects.modelConsent)
                      "
                    >
                      <mat-icon>forward_to_inbox</mat-icon>
                    </button>
                  </ng-container>
                </ng-container>
                <button
                  mat-stroked-button
                  color="primary"
                  (click)="
                    onOpenContract(
                      modelConsentProjects.projects[0],
                      modelConsentProjects.modelConsent,
                      element.model
                    )
                  "
                >
                  <mat-icon>search</mat-icon>
                  {{ "model.contract" | translate }}
                </button>
                <button
                  *ngIf="!modelConsentProjects.modelConsent.generic"
                  mat-stroked-button
                  color="primary"
                  (click)="
                    onGoToProject(
                      modelConsentProjects.projects?.[0],
                      expandedModel
                    )
                  "
                >
                  <mat-icon>arrow_forward</mat-icon>
                  {{ "project.to-project" | translate }}
                </button>
              </div>
              <ng-container *ngIf="modelConsentProjects.modelConsent.generic">
                <div
                  class="model-consent-project"
                  *ngFor="
                    let project of modelConsentProjects.projects;
                    trackBy: trackByProjectId
                  "
                >
                  <div class="model-consent-project-title">
                    <h6>
                      {{ project.name }}
                    </h6>
                  </div>
                  <button
                    mat-stroked-button
                    color="primary"
                    (click)="onGoToProject(project, expandedModel)"
                  >
                    <mat-icon>arrow_forward</mat-icon>
                    {{ "project.to-project" | translate }}
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <tr
        mat-header-row
        class="models-list-table-header"
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        (click)="onOpenModel(element.model?.id)"
        class="models-list-table-row"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="models-list-table-detail-row"
      ></tr>
    </table>
  </div>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    [pageIndex]="currentPage"
    [pageSize]="itemsPerPage"
    [length]="totalItems"
    (page)="onHandlePageEvent($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>

<mat-menu #columnMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let column of availableColumns"
    (click)="$event.stopPropagation()"
  >
    <mat-checkbox
      [checked]="displayedColumns.includes(column.name)"
      [indeterminate]="false"
      (change)="onToggleColumn(column.name)"
    >
    </mat-checkbox>
    {{ column.translation | translate }}
  </button>
</mat-menu>
