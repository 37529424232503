import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { User, UserRole } from '@verify/shared-components/models';

import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import { AuthService } from '@verify/shared-components/services';
import { UserService } from '../../../../services';

@Component({
    selector: 'app-edit-user-dialog',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDialogModule,
        SpinnerComponent,
        TranslateModule,
    ],
    templateUrl: './edit-user-dialog.component.html',
    styleUrl: './edit-user-dialog.component.scss'
})
export class EditUserDialogComponent {
  private dialogRef = inject(MatDialogRef<string | boolean>);
  private formBuilder = inject(FormBuilder);
  private userService = inject(UserService);
  private authService = inject(AuthService);

  dialogData: { user?: User; basicInvite?: boolean } = inject(DIALOG_DATA);

  readonly form = this.formBuilder.nonNullable.group({
    firstName: [''],
    lastName: [''],
    email: ['', [Validators.required, Validators.email]],
    language: ['EN'],
    roles: new FormControl<UserRole[]>([]),
    inviteText: [''],
  });

  languages = ['EN', 'NL'];
  loading = false;

  constructor() {
    if (this.dialogData?.user) {
      this.form.patchValue(this.dialogData.user);
    }
  }

  get roles(): UserRole[] {
    return this.dialogData?.basicInvite
      ? this.authService.currentUser.roles
      : (Object.values(UserRole) as UserRole[]);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onSave(): void {
    this.loading = true;
    const { firstName, lastName, email, language, roles, inviteText } =
      this.form.value;
    if (this.dialogData?.user) {
      const user = {
        ...this.dialogData.user,
        firstName,
        lastName,
        email,
        language,
        roles,
      };
      this.userService.updateUser(user).subscribe(() => {
        this.loading = false;
        this.dialogRef.close(user.id);
      });
    } else {
      this.userService
        .addUser({ firstName, lastName, email, language, roles }, inviteText)
        .subscribe((response) => {
          this.loading = false;
          this.dialogRef.close(response.userId);
        });
    }
  }
}
