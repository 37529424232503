import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import {
  Asset,
  AssetFileSize,
  Model,
  ModelConsent,
  Project,
} from '@verify/shared-components/models';
import { StorageService } from '@verify/shared-components/services';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { AssetService, ModelService } from '../../../services';

@Component({
  selector: 'app-project-tile',
  imports: [CommonModule, TranslateModule, MatIconModule],
  templateUrl: './project-tile.component.html',
  styleUrl: './project-tile.component.scss',
})
export class ProjectTileComponent implements OnChanges {
  private storageService = inject(StorageService);
  private modelService = inject(ModelService);
  private assetService = inject(AssetService);

  @Input({ required: true })
  project!: Project;

  @Output()
  deleteProject = new EventEmitter<Project>();
  @Output()
  openProject = new EventEmitter<Project>();

  imageUrls$?: Observable<string[]>;
  modelConsent$?: Observable<
    Array<{ model?: Model; modelConsent: ModelConsent }> | undefined
  >;
  models$?: Observable<Model[]>;
  assets$?: Observable<Asset[]>;
  matchesFound$?: Observable<number>;

  ngOnChanges(): void {
    if (this.project && !this.models$) {
      this.models$ = this.modelService.getModelsByProject(this.project.id);
      this.assets$ = this.assetService.getAssets({
        projectId: this.project.id,
      });
      this.matchesFound$ = this.assets$.pipe(
        map((assets) =>
          assets.reduce(
            (total, asset) => total + (asset.matches?.length || 0),
            0,
          ),
        ),
      );
      this.imageUrls$ = this.assets$.pipe(
        distinctUntilChanged((a, b) => a?.length !== b?.length),
        switchMap((assets) => {
          return combineLatest(
            (assets || []).slice(0, 3).map((asset, index) => {
              const file =
                asset.files?.find(
                  (file) =>
                    file.size ===
                    (index === 0
                      ? AssetFileSize.size1000
                      : AssetFileSize.size300),
                ) || this.project.banner?.[0];
              return file ? this.storageService.getDownloadUrl(file) : of('');
            }),
          );
        }),
      );
    }
  }

  onDeleteProject(): void {
    this.deleteProject.emit(this.project);
  }

  onOpenProject(): void {
    this.openProject.emit(this.project);
  }

  trackByModelConsent(
    _: number,
    modelConsent: { modelConsent: ModelConsent },
  ): string {
    return modelConsent.modelConsent.modelId;
  }

  trackByAsset(_: number, asset: Asset): string {
    return asset.id;
  }
}
