import { QueryDocumentSnapshot } from '@angular/fire/firestore';

export enum CollectionName {
  users = 'users',
  tenants = 'tenants',
  projects = 'projects',
  assets = 'assets',
  models = 'models',
  forms = 'forms',
  notifications = 'notifications',
  modelConsents = 'modelConsents',
}

export const typedConverter = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
});
