<app-header [title]="'user.personal-information' | translate"> </app-header>

<div class="container form" [formGroup]="form">
  <mat-form-field>
    <mat-label>{{ "user.first-name" | translate }}</mat-label>
    <input matInput type="text" formControlName="firstName" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ "user.last-name" | translate }}</mat-label>
    <input matInput type="text" formControlName="lastName" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ "user.email" | translate }}</mat-label>
    <input matInput type="text" formControlName="email" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ "user.language" | translate }}</mat-label>
    <mat-select formControlName="language">
      <mat-option *ngFor="let language of languages" [value]="language">{{
        language
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <input
    class="file-input"
    type="file"
    #selfie
    name="selfie"
    accept="image/*"
    capture="user"
    (change)="onSelectSelfie($event)"
  />
  <button
    class="selfie-button"
    mat-stroked-button
    color="secondary"
    (click)="selfie.click()"
  >
    <mat-icon>photo_camera</mat-icon>{{ "user.take-selfie" | translate }}
  </button>
  <img
    *ngIf="selfiePreview; else selfieImage"
    class="selfie-preview"
    [src]="selfiePreview"
  />
  <ng-template #selfieImage
    ><img *ngIf="imageUrl$" class="selfie-preview" [src]="imageUrl$ | async"
  /></ng-template>

  <div class="form-footer">
    <button
      mat-flat-button
      color="primary"
      (click)="onSave()"
      [disabled]="form.invalid"
    >
      {{ "base.save-changes" | translate }}
    </button>
  </div>
</div>

<div class="header">
  <div class="header-title">{{ "user.password" | translate }}</div>
</div>

<div class="container form" [formGroup]="passwordForm">
  <mat-form-field>
    <mat-label>{{ "user.password-new" | translate }}</mat-label>
    <input matInput type="password" formControlName="password" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ "user.password-repeat" | translate }}</mat-label>
    <input matInput type="password" formControlName="repeatPassword" />
  </mat-form-field>

  <div class="form-footer">
    <button
      mat-flat-button
      color="primary"
      (click)="onUpdatePassword()"
      [disabled]="passwordForm.invalid"
    >
      {{ "user.password-update" | translate }}
    </button>
  </div>
</div>
