import { map, Observable } from 'rxjs';

import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  ClientTimestamp,
  CustomFieldType,
  FormTemplate,
  Project,
  TenantToggle,
  User,
} from '@verify/shared-components/models';

import { Timestamp } from '@angular/fire/firestore';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import {
  AuthService,
  DialogService,
  HasTogglePipe,
} from '@verify/shared-components/services';
import { FormService, ProjectService, UserService } from '../../../services';
import { EditUserDialogComponent } from '../../settings/users/edit-user-dialog/edit-user-dialog.component';

@Component({
    selector: 'app-edit-project-dialog',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatIconModule,
        TranslateModule,
        HasTogglePipe,
    ],
    templateUrl: './edit-project-dialog.component.html',
    styleUrl: './edit-project-dialog.component.scss'
})
export class EditProjectDialogComponent implements OnInit {
  private dialogRef = inject(
    MatDialogRef<{ project: Partial<Project>; banner?: File }>,
  );
  private formService = inject(FormService);
  private projectService = inject(ProjectService);
  private userService = inject(UserService);
  private formBuilder = inject(FormBuilder);
  private authService = inject(AuthService);
  private dialogService = inject(DialogService);

  dialogData: { project?: Project } = inject(DIALOG_DATA);

  formTemplates$?: Observable<FormTemplate[]>;
  genericFormTemplates$?: Observable<FormTemplate[]>;
  assignableUsers$?: Observable<User[]>;
  tenant$ = this.authService.tenant$;

  readonly form = this.formBuilder.nonNullable.group({
    name: ['', Validators.required],
    description: [''],
    instructions: [''],
    shootDate: new FormControl<Date>(null),
    expirationDate: new FormControl<Date>(null),
    formTemplateIds: new FormControl<string[]>([], Validators.minLength(1)),
    assignees: new FormControl<string[]>([]),
    enableRevoke: [true],
    resizeImages: [true],
    allowMinors: [false],
    customData: new FormGroup(
      (this.authService.tenant.customProjectFields || []).reduce(
        (acc, customField) => ({
          ...acc,
          [customField.name]: new FormControl(
            '',
            customField.required ? [Validators.required] : [],
          ),
        }),
        {},
      ),
    ),
  });
  // banner?: File;
  CustomFieldType = CustomFieldType;
  TenantToggle = TenantToggle;

  constructor() {
    const formTemplates$ = this.formService.getForms();
    this.formTemplates$ = formTemplates$.pipe(
      map((templates) => templates.filter((template) => !template.generic)),
    );
    this.genericFormTemplates$ = formTemplates$.pipe(
      map((templates) => templates.filter((template) => template.generic)),
    );
    this.assignableUsers$ = this.userService.getUsers();
  }

  ngOnInit(): void {
    if (this.dialogData?.project) {
      this.form.patchValue({
        ...this.dialogData.project,
        shootDate: this.dialogData.project.shootDate?.toDate(),
        expirationDate: this.dialogData.project.expirationDate?.toDate(),
        enableRevoke: this.dialogData.project.enableRevoke || false,
        resizeImages: this.dialogData.project.resizeImages || false,
        allowMinors: this.dialogData.project.allowMinors || false,
        customData: this.dialogData.project.customData?.reduce(
          (acc, data) => ({
            ...acc,
            [data.name]: (data.value as ClientTimestamp).toDate
              ? (data.value as ClientTimestamp).toDate()
              : data.value,
          }),
          {},
        ),
      });
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    const project = {
      ...this.form.value,
      shootDate: this.form.value.shootDate
        ? Timestamp.fromDate(this.form.value.shootDate)
        : null,
      expirationDate: this.form.value.expirationDate
        ? Timestamp.fromDate(this.form.value.expirationDate)
        : null,
      customData: Object.entries(this.form.value.customData).map(
        ([name, value]) => ({
          name,
          value: value as string | number | ClientTimestamp,
        }),
      ),
    };
    if (project.name !== null && project.formTemplateIds) {
      if (this.dialogData?.project) {
        this.projectService
          .updateProject({
            ...this.dialogData.project,
            ...project,
          })
          .subscribe(() => {
            this.dialogRef.close();
          });
      } else {
        this.projectService.addProject(project).subscribe(() => {
          this.dialogRef.close();
        });
      }
    }
  }

  // onSelectBanner(event: unknown): void {
  //   const files = (event as { target: { files: FileList } }).target.files;
  //   if (files.length > 0) {
  //     this.banner = files[0];
  //   }
  // }

  onInviteUser(event: MouseEvent): void {
    event.stopPropagation();
    this.dialogService
      .openDialog<string>(EditUserDialogComponent, {
        width: this.dialogService.widths.medium,
        data: {
          basicInvite: true,
        },
      })
      .subscribe((userId) => {
        console.log(userId);
        if (userId) {
          this.form.patchValue({
            assignees: [...this.form.value.assignees, userId],
          });
        }
      });
  }
}
