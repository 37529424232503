<div class="project-tile" (click)="onOpenProject()">
  <h6 class="project-tile-name">{{ project.name }}</h6>

  <div class="project-tile-assets">
    <div class="project-tile-assets-asset">
      <img
        class="project-tile-assets-asset"
        [src]="(imageUrls$ | async)?.[0] || 'assets/placeholder.svg'"
      />
    </div>

    <div
      class="project-tile-assets-sub"
      *ngIf="(imageUrls$ | async)?.length > 1"
    >
      <div class="project-tile-assets-asset">
        <img
          class="project-tile-assets-asset"
          [src]="(imageUrls$ | async)?.[1] || 'assets/placeholder.svg'"
        />
      </div>
      <div class="project-tile-assets-asset">
        <img
          class="project-tile-assets-asset"
          [src]="(imageUrls$ | async)?.[2] || 'assets/placeholder.svg'"
        />
      </div>
    </div>
  </div>

  <div class="project-tile-footer">
    <mat-icon>person</mat-icon>
    <span
      >{{ (models$ | async)?.length || 0 }} {{ "base.total" | translate }}</span
    >
    <mat-icon>image</mat-icon>
    <span
      >{{ (assets$ | async)?.length || 0 }} {{ "base.total" | translate }}</span
    >
    <div class="status-tag-dark">
      <mat-icon class="status-tag-icon">language</mat-icon>
      <span>{{ matchesFound$ | async }}</span>
    </div>
  </div>
</div>
