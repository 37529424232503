import { firebaseUiAuthConfig } from '@verify/shared-components/helpers';
import { AuthService } from '../auth.service';

export const firebaseUiConfig = (authService: AuthService) => {
  const uiConfig = firebaseUiAuthConfig;

  for (const identityProvider of authService.tenant?.identityProviders || []) {
    switch (identityProvider.type) {
      case 'microsoft': {
        uiConfig.signInOptions.push({
          provider: 'microsoft.com',
          customParameters: {
            tenant: identityProvider.tenantId || 'common',
          },
        });
        break;
      }
    }
  }

  return uiConfig;
};
