import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@verify/shared-components/models';

/**
 * Returns the display name for given user
 *
 * Usage:
 *  {user} | userName
 */
@Pipe({
  name: 'userName',
  standalone: true,
})
export class UserNamePipe implements PipeTransform {
  transform(user: User): string {
    if (!user) {
      return '';
    }
    return user.firstName || user.lastName
      ? [user.firstName, user.lastName].filter((part) => !!part).join(' ')
      : user.email;
  }
}
