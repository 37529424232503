import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  Asset,
  AssetFileSize,
  MessageType,
  Model,
  Notification,
  Project,
} from '@verify/shared-components/models';
import {
  StorageService,
  TimestampPipe,
} from '@verify/shared-components/services';
import { filter, Observable, switchMap, take } from 'rxjs';
import { ModelNamePipe } from '../../../pipes';
import { AssetService, ModelService, ProjectService } from '../../../services';

@Component({
  selector: 'app-notification',
  imports: [
    CommonModule,
    ModelNamePipe,
    TimestampPipe,
    MatIcon,
    TranslateModule,
  ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
})
export class NotificationComponent implements OnInit {
  private router = inject(Router);
  private projectService = inject(ProjectService);
  private modelService = inject(ModelService);
  private assetService = inject(AssetService);
  private storageService = inject(StorageService);

  project$: Observable<Project>;
  model$: Observable<Model>;
  asset$: Observable<Asset>;
  imageUrl$?: Observable<string>;

  @Input({ required: true })
  notification: Notification;

  @Output()
  closeNotification = new EventEmitter<void>();

  MessageType = MessageType;

  ngOnInit(): void {
    if (!Array.isArray(this.notification.variables)) {
      if (this.notification.variables['projectId']) {
        this.project$ = this.projectService.getProject(
          this.notification.variables['projectId'],
        );
      }
      if (this.notification.variables['modelId']) {
        this.model$ = this.modelService.getModel(
          this.notification.variables['modelId'],
        );
      }
      if (this.notification.variables['assetId']) {
        this.asset$ = this.assetService.getAsset(
          this.notification.variables['assetId'],
        );
        this.imageUrl$ = this.asset$.pipe(
          filter((asset) => !!asset),
          switchMap((asset) => {
            const file =
              asset?.files?.find(
                (file) => file.size == AssetFileSize.size300,
              ) || asset?.files?.[0];
            return this.storageService.getDownloadUrl(file);
          }),
        );
      }
    }
  }

  @HostListener('click') onClick() {
    if (!Array.isArray(this.notification.variables)) {
      switch (this.notification.type) {
        case MessageType.modelRevokeConsent:
          this.router.navigate(
            ['project', this.notification.variables['projectId']],
            {
              queryParams: { modelId: this.notification.variables['modelId'] },
            },
          );
          return;
        case MessageType.modelAssetDecline:
          this.router.navigate([
            'asset',
            this.notification.variables['assetId'],
          ]);
          return;
        case MessageType.assetFingerprintFailed:
          this.asset$.pipe(take(1)).subscribe((asset) => {
            this.router.navigate(['project', asset.projectId]);
          });
          return;
      }
      this.closeNotification.emit();
    }
  }

  get multipleVariables(): Array<{ [key: string]: string }> {
    return Array.isArray(this.notification.variables)
      ? this.notification.variables
      : [];
  }
}
