<mat-toolbar class="layout-toolbar">
  @let logoUrl = tenantInfo?.logo | assetUrl: AssetFileSize.size300 | async;
  <img
    class="layout-toolbar-logo"
    [src]="logoUrl ? logoUrl : 'assets/logo-darkblue.png'"
  />
</mat-toolbar>

<div class="layout-content">
  <router-outlet />
</div>
