import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-header',
    imports: [CommonModule, MatIconModule, MatButtonModule, RouterModule],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
  @Input()
  backUrl: string | string[] = '/';

  @Input()
  hideBackButton = false;

  @Input()
  title: string;

  @Input()
  longTitle: boolean;
}
