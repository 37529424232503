<img
  *ngIf="imageUrl$; else placeholder"
  class="model-image"
  [src]="imageUrl$ | async"
  (load)="onImageLoad()"
  (click)="onSelectModel()"
  loading="lazy"
/>

<ng-template #placeholder>
  <div class="model-image-placeholder" (click)="onSelectModel()">
    {{ model.email?.[0] }}
  </div>
</ng-template>
