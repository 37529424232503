import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AuthModule } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@verify/shared-components/services';
import { FirebaseUIModule, FirebaseUISignInFailure } from 'firebaseui-angular';

//TODO: get rid of firebaseui https://github.com/firebase/quickstart-js, https://github.com/firebase/snippets-web/tree/master/auth, https://firebase.google.com/docs/auth/web/start

@Component({
  selector: 'app-login',
  imports: [FirebaseUIModule, AuthModule, CommonModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  private authService = inject(AuthService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  constructor() {
    const { tenantId } = this.activatedRoute.snapshot.queryParams;
    if (tenantId) {
      console.log(`set temporary tenant: ${tenantId}`);
      this.authService.getAuth().signOut();
      this.authService.getAuth().tenantId = tenantId;
    }
  }

  get isTenantSet(): boolean {
    return (
      !!this.authService.getAuth().tenantId || !!this.authService.isRootUser
    );
  }

  successCallback() {
    console.log('login success');
    this.router.navigateByUrl('/');
  }

  errorCallback(errorData: FirebaseUISignInFailure) {
    console.log('login error');
    console.log(errorData);
  }

  uiShownCallback() {
    console.log('login shown');
  }
}
