<div class="page-content">
  <app-header [title]="'model.models' | translate">
    <button
      *ngIf="UserRule.projectEditing | hasRule"
      mat-flat-button
      color="primary"
      (click)="onAddModel()"
    >
      <mat-icon>add</mat-icon> {{ "model.add-model" | translate }}
    </button>
  </app-header>

  <div class="list-container">
    <div class="list-container-toolbar">
      <button mat-icon-button color="primary" [matMenuTriggerFor]="columnMenu">
        <mat-icon>tune</mat-icon>
      </button>
      <button mat-icon-button color="primary" [matMenuTriggerFor]="filterMenu">
        <mat-icon>filter_list</mat-icon>
      </button>
      <!-- <button mat-icon-button color="primary" [matMenuTriggerFor]="orderMenu">
      <mat-icon>filter_list</mat-icon>
    </button> -->
      <!-- <mat-form-field subscriptSizing="dynamic" class="models-list-search">
      <mat-label>{{ "search.search" | translate }}</mat-label>
      <input
        matInput
        #searchInput
        type="text"
        (keyup)="onSearchKeyUp($event, searchInput.value)"
      />
      <button
        matPrefix
        mat-icon-button
        color="primary"
        (click)="onSearch(searchInput.value)"
      >
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field> -->
      <mat-chip-set>
        <mat-chip *ngFor="let filter of filters">
          <ng-container [ngSwitch]="filter.type">
            <ng-container *ngSwitchCase="'status'">{{
              "model.status-" + filter.value.toLowerCase() | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'matches'">{{
              (filter.value ? "model.matches-found" : "model.matches-not-found")
                | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'model-type'">{{
              (filter.value ? "model.generic-model" : "model.project-model")
                | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'minor-adult'">{{
              (filter.value ? "model.minor" : "model.adult") | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="'search'"
              >{{ "search.search" | translate }} "{{
                filter.value
              }}"</ng-container
            >
          </ng-container>
          <button matChipRemove (click)="onRemoveFilter(filter.type)">
            <mat-icon>cancel</mat-icon>
          </button></mat-chip
        >
      </mat-chip-set>
      <lib-spinner
        matPrefix
        class="list-container-spinner"
        *ngIf="isSearching"
        [size]="30"
      ></lib-spinner>
    </div>

    <div class="list-container-tablewrapper">
      <table
        mat-table
        matSort
        multiTemplateDataRows
        [dataSource]="modelsDatasource$ | async"
        (matSortChange)="onSortChange($event)"
        class="list-container-table"
      >
        <ng-container matColumnDef="selfie">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.model" | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="model-info">
            <app-model-tile
              class="model-info-selfie"
              [model]="element.model"
              size="small"
            ></app-model-tile>
            <div class="model-info-title">
              <p class="text-body-2">
                {{ element.model | modelName }}
              </p>
              <p
                class="text-caption"
                *ngIf="
                  (element.genericModelConsent$ | async) || element.model.minor
                "
              >
                @if (element.genericModelConsent$ | async) {
                  <span>{{ "model.generic-model" | translate }}</span>
                }
                @if (element.model.minor) {
                  <span>{{ "model.minor" | translate }}</span>
                }
              </p>
            </div>
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.first-name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.model.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.last-name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.model.lastName }}
        </td>
      </ng-container> -->

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.email" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.model.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="genericConsent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.generic-model" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              ((element.genericModelConsent$ | async) ? "base.yes" : "base.no")
                | translate
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.creation-date" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.model.creationDate
                ? (element.model.creationDate.toDate()
                  | timestamp: "mediumDate")
                : ""
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="modificationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.modification-date" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.model.modificationDate
                ? (element.model.modificationDate.toDate()
                  | timestamp: "mediumDate")
                : ""
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="expirationDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.expiration-date" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              (element.expirationDate$ | async)
                ? (element.expirationDate$ | async | timestamp: "mediumDate")
                : ""
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="projecten">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "project.projects" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.model.projectIds?.length || 0 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="pendingForms">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.status-pending" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.pendingForms$ | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="revokedForms">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.status-revoked" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.revokedForms$ | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="assets">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "asset.assets" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.assetCount$ | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="matches">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "tracking.matches" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.assetMatches$ | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="minor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.minor" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ (element.model.minor ? "base.yes" : "base.no") | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="parentName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "model.parent-name" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.model.parentFirstName }}
            {{ element.model.parentLastName }}
          </td>
        </ng-container>

        <ng-container
          *ngFor="let customField of tenant?.customModelFields"
          [matColumnDef]="customField.name"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ customField.name }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              customField.type === "DATE"
                ? (getCustomValue(element.model, customField.name)
                  | timestamp: "mediumDate")
                : getCustomValue(element.model, customField.name)
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <!-- <button mat-button (click)="onEditUser(element)">
        {{ "base.edit" | translate }}
      </button>
      <button mat-button (click)="onDeleteUser(element)">
        {{ "base.delete" | translate }}
      </button> -->
            <button
              mat-icon-button
              aria-label="expand row"
              (click)="onOpenModel(element.model?.id); $event.stopPropagation()"
            >
              @if (expandedModel === element.model?.id) {
                <mat-icon>keyboard_arrow_up</mat-icon>
              } @else {
                <mat-icon>keyboard_arrow_down</mat-icon>
              }
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length"
          >
            <div
              class="list-container-table-detail-content"
              [@detailExpand]="
                element.model?.id === expandedModel ? 'expanded' : 'collapsed'
              "
            >
              @if (!(modelConsentsProjects$ | async)?.length) {
                <p class="text-caption model-consent-title">
                  {{ "project.no-projects" | translate }}
                </p>
              }
              @let modelConsentsProjects = modelConsentsProjects$ | async;
              <ng-container
                *ngFor="
                  let modelConsentProjects of modelConsentsProjects;
                  trackBy: trackByModelConsentId
                "
              >
                @if (modelConsentProjects.modelConsent.generic) {
                  <h6
                    class="text-subtitle-2 model-consent-title model-consent-title-generic"
                  >
                    {{ "project.generic-projects" | translate }}
                  </h6>
                } @else {
                  <p
                    class="text-subtitle-2 model-consent-title model-consent-title-specific"
                  >
                    {{
                      (modelConsentsProjects[0].modelConsent.generic
                        ? "project.specific-projects"
                        : "project.projects"
                      ) | translate
                    }}
                  </p>
                }
                <div class="model-consent">
                  <div class="model-consent-project-title">
                    <p
                      class="text-caption"
                      *ngIf="modelConsentProjects.modelConsent.generic"
                    >
                      {{ "model-release-form.generic" | translate }}
                    </p>
                    <p
                      class="text-caption"
                      *ngIf="!modelConsentProjects.modelConsent.generic"
                    >
                      {{ modelConsentProjects.projects?.[0]?.name }}
                    </p>
                    <!-- <p class="text-body-2">
                    {{ modelConsentProjects.project?.name }}
                  </p>
                  <p
                    class="text-caption"
                    *ngIf="modelConsentProjects.modelConsent.generic"
                  >
                    {{ "model-release-form.generic" | translate }}
                  </p> -->
                  </div>
                  <div class="status-tag-dark">
                    <mat-icon
                      [class]="
                        'status-tag-icon status-tag-icon-' +
                        modelConsentProjects.modelConsent.status.toLowerCase()
                      "
                      *ngIf="
                        modelConsentProjects.modelConsent.status !==
                        ModelConsentStatus.signed
                      "
                      >error</mat-icon
                    >
                    <span>{{
                      "model.status-" +
                        modelConsentProjects.modelConsent.status.toLowerCase()
                        | translate
                    }}</span>
                  </div>
                  <ng-container *ngIf="UserRule.projectEditing | hasRule">
                    <ng-container
                      [ngSwitch]="modelConsentProjects.modelConsent.status"
                    >
                      <button
                        *ngSwitchCase="ModelConsentStatus.signed"
                        class="model-info-large"
                        mat-icon-button
                        color="primary"
                        [matTooltip]="'model.revoke-consent' | translate"
                        (click)="
                          onRevokeModelConsent(
                            modelConsentProjects.modelConsent
                          )
                        "
                      >
                        <mat-icon>contract_delete</mat-icon>
                      </button>
                      <button
                        *ngSwitchCase="ModelConsentStatus.revoked"
                        class="model-info-large"
                        mat-icon-button
                        color="primary"
                        [matTooltip]="'model.unrevoke-consent' | translate"
                        (click)="
                          onUnrevokeModelConsent(
                            modelConsentProjects.modelConsent
                          )
                        "
                      >
                        <mat-icon>contract_edit</mat-icon>
                      </button>
                      <button
                        *ngSwitchDefault
                        class="model-info-large"
                        mat-icon-button
                        color="primary"
                        [matTooltip]="'model.send-reminder' | translate"
                        (click)="
                          onSendReminder(modelConsentProjects.modelConsent)
                        "
                      >
                        <mat-icon>forward_to_inbox</mat-icon>
                      </button>
                    </ng-container>
                  </ng-container>
                  <button
                    mat-icon-button
                    color="primary"
                    [matTooltip]="'model.contract' | translate"
                    (click)="
                      onOpenContract(
                        modelConsentProjects.projects[0],
                        modelConsentProjects.modelConsent,
                        element.model
                      )
                    "
                  >
                    <mat-icon>contract</mat-icon>
                  </button>
                  <button
                    *ngIf="!modelConsentProjects.modelConsent.generic"
                    mat-icon-button
                    color="primary"
                    [matTooltip]="'project.to-project' | translate"
                    (click)="
                      onGoToProject(
                        modelConsentProjects.projects?.[0],
                        expandedModel
                      )
                    "
                  >
                    <mat-icon>arrow_forward</mat-icon>
                  </button>
                </div>
                <ng-container *ngIf="modelConsentProjects.modelConsent.generic">
                  @if (!modelConsentProjects.projects?.length) {
                    <div class="model-consent">
                      <div class="model-consent-project-title">
                        <p class="text-caption">
                          {{ "project.no-projects" | translate }}
                        </p>
                      </div>
                    </div>
                  }
                  <div
                    class="model-consent"
                    *ngFor="
                      let project of modelConsentProjects.projects;
                      trackBy: trackByProjectId
                    "
                  >
                    <div class="model-consent-project-title">
                      <p class="text-caption">
                        {{ project.name }}
                      </p>
                    </div>
                    <button
                      mat-icon-button
                      color="primary"
                      [matTooltip]="'project.to-project' | translate"
                      (click)="onGoToProject(project, expandedModel)"
                    >
                      <mat-icon>arrow_forward</mat-icon>
                    </button>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          class="list-container-table-header"
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: displayedColumns"
          (click)="onOpenModel(element.model?.id)"
          class="list-container-table-row"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="list-container-table-detail-row"
        ></tr>
      </table>
    </div>

    <mat-paginator
      class="list-container-paginator"
      [pageSizeOptions]="[5, 10, 20]"
      [pageIndex]="currentPage"
      [pageSize]="itemsPerPage"
      [length]="totalItems"
      (page)="onHandlePageEvent($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<mat-menu #columnMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let column of availableColumns"
    (click)="$event.stopPropagation()"
  >
    <mat-checkbox
      [checked]="displayedColumns.includes(column.name)"
      [indeterminate]="false"
      (change)="onToggleColumn(column.name)"
    >
    </mat-checkbox>
    {{ column.translation | translate }}
  </button>
</mat-menu>

<mat-menu #filterMenu="matMenu">
  <mat-form-field
    class="model-search"
    subscriptSizing="dynamic"
    (click)="$event.stopPropagation()"
  >
    <mat-label>{{ "search.search" | translate }}</mat-label>
    <input
      matInput
      type="text"
      #searchInput
      (keyup)="onSearchKeyUp($event, searchInput.value)"
    />
    <button matSuffix mat-icon-button (click)="onSearch(searchInput.value)">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.signed)"
  >
    <span class="status-filter-signed"></span
    >{{ "model.status-signed" | translate }}
  </button>
  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.pending)"
  >
    <span class="status-filter-pending"></span
    >{{ "model.status-pending" | translate }}
  </button>
  <button
    mat-menu-item
    (click)="onAddFilter('status', ModelConsentStatus.revoked)"
  >
    <span class="status-filter-revoked"></span
    >{{ "model.status-revoked" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('matches', true)">
    <mat-icon>language</mat-icon>{{ "model.matches-found" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('matches', false)">
    <mat-icon>language</mat-icon>{{ "model.matches-not-found" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('model-type', true)">
    <mat-icon>contract</mat-icon>{{ "model.generic-model" | translate }}
  </button>
  <button mat-menu-item (click)="onAddFilter('model-type', false)">
    <mat-icon>contract</mat-icon>{{ "model.project-model" | translate }}
  </button>
  @if (TenantToggle.minorModels | hasToggle) {
    <button mat-menu-item (click)="onAddFilter('minor-adult', true)">
      <mat-icon>escalator_warning</mat-icon>{{ "model.minor" | translate }}
    </button>
    <button mat-menu-item (click)="onAddFilter('minor-adult', false)">
      <mat-icon>escalator_warning</mat-icon>{{ "model.adult" | translate }}
    </button>
  }
</mat-menu>
