import { ClientTimestamp } from '../shared-types';

export enum FormTemplateLanguage {
  en = 'EN',
  nl = 'NL',
}

export interface FormTemplateVariant {
  language: FormTemplateLanguage;
  text: string;
  modificationDate: ClientTimestamp;
}

export interface FormTemplate {
  id: string;
  name: string;
  variants: FormTemplateVariant[];
  creationDate: ClientTimestamp;
  modificationDate: ClientTimestamp;
  generic?: boolean;
  expirationMonths?: number;
  deleted?: boolean;
}
