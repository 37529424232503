<app-header
  [title]="(asset$ | async)?.name || '&nbsp;'"
  [longTitle]="true"
  [backUrl]="['/project', (project$ | async)?.id]"
>
</app-header>

<div class="asset">
  <div class="image">
    <div class="image-container">
      <ng-container *ngIf="playVideo">
        <video
          class="image-container-video"
          autoplay="autoplay"
          controls
          controlsList="nodownload"
          [src]="videoUrl$ | async"
        ></video>
      </ng-container>
      <ng-container *ngIf="!playVideo">
        <img
          class="image-container-image"
          [src]="imageUrl$ | async"
          (click)="onClickImage($event)"
          oncontextmenu="return false"
        />
      </ng-container>

      <div
        *ngIf="selection"
        class="image-container-clip"
        [style.--position-x.%]="selection.x"
        [style.--position-y.%]="selection.y"
        (click)="onClickImage($event)"
      ></div>

      <ng-container
        *ngIf="
          !selection && (imageUrl$ | async) && TenantToggle.assetAutoTagModels
            | hasToggle
        "
      >
        <div
          *ngFor="let face of (asset$ | async)?.faces"
          class="image-container-face mat-elevation-z3"
          [class.image-container-face-untagged]="
            !isFaceTagged(face, (asset$ | async)?.modelTags)
          "
          [style.left.%]="face.x * 100"
          [style.top.%]="face.y * 100"
          [style.width.%]="face.width * 100"
          [style.height.%]="face.height * 100"
          (click)="onClickFace(face)"
        ></div>
      </ng-container>

      <div
        class="image-container-selection"
        [class.image-container-selection-hidden]="!selection"
        [style.left.%]="selection?.x"
        [style.top.%]="selection?.y"
        cdkOverlayOrigin
        (click)="onSelectModel(null)"
        #trigger="cdkOverlayOrigin"
      >
        <button
          *ngIf="selectedModel && selection"
          class="image-container-selection-clear"
          mat-icon-button
          (click)="onRemoveModel(selectedModel.id); $event.stopPropagation()"
          color="secondary"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
      <div
        class="image-container-selection-name"
        *ngIf="selectedModel && selection"
        [style.left.%]="selection.x - 30"
        [style.right.%]="100 - selection.x - 30"
        [style.top.%]="selection.y"
      >
        <h4 class="select-model-item-name">
          {{ selectedModel | modelName }}
        </h4>
      </div>
    </div>
  </div>
  <button
    mat-icon-button
    class="previous-asset"
    [routerLink]="['/asset', (previousAsset$ | async)?.id]"
  >
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button
    mat-icon-button
    class="next-asset"
    [routerLink]="['/asset', (nextAsset$ | async)?.id]"
  >
    <mat-icon>chevron_right</mat-icon>
  </button>
  <button
    *ngIf="isvideo && !playVideo"
    mat-icon-button
    class="play-video"
    (click)="onPlayVideo()"
  >
    <mat-icon>play_arrow</mat-icon>
  </button>

  <div class="button-bar">
    <button
      class="verify-check"
      [class.verify-check-checked]="
        (asset$ | async)?.status === AssetStatus.verified
      "
      mat-stroked-button
      color="primary"
      (click)="onToggleVerified()"
      cdkOverlayOrigin
      #trigger2="cdkOverlayOrigin"
    >
      {{ "asset.verified" | translate }}
      <mat-icon>{{
        (asset$ | async)?.status === AssetStatus.verified
          ? "select_check_box"
          : "check_box_outline_blank"
      }}</mat-icon>
    </button>
    <ng-container *ngIf="isvideo">
      <button
        class="tag-video"
        mat-stroked-button
        color="primary"
        (click)="onToggleMultiModelSelect()"
        cdkOverlayOrigin
        #trigger2="cdkOverlayOrigin"
      >
        <mat-icon>location_home</mat-icon> {{ "asset.tag-model" | translate }}
      </button>
      <ng-template
        *ngIf="isvideo"
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger2"
        [cdkConnectedOverlayOpen]="multiModelSelectOpen"
        [cdkConnectedOverlayScrollStrategy]="overlay.scrollStrategies.noop()"
        [cdkConnectedOverlayLockPosition]="true"
        [cdkConnectedOverlayDisposeOnNavigation]="true"
        [cdkConnectedOverlayFlexibleDimensions]="true"
        [cdkConnectedOverlayGrowAfterOpen]="true"
        [cdkConnectedOverlayPush]="true"
        [cdkConnectedOverlayPositions]="[
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center'
          },
          {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center'
          }
        ]"
      >
        <app-model-select-menu
          [modelConsents]="modelConsent$ | async"
          [multiselect]="true"
          [selectedModels]="(asset$ | async)?.modelIds"
          (selectModel)="onAddModel($event)"
          (removeModel)="onRemoveModel($event.id)"
          (autoTag)="onAutoTag()"
          (closeMenu)="onToggleMultiModelSelect()"
        ></app-model-select-menu>
      </ng-template>
    </ng-container>
  </div>
</div>

<div class="container">
  <app-model-list
    [modelsData]="modelsData$ | async"
    [selectedModel]="selectedModel"
    [cancelOption]="true"
    (selectModel)="onSelectModel($event)"
    (removeModelConsent)="onRemoveModel($event.modelId)"
  ></app-model-list>
</div>

<div class="container">
  <div class="matches-header-title">
    <h6>{{ "tracking.monitor-matches" | translate }}</h6>
    <p class="text-body-2">
      {{ (asset$ | async)?.matches?.length || 0 }}
      {{ "base.total" | translate }}
    </p>
    <div class="matches">
      <div class="matches-row" *ngFor="let match of (asset$ | async)?.matches">
        <mat-icon>language</mat-icon>
        <h6
          class="matches-row-link"
          (click)="onOpenMatch(match.page_url || match.image_url)"
          [title]="match.page_url || match.image_url"
        >
          {{ match.page_url || match.image_url }}
        </h6>
        <span class="text-body-1">{{ match.date }}</span>
        <div class="status-tag-dark">
          <mat-icon class="status-tag-icon">radio_button_checked</mat-icon>
          <span>online</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="modelSelectOpen"
  [cdkConnectedOverlayScrollStrategy]="overlay.scrollStrategies.noop()"
  [cdkConnectedOverlayLockPosition]="true"
  [cdkConnectedOverlayDisposeOnNavigation]="true"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top'
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top'
    },
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center'
    },
    {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center'
    }
  ]"
>
  <app-model-select-menu
    [modelConsents]="selectableModelConsents$ | async"
    (selectModel)="onAddModel($event, selection)"
    (closeMenu)="onCloseModelSelect()"
    (autoTag)="onAutoTag()"
  ></app-model-select-menu>
</ng-template>
