import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StoragePath } from '@verify/shared-components/helpers';
import { AssetFileSize, User } from '@verify/shared-components/models';
import {
  AuthService,
  StorageService,
} from '@verify/shared-components/services';
import { Observable } from 'rxjs';
import { UserService } from '../../../services';
import { HeaderComponent } from '../../shared/header/header.component';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    TranslateModule,
    HeaderComponent,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  private authService = inject(AuthService);
  private formBuilder = inject(FormBuilder);
  private userService = inject(UserService);
  private snackBar = inject(MatSnackBar);
  private storageService = inject(StorageService);
  private translateService = inject(TranslateService);

  readonly form = this.formBuilder.nonNullable.group({
    firstName: [''],
    lastName: [''],
    email: ['', Validators.required],
    language: ['EN'],
  });

  readonly passwordForm = this.formBuilder.nonNullable.group({
    password: ['', Validators.required],
    repeatPassword: ['', Validators.required],
  });

  selfie?: File;
  selfiePreview?: string;
  imageUrl$?: Observable<string>;
  languages = ['EN', 'NL'];

  ngOnInit(): void {
    this.form.patchValue(this.authService.currentUser);
    const file =
      this.user.selfie?.find((file) => file.size === AssetFileSize.size200) ||
      this.user.selfie?.[0];
    if (file) {
      this.imageUrl$ = this.storageService.getDownloadUrl(file);
    }
  }

  get user(): User {
    return this.authService.currentUser;
  }

  onSave(): void {
    const { firstName, lastName, email, language } = this.form.value;

    if (email.length > 0 && email !== this.authService.currentUser.email) {
      this.authService.updateEmail(email);
    }
    this.userService.updateUser({
      ...this.user,
      firstName,
      lastName,
      email,
      language,
    });
    if (this.selfie) {
      this.storageService.uploadFile(
        `${StoragePath.users}/${this.user.id}/${this.selfie.name}`,
        this.selfie,
        this.user.id,
      );
    }
    this.translateService.use(language.toLocaleLowerCase());
    this.snackBar.open(
      this.translateService.instant('user.update-success'),
      null,
      {
        duration: 3000,
      },
    );
  }

  onUpdatePassword(): void {
    const { password, repeatPassword } = this.passwordForm.value;
    if (password.length > 0 && password === repeatPassword) {
      this.authService
        .updatePassword(password)
        .then(() => {
          this.snackBar.open(
            this.translateService.instant('user.password-change-success'),
            null,
            {
              duration: 3000,
            },
          );
        })
        .catch(() => {
          this.snackBar.open(
            this.translateService.instant('user.password-change-error'),
            null,
            {
              duration: 3000,
            },
          );
        });
    }
  }

  onSelectSelfie(event: unknown): void {
    const files = (event as { target: { files: FileList } }).target.files;
    if (files.length > 0) {
      this.selfie = files[0];
      this.selfiePreview = URL.createObjectURL(this.selfie);
    }
  }
}
