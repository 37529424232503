import { ClientTimestamp } from '../shared-types';

export enum CustomFieldType {
  text = 'TEXT',
  number = 'NUMBER',
  date = 'DATE',
  permissions = 'PERMISSIONS',
  assetDescription = 'ASSET_DESCRIPTION',
}

export interface CustomField {
  name: string;
  type: CustomFieldType;
  required?: boolean;
  permissions?: string[];
  description?: string;
}

export interface CustomFieldData {
  name: string;
  value: string | ClientTimestamp | number | string[];
}
