import { ModelConsentStatus } from '../consent';
import { ModelTag } from '../consent/model-tag.model';
import { ClientTimestamp } from '../shared-types';

export enum AssetStatus {
  uploading = 'UPLOADING',
  uploaded = 'UPLOADED',
  verified = 'VERIFIED',
}

export enum AssetFileSize {
  original = 'ORIGINAL',
  fprint = 'FPRINT',
  videoStream = 'VIDEO_STREAM',
  size100 = '100x100',
  size200 = '200x200',
  size300 = '300x300',
  size1000 = '1000x1000',
}

export interface AssetFile {
  size: AssetFileSize;
  fileName: string;
  filePath: string;
  creationDate?: ClientTimestamp;
}

export interface AssetExport {
  type: string;
  externalId: string;
  exportDate: ClientTimestamp;
  userId?: string;
}

export interface AssetDownload {
  downloadDate: ClientTimestamp;
  userId?: string;
}

export interface AssetMatch {
  page_url: string;
  image_url: string;
  date: string;
  print: { id: string; certain: number; verified: boolean };
}

export interface AssetFace {
  id: string;
  score: number;
  x: number;
  y: number;
  width: number;
  height: number;
  descriptor: string;
}

export interface AssetFaceSuggestion {
  assetFaceId: string;
  modelId: string;
  distance: number;
}

export interface Asset {
  id: string;
  projectId: string;
  name: string;
  size: number;
  type: string;
  status: AssetStatus;
  files: AssetFile[];
  modelTags: ModelTag[];
  modelIds: string[];
  fingerprintId?: string;
  fingerprintError?: string;
  createdBy: string;
  creationDate: ClientTimestamp;
  modificationDate: ClientTimestamp;
  deleted?: boolean;
  exports?: AssetExport[];
  downloads?: AssetDownload[];
  matches?: AssetMatch[];
  online?: boolean;
  modelConsentStatus?: ModelConsentStatus;
  faces?: AssetFace[];
  suggestions?: AssetFaceSuggestion[];
  groupId?: string;
}
