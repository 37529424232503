import { ClientTimestamp } from '../shared-types';

export enum MessageType {
  modelRevokeConsent = 'model-revoke-consent',
  assetFingerprintFailed = 'asset-fingerprint-failed',
  assetResized = 'asset-resized',
}

export interface Notification {
  id: string;
  type: MessageType;
  variables?: { [key: string]: string } | Array<{ [key: string]: string }>;
  creationDate: ClientTimestamp;
  userId?: string;
  groupId?: string;
  read: boolean;
  readDate?: ClientTimestamp;
}
