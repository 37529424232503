import { Routes } from '@angular/router';
import { LoginComponent } from '@verify/shared-components/components';
import { AuthGuard, AuthResolver } from '@verify/shared-components/helpers';
import { AssetComponent } from './modules/asset/asset.component';
import { ExternalComponent } from './modules/external/external.component';
import { ModelFormComponent } from './modules/external/model-form/model-form.component';
import { RegisterModelComponent } from './modules/external/register-model/register-model.component';
import { LayoutComponent } from './modules/layout/layout.component';
import { ModelsComponent } from './modules/models/models.component';
import { ProjectComponent } from './modules/project/project.component';
import { ProjectsComponent } from './modules/projects/projects.component';
import { CustomFieldsComponent } from './modules/settings/custom-fields/custom-fields.component';
import { DeletedComponent } from './modules/settings/deleted/deleted.component';
import { FormsComponent } from './modules/settings/forms/forms.component';
import { ProfileComponent } from './modules/settings/profile/profile.component';
import { TenantComponent } from './modules/settings/tenant/tenant.component';
import { UsersComponent } from './modules/settings/users/users.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'projects',
      },
      {
        path: 'projects',
        component: ProjectsComponent,
      },
      {
        path: 'project/:projectId',
        component: ProjectComponent,
      },
      {
        path: 'asset/:assetId',
        component: AssetComponent,
      },
      {
        path: 'models',
        component: ModelsComponent,
      },
      {
        path: 'settings',
        children: [
          {
            path: 'profile',
            component: ProfileComponent,
          },
          {
            path: 'forms',
            component: FormsComponent,
          },
          {
            path: 'users',
            component: UsersComponent,
          },
          {
            path: 'custom-fields',
            component: CustomFieldsComponent,
          },
          {
            path: 'tenant',
            component: TenantComponent,
          },
          {
            path: 'deleted',
            component: DeletedComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'ext',
    component: ExternalComponent,
    children: [
      {
        path: 'model-release/:projectId/:formTemplateId',
        component: RegisterModelComponent,
      },
      {
        path: 'model-release/:modelConsentId',
        component: RegisterModelComponent,
      },
      {
        path: 'model-form/:modelConsentId',
        component: ModelFormComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: {
      ready: AuthResolver,
    },
  },
];
