import { formatDate } from '@angular/common';
import { LOCALE_ID, Pipe, PipeTransform, inject } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
import { ClientTimestamp } from '@verify/shared-components/models';

interface TimestampPrimitive {
  _nanoseconds: number;
  _seconds: number;
}

@Pipe({ name: 'timestamp', standalone: true })
export class TimestampPipe implements PipeTransform {
  private locale: string = inject(LOCALE_ID);
  private translateService: TranslateService = inject(TranslateService);

  /**
   * Returns the formatted date
   */
  transform(
    value:
      | TimestampPrimitive
      | Timestamp
      | ClientTimestamp
      | Date
      | string
      | number
      | null
      | undefined,
    format?: string,
  ): string | null {
    if (!value) {
      return '';
    }
    if (typeof value === 'object' && '_seconds' in value) {
      value = new Timestamp(value._seconds, value._nanoseconds);
    }
    if (typeof value === 'object' && 'seconds' in value) {
      value = new Timestamp(value.seconds, value.nanoseconds);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isTimestamp = (val: any): val is ClientTimestamp => {
      if ((val as ClientTimestamp).toDate) {
        return true;
      }
      return false;
    };

    return isTimestamp(value)
      ? formatDate(
          value.toDate(),
          format,
          this.translateService.currentLang || this.locale,
        )
      : formatDate(
          value,
          format,
          this.translateService.currentLang || this.locale,
        );
  }
}
