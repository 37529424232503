<mat-toolbar class="layout-toolbar">
  <img
    *ngIf="logoUrl$ | async; else logoTemplate"
    class="layout-toolbar-logo"
    [src]="logoUrl$ | async"
  />
  <ng-template #logoTemplate
    ><img class="layout-toolbar-logo" src="assets/logo-darkblue.png"
  /></ng-template>
</mat-toolbar>

<div class="layout-content">
  <router-outlet />
</div>
