import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import { FormTemplate } from '@verify/shared-components/models';
import { QRCodeModule } from 'angularx-qrcode';
import { Observable } from 'rxjs';
import { ModelNamePipe } from '../../../pipes';
import { ModelService } from '../../../services';
import { FormService } from '../../../services/form.service';
import { ModelTileComponent } from '../model-tile/model-tile.component';

@Component({
  selector: 'app-add-generic-model-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    QRCodeModule,
    MatSelectModule,
    MatDialogModule,
    TranslateModule,
    MatTabsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerComponent,
    ModelNamePipe,
    ModelTileComponent,
  ],
  templateUrl: './add-generic-model-dialog.component.html',
  styleUrl: './add-generic-model-dialog.component.scss',
})
export class AddGenericModelDialogComponent {
  private formService = inject(FormService);
  private modelService = inject(ModelService);
  private dialogRef = inject(DialogRef<boolean>);

  genericFormTemplates$?: Observable<FormTemplate[]>;
  selectedFormTemplateId?: string;
  emailInvites: string[] = [];
  emailControl = new FormControl('', Validators.email);
  loading = false;

  constructor() {
    this.genericFormTemplates$ = this.formService.getGenericForms();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onAddEmailInvite(): void {
    if (this.emailControl.valid) {
      const email = this.emailControl.value;
      if (!this.emailInvites.includes(email)) {
        this.emailInvites = [...this.emailInvites, email];
        this.emailControl.setValue('');
      }
    }
  }

  onRemoveEmailInvite(email: string): void {
    if (this.emailInvites.includes(email)) {
      this.emailInvites = this.emailInvites.filter((p) => p !== email);
    }
  }

  onSendEmailInvites(): void {
    this.loading = true;
    this.modelService
      .inviteModelsByEmail({
        formTemplateId: this.selectedFormTemplateId,
        formVariantId: 'NL',
        emails: this.emailInvites,
      })
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }
}
