import { Injectable, inject } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, first, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthResolver implements Resolve<boolean> {
  private auth: Auth = inject(Auth);
  private router = inject(Router);

  constructor() {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (route.queryParams['mode']) {
      return of(true);
    }
    return authState(this.auth).pipe(
      first(),
      map((user) => {
        if (user) {
          console.log('redirect');
          this.router.navigateByUrl('/');
          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
