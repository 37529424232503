import {
  combineLatest,
  distinctUntilKeyChanged,
  filter,
  fromEvent,
  map,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Asset,
  Model,
  ModelConsent,
  Project,
  UserRule,
} from '@verify/shared-components/models';
import { DialogService, HasRulePipe } from '@verify/shared-components/services';

import { MediaMatcher } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '@verify/shared-components/components';
import { AssetService, ProjectService } from '../../services';
import { ModelConsentService } from '../../services/model-consent.service';
import { EditProjectDialogComponent } from '../projects/edit-project-dialog/edit-project-dialog.component';
import { AddModelDialogComponent } from '../shared/add-model-dialog/add-model-dialog.component';
import { HeaderComponent } from '../shared/header/header.component';
import { ModelListComponent } from '../shared/model-list/model-list.component';
import { ModelSelectMenuComponent } from '../shared/model-select-menu/model-select-menu.component';
import { AiPromptComponent } from './ai-prompt/ai-prompt.component';
import { ExtendProjectDialogComponent } from './extend-project-dialog/extend-project-dialog.component';
import { ProjectAssetsComponent } from './project-assets/project-assets.component';
import { SelectionBarComponent } from './selection-bar/selection-bar.component';

export interface AssetOrderBy {
  field: keyof Asset;
  direction: 'asc' | 'desc';
}

export interface AssetFilter {
  type: 'model' | 'no-models' | 'verified' | 'no-exports' | 'status' | 'search';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

@Component({
  selector: 'app-project',
  imports: [
    CommonModule,
    MatButtonModule,
    ModelListComponent,
    ProjectAssetsComponent,
    SelectionBarComponent,
    HasRulePipe,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    TranslateModule,
    HeaderComponent,
    ModelSelectMenuComponent,
  ],
  providers: [HasRulePipe],
  templateUrl: './project.component.html',
  styleUrl: './project.component.scss',
})
export class ProjectComponent implements OnInit, OnDestroy {
  private assetService = inject(AssetService);
  private projectService = inject(ProjectService);
  private modelConsentService = inject(ModelConsentService);
  private dialogService = inject(DialogService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private hasRule = inject(HasRulePipe);

  private destroy$ = new Subject<void>();

  project$?: Observable<Project | undefined>;
  projectId?: string;
  assets$: Observable<Asset[]>;
  modelsData$?: Observable<
    | Array<{ model?: Model; modelConsent: ModelConsent; assets?: Asset[] }>
    | undefined
  >;
  projectExpired$?: Observable<boolean>;

  UserRule = UserRule;
  selectedAssets: string[] = [];
  selectedModel: Model;

  orderBy: AssetOrderBy = {
    field: 'creationDate',
    direction: 'asc',
  };
  filters: AssetFilter[] = [];

  dragOver = false;
  showProjectInfo = false;
  mobileQuery: MediaQueryList;

  constructor() {
    const changeDetectorRef = inject(ChangeDetectorRef);
    const media = inject(MediaMatcher);

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQuery.addEventListener('change', () =>
      changeDetectorRef.detectChanges(),
    );
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$), distinctUntilKeyChanged('projectId'))
      .subscribe(({ projectId }) => {
        this.projectId = projectId;
        this.project$ = this.projectService.getProject(projectId);
        this.projectExpired$ = this.project$.pipe(
          map((project) => project?.expirationDate?.toDate() < new Date()),
        );
        this.assets$ = this.assetService.getAssets({
          projectId: this.projectId,
        });

        if (localStorage.getItem(`asset-filters-${this.projectId}`)) {
          this.filters = JSON.parse(
            localStorage.getItem(`asset-filters-${this.projectId}`),
          );
        }

        this.modelsData$ = combineLatest([
          this.project$.pipe(
            filter((project) => !!project),
            switchMap((project) =>
              this.modelConsentService.getModelsAndConsent(project.id),
            ),
          ),
          this.assets$,
        ]).pipe(
          map(([modelsData, assets]) =>
            modelsData.map((modelData) => ({
              ...modelData,
              assets: assets.filter((asset) =>
                asset.modelIds?.includes(modelData.model.id),
              ),
            })),
          ),
        );

        combineLatest([
          this.activatedRoute.queryParams,
          this.modelsData$.pipe(take(1)),
        ]).subscribe(([{ modelId }, modelsData]) => {
          if (modelId) {
            this.onSelectModel(
              modelsData?.find((modelData) => modelData.model.id === modelId)
                ?.model,
            );
          }
        });
      });

    fromEvent<DragEvent>(window, 'drop')
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.hasRule.transform(UserRule.projectUploading)),
      )
      .subscribe((event: DragEvent) => {
        this.onDrop(event);
      });

    fromEvent<DragEvent>(window, 'dragover')
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.hasRule.transform(UserRule.projectUploading)),
      )
      .subscribe((event: DragEvent) => {
        this.onDragOver(event);
      });

    fromEvent<DragEvent>(window, 'dragleave')
      .pipe(
        takeUntil(this.destroy$),
        filter(
          ({ relatedTarget }: DragEvent) =>
            !relatedTarget || (relatedTarget as Element).nodeName === 'HTML',
        ),
        filter(() => this.hasRule.transform(UserRule.projectUploading)),
      )
      .subscribe((event: DragEvent) => {
        this.onDragLeave(event);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onEditProject(): void {
    this.project$.pipe(take(1)).subscribe((project) => {
      this.dialogService.openDialog<Partial<Project>>(
        EditProjectDialogComponent,
        {
          width: this.dialogService.widths.medium,
          data: { project },
        },
      );
    });
  }

  onDeleteProject(): void {
    this.dialogService
      .openDialog<boolean>(ConfirmDialogComponent, {})
      .pipe(filter((confirmed) => !!confirmed))
      .subscribe(() => {
        if (this.projectId) {
          this.projectService.deleteProject(this.projectId);
          this.router.navigate(['']);
        }
      });
  }

  onToggleProjectInfo(): void {
    this.showProjectInfo = !this.showProjectInfo;
  }

  onFileUpload(event: unknown): void {
    if (this.projectId) {
      const files = (event as { target: { files: FileList } }).target.files;
      for (let i = 0; i < files.length; i++) {
        this.assetService.addAsset(this.projectId, {}, files[i]);
      }
    }
  }

  onDragOver(event: DragEvent) {
    this.dragOver = true;
    event.preventDefault();
  }

  onDragLeave(event: DragEvent) {
    if (this.dragOver) {
      this.dragOver = false;
      event.preventDefault();
    }
  }

  onDrop(event: DragEvent) {
    const { files, items } = event.dataTransfer;
    if (
      this.dragOver &&
      (files?.length ||
        Array.from(items).some((item) => item.kind?.toLowerCase() === 'file'))
    ) {
      event.preventDefault();
      const groupId = `${Date.now()}`;
      const files: DataTransferItem[] = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file') {
          files.push(item);
        }
      }
      combineLatest(
        files.map((item) =>
          this.assetService.addAsset(
            this.projectId,
            {},
            item.getAsFile(),
            groupId,
          ),
        ),
      )
        .pipe(
          filter((assets) =>
            assets.every(
              (asset) => asset.fingerprintId || asset.fingerprintError,
            ),
          ),
          take(1),
        )
        .subscribe(() => {
          // console.log(assets);
        });
    }
    this.dragOver = false;
  }

  onAddModel(): void {
    this.project$.pipe(take(1)).subscribe((project) => {
      this.dialogService.openDialog(AddModelDialogComponent, {
        width: this.dialogService.widths.small,
        data: {
          projectId: this.projectId,
          formTemplateIds: project.formTemplateIds,
        },
      });
    });
  }

  onRemoveModelConsent(modelConsent: ModelConsent): void {
    this.modelConsentService.removeModelConsent(modelConsent, this.projectId);
  }

  onRevokeModelConsent(modelConsent: ModelConsent): void {
    this.modelConsentService.revokeModelConsent(modelConsent);
  }

  onOpenAsset(asset: Asset): void {
    this.router.navigate(['asset', asset.id]);
  }

  onSetSelection(assetIds: string[]): void {
    this.selectedAssets = assetIds;
  }

  onSetOrderBy(orderBy: AssetOrderBy): void {
    this.orderBy = orderBy;
  }

  onSelectModel(model: Model): void {
    if (this.selectedModel === model) {
      this.onRemoveFilter('model');
    } else {
      this.onAddFilter({ type: 'model', value: model });
    }
    this.selectedModel = this.selectedModel === model ? null : model;
  }

  onAddFilter({ type, value }: AssetFilter): void {
    const filters = (this.filters || []).filter((filt) => filt.type !== type);
    this.filters = [...filters, { type, value }];
    localStorage.setItem(
      `asset-filters-${this.projectId}`,
      JSON.stringify(this.filters),
    );
  }

  onRemoveFilter(type: AssetFilter['type']): void {
    this.filters = (this.filters || []).filter((filt) => filt.type !== type);
    localStorage.setItem(
      `asset-filters-${this.projectId}`,
      JSON.stringify(this.filters),
    );
  }

  onExtendProject(): void {
    this.project$.pipe(take(1)).subscribe((project) => {
      this.dialogService.openDialog<Partial<Project>>(
        ExtendProjectDialogComponent,
        {
          width: this.dialogService.widths.medium,
          data: { project },
        },
      );
    });
  }

  onAiPrompt(): void {
    this.dialogService.openDialog<Partial<Project>>(AiPromptComponent, {
      width: this.dialogService.widths.medium,
    });
  }
}
