<div
  class="selection-bar mat-elevation-z3"
  [class.with-sidenav]="modelSelectOpen"
  *ngIf="selectedAssets?.length > 0"
>
  <span class="text-subtitle-2"
    >{{
      exporting
        ? ("asset.exporting-assets" | translate)
        : ("asset.assets-selected"
          | translate: { count: selectedAssets?.length })
    }}
  </span>
  <span *ngIf="exporting">
    <lib-spinner [size]="30" *ngIf="exporting"></lib-spinner>
  </span>
  <ng-container *ngIf="!exporting">
    <button
      mat-stroked-button
      class="selection-bar-large"
      color="primary"
      (click)="onOpenModelSelect()"
    >
      <mat-icon>location_home</mat-icon> {{ "asset.tag-model" | translate }}
    </button>
    <button
      *ngIf="UserRole.projectUploading | hasRole"
      class="selection-bar-large"
      mat-stroked-button
      color="primary"
      (click)="onDeleteSelected()"
    >
      <mat-icon>delete</mat-icon>{{ "base.delete" | translate }}
    </button>
    <button
      *ngIf="UserRole.projectDownloading | hasRole"
      class="selection-bar-large"
      mat-stroked-button
      color="primary"
      (click)="onDownloadSelected()"
    >
      <mat-icon>download</mat-icon>{{ "base.download" | translate }}
    </button>
    <button
      *ngIf="(tenant$ | async).lythoConfig && UserRole.projectExport | hasRole"
      class="selection-bar-large"
      mat-stroked-button
      color="primary"
      (click)="onExportSelectedToLytho()"
      [disabled]="exporting"
    >
      <mat-icon>ios_share</mat-icon>{{ "asset.export-to-lytho" | translate }}
    </button>
    <button
      *ngIf="(tenant$ | async).bynderConfig && UserRole.projectExport | hasRole"
      class="selection-bar-large"
      mat-stroked-button
      color="primary"
      (click)="onExportSelectedToBynder()"
      [disabled]="exporting"
    >
      <mat-icon>ios_share</mat-icon>{{ "asset.export-to-bynder" | translate }}
    </button>

    <button
      mat-icon-button
      class="selection-bar-small"
      color="primary"
      [matMenuTriggerFor]="selectionMenu"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <button mat-icon-button color="primary" (click)="onClearSelection()">
      <mat-icon>close</mat-icon>
    </button>
  </ng-container>
</div>

<mat-menu #selectionMenu="matMenu">
  <button mat-menu-item (click)="onOpenModelSelect()">
    <mat-icon>location_home</mat-icon> {{ "asset.tag-model" | translate }}
  </button>
  <button
    *ngIf="UserRole.projectUploading | hasRole"
    mat-menu-item
    (click)="onDeleteSelected()"
  >
    <mat-icon>delete</mat-icon>{{ "base.delete" | translate }}
  </button>
  <button
    *ngIf="UserRole.projectDownloading | hasRole"
    mat-menu-item
    (click)="onDownloadSelected()"
  >
    <mat-icon>download</mat-icon>{{ "base.download" | translate }}
  </button>
  <button
    *ngIf="(tenant$ | async).lythoConfig && UserRole.projectExport | hasRole"
    mat-menu-item
    (click)="onExportSelectedToLytho()"
    [disabled]="exporting"
  >
    <mat-icon>ios_share</mat-icon>{{ "asset.export-to-lytho" | translate }}
  </button>
  <button
    *ngIf="(tenant$ | async).bynderConfig && UserRole.projectExport | hasRole"
    mat-menu-item
    (click)="onExportSelectedToBynder()"
    [disabled]="exporting"
  >
    <mat-icon>ios_share</mat-icon>{{ "asset.export-to-bynder" | translate }}
  </button>
</mat-menu>

<!-- <div class="model-select-menu-origin">
  <div cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
</div> -->
<!-- <ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="modelSelectOpen"
  [cdkConnectedOverlayScrollStrategy]="overlay.scrollStrategies.noop()"
  [cdkConnectedOverlayLockPosition]="true"
  [cdkConnectedOverlayDisposeOnNavigation]="true"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center'
    },
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'center',
      overlayY: 'center'
    },
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center'
    }
  ]"
>
  <app-model-select-menu
    [modelConsents]="modelConsents"
    [multiselect]="true"
    [selectedModels]="selectedModelTags"
    (selectModel)="onTagModel($event)"
    (removeModel)="onUntagModel($event)"
    (autoTag)="onAutoTag()"
    (closeMenu)="onCloseModelSelect()"
  ></app-model-select-menu>
</ng-template> -->
