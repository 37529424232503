import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { CustomField } from '@verify/shared-components/models';
import { AuthService, DialogService } from '@verify/shared-components/services';
import { Observable, filter, map } from 'rxjs';
import { TenantService } from '../../../services/tenant.service';
import { HeaderComponent } from '../../shared/header/header.component';
import { EditCustomFieldDialogComponent } from './edit-custom-field-dialog/edit-custom-field-dialog.component';

@Component({
    selector: 'app-custom-fields',
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        TranslateModule,
        HeaderComponent,
    ],
    templateUrl: './custom-fields.component.html',
    styleUrl: './custom-fields.component.scss'
})
export class CustomFieldsComponent implements OnInit {
  private authService = inject(AuthService);
  private tenantService = inject(TenantService);
  private dialogService = inject(DialogService);

  displayedColumns: string[] = ['name', 'type', 'required', 'actions'];

  tenant$ = this.authService.tenant$;
  projectfields$: Observable<CustomField[]>;
  modelfields$: Observable<CustomField[]>;

  ngOnInit(): void {
    this.projectfields$ = this.tenant$.pipe(
      map((tenant) =>
        (tenant.customProjectFields || []).sort((a, b) =>
          a.name < b.name ? -1 : 1,
        ),
      ),
    );
    this.modelfields$ = this.tenant$.pipe(
      map((tenant) =>
        (tenant.customModelFields || []).sort((a, b) =>
          a.name < b.name ? -1 : 1,
        ),
      ),
    );
  }

  onAddProjectField(): void {
    this.dialogService
      .openDialog<CustomField>(EditCustomFieldDialogComponent, {
        width: this.dialogService.widths.medium,
      })
      .pipe(filter((result) => !!result))
      .subscribe((customField) => {
        this.tenantService.addCustomProjectField(customField);
      });
  }

  onEditProjectField(field: CustomField): void {
    this.dialogService
      .openDialog<CustomField>(EditCustomFieldDialogComponent, {
        width: this.dialogService.widths.medium,
        data: { field },
      })
      .pipe(filter((result) => !!result))
      .subscribe((updatedField) => {
        this.tenantService.editCustomProjectField(field, updatedField);
      });
  }

  onRemoveProjectField(customField: CustomField): void {
    this.tenantService.removeCustomProjectField(customField);
  }

  onAddModelField(): void {
    this.dialogService
      .openDialog<CustomField>(EditCustomFieldDialogComponent, {
        width: this.dialogService.widths.medium,
      })
      .pipe(filter((result) => !!result))
      .subscribe((customField) => {
        this.tenantService.addCustomModelField(customField);
      });
  }

  onEditModelField(field: CustomField): void {
    this.dialogService
      .openDialog<CustomField>(EditCustomFieldDialogComponent, {
        width: this.dialogService.widths.medium,
        data: { field },
      })
      .pipe(filter((result) => !!result))
      .subscribe((updatedField) => {
        this.tenantService.editCustomModelField(field, updatedField);
      });
  }

  onRemoveModelField(customField: CustomField): void {
    this.tenantService.removeCustomModelField(customField);
  }

  trackByCustomField(_: number, field: CustomField): string {
    return field.name;
  }
}
