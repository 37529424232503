import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import {
  ClientTimestamp,
  GetModelConsentFormResponse,
  ModelConsentStatus,
} from '@verify/shared-components/models';
import { AuthService, TimestampPipe } from '@verify/shared-components/services';

import { MatIconModule } from '@angular/material/icon';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { switchMap } from 'rxjs';
import { enrichedFormText } from '../../../helpers';
import { ModelService } from '../../../services';

@Component({
    selector: 'app-model-form',
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        TimestampPipe,
        SpinnerComponent,
        TranslateModule,
        PdfViewerModule,
    ],
    providers: [TimestampPipe],
    templateUrl: './model-form.component.html',
    styleUrl: './model-form.component.scss'
})
export class ModelFormComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private modelService = inject(ModelService);
  private authService = inject(AuthService);
  private sanitizer = inject(DomSanitizer);
  private translateService = inject(TranslateService);
  private timestampPipe = inject(TimestampPipe);

  modelConsentFormInfo?: GetModelConsentFormResponse;
  pdfUrl?: SafeResourceUrl;
  ModelConsentStatus = ModelConsentStatus;

  askRevoke = false;
  isRevoking = false;
  isSigning = false;
  revoked = false;
  justSigned = false;
  pdfZoom = 0.95;

  ngOnInit(): void {
    const { modelConsentId } = this.activatedRoute.snapshot.params;
    const tenantId = this.authService.tenantId;
    this.modelService
      .getModelConsentForm(tenantId, modelConsentId)
      .subscribe((modelConsentFormInfo) => {
        this.modelConsentFormInfo = modelConsentFormInfo;
        const lang = modelConsentFormInfo.formVariantId.toLocaleLowerCase();
        if (this.translateService.currentLang !== lang) {
          this.translateService.use(lang);
        }
        if (modelConsentFormInfo.pdf) {
          this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            modelConsentFormInfo.pdf,
          );
        }
      });
  }

  get formText(): SafeHtml {
    const {
      name,
      description,
      shootDate,
      model,
      signedDate,
      customProjectData,
      customModelData,
    } = this.modelConsentFormInfo;
    const { firstName, lastName, email } = model || {};
    return this.sanitizer.bypassSecurityTrustHtml(
      enrichedFormText(
        (
          this.modelConsentFormInfo?.formTemplate?.variants?.find(
            (variant) =>
              variant.language === this.modelConsentFormInfo.formVariantId,
          ) || this.modelConsentFormInfo?.formTemplate?.variants?.[0]
        )?.text,
        {
          projectName: name,
          projectDescription: description,
          projectPhotoshootDate: shootDate,
          modelFirstName: firstName,
          modelLastName: lastName,
          modelEmail: email,
          signedDate,
          ...(customProjectData || []).reduce(
            (acc, data) => ({
              ...acc,
              [`project{${data.name}}`]:
                typeof data.value === 'object' && '_seconds' in data.value
                  ? this.timestampPipe.transform(
                      data.value as ClientTimestamp,
                      'mediumDate',
                    )
                  : data.value,
            }),
            {},
          ),
          ...(customModelData || []).reduce(
            (acc, data) => ({
              ...acc,
              [`model{${data.name}}`]:
                typeof data.value === 'object' && '_seconds' in data.value
                  ? this.timestampPipe.transform(
                      data.value as ClientTimestamp,
                      'mediumDate',
                    )
                  : data.value,
            }),
            {},
          ),
        },
      ),
    );
  }

  get isExtensionRequest(): boolean {
    return (
      this.modelConsentFormInfo?.status === ModelConsentStatus.extendRequest
    );
  }

  onConfirmSign(): void {
    const { modelConsentId } = this.activatedRoute.snapshot.params;
    const tenantId = this.authService.tenantId;
    if (modelConsentId) {
      this.isSigning = true;
      this.modelService
        .confirmConsent({ modelConsentId, tenantId })
        .pipe(
          switchMap(() =>
            this.modelService.getModelConsentForm(tenantId, modelConsentId),
          ),
        )
        .subscribe((modelConsentFormInfo) => {
          this.modelConsentFormInfo = modelConsentFormInfo;
          this.isSigning = false;
          this.justSigned = true;
        });
    }
  }

  onAskRevoke(): void {
    this.askRevoke = true;
  }

  onRevoke(): void {
    const { modelConsentId } = this.activatedRoute.snapshot.params;
    const tenantId = this.authService.tenantId;
    if (modelConsentId) {
      this.isRevoking = true;
      this.modelService
        .revokeConsent({ modelConsentId, tenantId })
        .subscribe((success) => {
          this.revoked = success;
          this.isRevoking = false;
        });
    }
  }

  zoomIn(): void {
    this.pdfZoom += 0.05;
  }

  zoomOut(): void {
    this.pdfZoom -= 0.05;
  }
}
