import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import {
  ClientTimestamp,
  FormTemplate,
  Model,
  ModelConsent,
  ModelConsentStatus,
  Project,
} from '@verify/shared-components/models';
import {
  StorageService,
  TimestampPipe,
} from '@verify/shared-components/services';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Observable, take } from 'rxjs';
import { enrichedFormText } from '../../../helpers';
import { FormService } from '../../../services/form.service';
import { ModelTileComponent } from '../model-tile/model-tile.component';

@Component({
    selector: 'app-contract-dialog',
    imports: [
        CommonModule,
        ModelTileComponent,
        TimestampPipe,
        MatButtonModule,
        MatDialogModule,
        TranslateModule,
        MatIconModule,
        PdfViewerModule,
    ],
    providers: [TimestampPipe],
    templateUrl: './contract-dialog.component.html',
    styleUrl: './contract-dialog.component.scss'
})
export class ContractDialogComponent implements OnInit {
  private formService = inject(FormService);
  private dialogRef = inject(MatDialogRef<void>);
  dialogData: {
    model: Model;
    modelConsent: ModelConsent;
    project?: Project;
  } = inject(DIALOG_DATA);
  private sanitizer = inject(DomSanitizer);
  private timestampPipe = inject(TimestampPipe);
  private storageService = inject(StorageService);

  pdfUrl$: Observable<string>;
  formTemplate: FormTemplate;
  formText: SafeHtml;
  ModelConsentStatus = ModelConsentStatus;
  pdfZoom = 0.95;

  ngOnInit(): void {
    if (this.dialogData.modelConsent.pdfFile) {
      this.pdfUrl$ = this.storageService.getDownloadUrl(
        this.dialogData.modelConsent.pdfFile,
      );
    } else {
      this.formService
        .getForm(this.dialogData.modelConsent.formTemplateId)
        .pipe(take(1))
        .subscribe((formTemplate) => {
          this.formTemplate = formTemplate;
          this.updateFormText();
        });
    }
  }

  updateFormText(): void {
    const { model, project, modelConsent } = this.dialogData;
    this.formText = this.sanitizer.bypassSecurityTrustHtml(
      enrichedFormText(this.formTemplate.variants?.[0]?.text, {
        projectName: project?.name,
        projectDescription: project?.description,
        projectPhotoshootDate: project?.shootDate
          ? this.timestampPipe.transform(
              project?.shootDate as ClientTimestamp,
              'mediumDate',
            )
          : '',
        modelFirstName: model.firstName,
        modelLastName: model.lastName,
        modelEmail: model.email,
        signedDate: modelConsent.signedDate,
        ...(project.customData || []).reduce(
          (acc, data) => ({
            ...acc,
            [`project{${data.name}}`]:
              typeof data.value === 'object' && 'seconds' in data.value
                ? this.timestampPipe.transform(
                    data.value as ClientTimestamp,
                    'mediumDate',
                  )
                : data.value,
          }),
          {},
        ),
        ...(model.customData || []).reduce(
          (acc, data) => ({
            ...acc,
            [`model{${data.name}}`]:
              typeof data.value === 'object' && 'seconds' in data.value
                ? this.timestampPipe.transform(
                    data.value as ClientTimestamp,
                    'mediumDate',
                  )
                : data.value,
          }),
          {},
        ),
      }),
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  zoomIn(): void {
    this.pdfZoom += 0.05;
  }

  zoomOut(): void {
    this.pdfZoom -= 0.05;
  }
}
