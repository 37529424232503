import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import {
  ClientTimestamp,
  Model,
  ModelConsent,
  ModelConsentStatus,
  Project,
  SearchRequestOrderBy,
  Tenant,
  UserRole,
} from '@verify/shared-components/models';
import {
  AuthService,
  DialogService,
  HasRolePipe,
  TimestampPipe,
} from '@verify/shared-components/services';
import { catchError, map, Observable, of, Subject, takeUntil } from 'rxjs';
import {
  AssetService,
  ModelConsentService,
  ModelService,
} from '../../services';
import { AddGenericModelDialogComponent } from '../shared/add-generic-model-dialog/add-generic-model-dialog.component';
import { ContractDialogComponent } from '../shared/contract-dialog/contract-dialog.component';
import { HeaderComponent } from '../shared/header/header.component';
import { ModelTileComponent } from '../shared/model-tile/model-tile.component';

@Component({
  selector: 'app-models',
  standalone: true,
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    HeaderComponent,
    RouterModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    TimestampPipe,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    SpinnerComponent,
    MatMenuModule,
    MatCheckboxModule,
    MatChipsModule,
    HasRolePipe,
    ModelTileComponent,
    MatTooltipModule,
  ],
  templateUrl: './models.component.html',
  styleUrl: './models.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ModelsComponent implements OnDestroy {
  private authService = inject(AuthService);
  private activatedRoute = inject(ActivatedRoute);
  private modelService = inject(ModelService);
  private modelConsentService = inject(ModelConsentService);
  private assetService = inject(AssetService);
  private router = inject(Router);
  private dialogService = inject(DialogService);
  private snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);

  private destroy$ = new Subject<void>();

  modelsDatasource$: Observable<
    Array<{
      model: Model;
      assetCount$: Observable<number>;
      assetMatches$: Observable<number>;
      genericModelConsent$: Observable<boolean>;
      expirationDate$: Observable<ClientTimestamp>;
    }>
  >;
  modelConsentsProjects$?: Observable<
    Array<{ modelConsent: ModelConsent; projects?: Project[] }>
  >;

  UserRole = UserRole;
  ModelConsentStatus = ModelConsentStatus;

  isSearching = false;
  searchValue = '';
  currentPage = 0;
  itemsPerPage = 10;
  totalItems = 0;
  orderBy: { field: string; direction: 'asc' | 'desc' };
  expandedModel: string | null;

  @ViewChild('searchInput')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchInput: ElementRef<any>;

  displayedColumns: string[] = [
    'selfie',
    'firstName',
    'lastName',
    'email',
    'genericConsent',
    'modificationDate',
    'projecten',
    'actions',
  ];

  availableColumns: Array<{
    name: string;
    translation: string;
    orderBy?: SearchRequestOrderBy;
  }> = [
    {
      name: 'selfie',
      translation: 'model.selfie',
    },
    {
      name: 'firstName',
      translation: 'model.first-name',
      orderBy: { field: 'firstName.keyword' },
    },
    {
      name: 'lastName',
      translation: 'model.last-name',
      orderBy: { field: 'lastName.keyword' },
    },
    {
      name: 'email',
      translation: 'model.email',
      orderBy: { field: 'email.keyword' },
    },
    {
      name: 'genericConsent',
      translation: 'model.generic-model',
      orderBy: {
        field: 'modelConsents.weight',
        mode: 'sum',
        missing: 0,
        nested: {
          path: 'modelConsents',
          filter: {
            term: {
              ['modelConsents.generic']: true,
            },
          },
        },
      },
    },
    {
      name: 'creationDate',
      translation: 'model.creation-date',
      orderBy: { field: 'creationDate' },
    },
    {
      name: 'modificationDate',
      translation: 'model.modification-date',
      orderBy: { field: 'modificationDate' },
    },
    {
      name: 'expirationDate',
      translation: 'project.expiration-date',
      orderBy: {
        field: 'modelConsents.expirationDate',
        missing: 0,
        nested: {
          path: 'modelConsents',
          filter: {
            term: {
              ['modelConsents.generic']: true,
            },
          },
        },
      },
    },
    {
      name: 'projecten',
      translation: 'project.projects',
      orderBy: {
        field: 'modelConsents.weight',
        mode: 'sum',
        missing: 0,
        nested: { path: 'modelConsents' },
      },
    },
    {
      name: 'pendingForms',
      translation: 'model.status-pending',
      orderBy: {
        field: 'modelConsents.weight',
        mode: 'sum',
        missing: '0',
        nested: {
          path: 'modelConsents',
          filter: {
            term: {
              ['modelConsents.status.keyword']: ModelConsentStatus.pending,
            },
          },
        },
      },
    },
    {
      name: 'revokedForms',
      translation: 'model.status-revoked',
      orderBy: {
        field: 'modelConsents.weight',
        mode: 'sum',
        missing: '0',
        nested: {
          path: 'modelConsents',
          filter: {
            term: {
              ['modelConsents.status.keyword']: ModelConsentStatus.revoked,
            },
          },
        },
      },
    },
    {
      name: 'assets',
      translation: 'asset.assets',
      orderBy: {
        field: 'assets.weight',
        mode: 'sum',
        missing: 0,
        nested: {
          path: 'assets',
          filter: {
            term: {
              ['assets.deleted']: false,
            },
          },
        },
      },
    },
    {
      name: 'matches',
      translation: 'tracking.matches',
      orderBy: {
        field: 'assets.matches',
        mode: 'sum',
        missing: '0',
        nested: {
          path: 'assets',
        },
      },
    },
    ...(this.authService.tenant?.customModelFields || []).map(
      (customField) => ({
        name: customField.name,
        translation: customField.name,
      }),
    ),
  ];

  constructor() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ query, model }) => {
        if (query) {
          this.onSearch(query);
        } else {
          this.searchModels();
        }
        if (model) {
          this.onOpenModel(model);
        }
      });

    if (localStorage.getItem('models-filter')) {
      this.displayedColumns = localStorage.getItem('models-filter').split(',');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get tenant(): Tenant {
    return this.authService.tenant;
  }

  getCustomValue(
    model: Model,
    fieldName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    return model.customData?.find(
      (customField) => customField.name === fieldName,
    )?.value;
  }

  onSearchKeyUp(e: KeyboardEvent, searchValue: string): void {
    if (e.key === 'Enter') {
      this.onSearch(searchValue);
    }
  }

  onSearch(searchValue?: string): void {
    this.searchValue = searchValue;
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
    this.currentPage = 0;
    this.searchModels();
  }

  onSortChange(sortState: Sort): void {
    const column = this.availableColumns.find(
      (column) => column.name === sortState.active,
    );
    this.orderBy =
      column && sortState.direction
        ? { ...column.orderBy, direction: sortState.direction }
        : null;
    this.searchModels();
  }

  onHandlePageEvent(e: PageEvent) {
    this.itemsPerPage = e.pageSize;
    this.currentPage = e.pageIndex;
    this.searchModels();
  }

  onToggleColumn(column: string): void {
    this.displayedColumns = this.displayedColumns.includes(column)
      ? this.displayedColumns.filter((col) => col !== column)
      : [
          ...this.availableColumns
            .filter(
              (col) =>
                this.displayedColumns.includes(col.name) || col.name === column,
            )
            .map((col) => col.name),
          'actions',
        ];
    localStorage.setItem('models-filter', this.displayedColumns.join(','));
  }

  onOpenProject(project: Project, model: Model) {
    this.router.navigate(['project', project.id], {
      queryParams: { modelId: model.id },
    });
  }

  onAddModel(): void {
    this.dialogService
      .openDialog(AddGenericModelDialogComponent, {
        width: this.dialogService.widths.small,
      })
      .subscribe((result) => {
        if (result) {
          this.searchModels();
        }
      });
  }

  onOpenModel(modelId: string): void {
    this.expandedModel = this.expandedModel === modelId ? null : modelId;
    this.modelConsentsProjects$ = this.expandedModel
      ? this.modelConsentService.getProjectsAndConsent(modelId)
      : null;
  }

  onOpenContract(
    project: Project,
    modelConsent: ModelConsent,
    model: Model,
  ): void {
    this.dialogService.openDialog(ContractDialogComponent, {
      data: {
        model: model,
        modelConsent: modelConsent,
        project: project,
      },
      width: this.dialogService.widths.mediumLarge,
    });
  }

  onRevokeModelConsent(modelConsent: ModelConsent): void {
    this.modelConsentService.revokeModelConsent(modelConsent);
  }

  onUnrevokeModelConsent(modelConsent: ModelConsent): void {
    this.modelConsentService.unrevokeModelConsent(modelConsent);
  }

  onSendReminder(modelConsent: ModelConsent): void {
    this.modelConsentService.resendEmail(modelConsent).subscribe(() => {
      this.snackBar.open(
        this.translateService.instant('model.reminder-sent'),
        null,
        { duration: 5000 },
      );
    });
  }

  onGoToProject(project: Project, modelId: string) {
    this.router.navigate(['project', project.id], {
      queryParams: { modelId },
    });
  }

  trackByModelConsentId(
    _: number,
    { modelConsent }: { modelConsent: ModelConsent; projects?: Project[] },
  ): string {
    return modelConsent?.id;
  }

  trackByProjectId(_: number, project: Project): string {
    return project?.id;
  }

  private searchModels(): void {
    this.isSearching = true;
    this.modelsDatasource$ = this.modelService
      .searchModels({
        queryString: this.searchValue,
        from: this.currentPage * this.itemsPerPage,
        size: this.itemsPerPage,
        orderBy: this.orderBy,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          this.isSearching = false;
          return of({ totalHits: 0, models: [] });
        }),
        map((result) => {
          this.totalItems = result.totalHits;
          this.isSearching = false;

          return result.models.map((model) => {
            const assets$ = this.assetService.getAssets({
              modelId: model.id,
            });
            const modelConsents$ =
              this.modelConsentService.getModelConsentsByModel(model.id);
            return {
              model,
              assetCount$: assets$.pipe(
                map(
                  (assets) => assets.filter((asset) => !asset.deleted).length,
                ),
              ),
              assetMatches$: assets$.pipe(
                map((assets) =>
                  assets.reduce(
                    (total, asset) => total + (asset.matches?.length || 0),
                    0,
                  ),
                ),
              ),
              genericModelConsent$: modelConsents$.pipe(
                map(
                  (modelConsents) =>
                    !!modelConsents.find(
                      (modelConsent) => !!modelConsent.generic,
                    ),
                ),
              ),
              expirationDate$: modelConsents$.pipe(
                map(
                  (modelConsents) =>
                    modelConsents.find((modelConsent) => !!modelConsent.generic)
                      ?.expirationDate,
                ),
              ),
              pendingForms$: modelConsents$.pipe(
                map(
                  (modelConsents) =>
                    modelConsents.filter(
                      (modelConsent) =>
                        modelConsent.status === ModelConsentStatus.pending,
                    ).length,
                ),
              ),
              revokedForms$: modelConsents$.pipe(
                map(
                  (modelConsents) =>
                    modelConsents.filter(
                      (modelConsent) =>
                        modelConsent.status === ModelConsentStatus.revoked,
                    ).length,
                ),
              ),
            };
          });
        }),
      );
  }
}
