<app-header [cloud]="true"> </app-header>

<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav-content>
    <div class="page-content">
      <app-header [longTitle]="true">
        <span title>
          {{ (project$ | async)?.name }}
          <span *ngIf="projectExpired$ | async" class="project-status-expired"
            >({{ "project.status-expired" | translate }})</span
          ></span
        >

        <button
          class="header-large"
          mat-stroked-button
          color="primary"
          *ngIf="UserRule.projectManagement | hasRule"
          [matMenuTriggerFor]="projectMenu"
        >
          <mat-icon>edit</mat-icon> {{ "base.edit" | translate }}
        </button>
        <button
          class="header-large"
          mat-stroked-button
          color="primary"
          (click)="onToggleProjectInfo()"
        >
          <mat-icon
            >keyboard_arrow_{{ showProjectInfo ? "up" : "down" }}</mat-icon
          >
          {{ "project.show-info" | translate }}
        </button>
        <button
          class="header-large"
          mat-stroked-button
          color="primary"
          *ngIf="UserRule.projectManagement | hasRule"
          (click)="onAiPrompt()"
        >
          <mat-icon>robot_2</mat-icon> {{ "ai.prompt" | translate }}
        </button>
        <button
          class="header-large"
          *ngIf="
            (UserRule.projectEditing | hasRule) ||
            (UserRule.projectUploading | hasRule)
          "
          mat-stroked-button
          color="primary"
          (click)="onAddModel()"
        >
          <mat-icon>person_add</mat-icon> {{ "model.add-model" | translate }}
        </button>
        <button
          class="header-large"
          *ngIf="UserRule.projectUploading | hasRule"
          mat-stroked-button
          color="primary"
          (click)="fileUpload.click()"
        >
          <mat-icon>upload</mat-icon> {{ "project.upload-media" | translate }}
        </button>

        <button
          mat-stroked-button
          color="primary"
          class="stroked-icon-button-square header-small"
          [matMenuTriggerFor]="projectMenu"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-stroked-button
          color="primary"
          class="stroked-icon-button-square header-small"
          (click)="onToggleProjectInfo()"
        >
          <mat-icon>info</mat-icon>
        </button>
        <button
          mat-stroked-button
          color="primary"
          class="stroked-icon-button-square header-small"
          (click)="onAiPrompt()"
        >
          <mat-icon>robot_2</mat-icon>
        </button>
        <button
          class="header-small"
          mat-stroked-button
          color="primary"
          [matMenuTriggerFor]="addMenu"
        >
          <mat-icon>add</mat-icon> {{ "base.add" | translate }}
        </button>
      </app-header>

      <mat-menu #projectMenu="matMenu">
        <button mat-menu-item (click)="onEditProject()">
          {{ "base.edit" | translate }}
        </button>
        <button mat-menu-item (click)="onExtendProject()">
          {{ "project.extend-project" | translate }}
        </button>
        <button mat-menu-item (click)="onDeleteProject()">
          {{ "base.delete" | translate }}
        </button>
      </mat-menu>

      <mat-menu #addMenu="matMenu">
        <button mat-menu-item (click)="onAddModel()">
          {{ "model.add-model" | translate }}
        </button>
        <button mat-menu-item (click)="fileUpload.click()">
          {{ "project.upload-media" | translate }}
        </button>
      </mat-menu>

      <input
        type="file"
        class="file-input"
        (change)="onFileUpload($event)"
        multiple
        #fileUpload
      />

      <div class="container" *ngIf="showProjectInfo">
        <p
          class="project-tile-description"
          *ngIf="(project$ | async)?.description"
        >
          {{ (project$ | async)?.description }}
        </p>
        <p
          class="project-tile-instructions text-body-2"
          *ngIf="(project$ | async)?.instructions"
          [innerText]="(project$ | async)?.instructions"
        ></p>
      </div>

      <div class="container">
        <app-model-list
          [modelsData]="modelsData$ | async"
          [selectedModel]="selectedModel"
          [project]="project$ | async"
          (selectModel)="onSelectModel($event)"
          (removeModelConsent)="onRemoveModelConsent($event)"
        ></app-model-list>
      </div>

      <div class="container">
        <app-project-assets
          [assets]="assets$ | async"
          [selectedAssets]="selectedAssets"
          [orderBy]="orderBy"
          [filters]="filters"
          (setSelection)="onSetSelection($event)"
          (addFilter)="onAddFilter($event)"
          (removeFilter)="onRemoveFilter($event)"
          (setOrderBy)="onSetOrderBy($event)"
        ></app-project-assets>
      </div>

      <div class="drop-zone" *ngIf="dragOver">
        <h1>{{ "project.drop-files" | translate }}</h1>
      </div>

      <app-selection-bar
        #selectionBar
        [assets]="assets$ | async"
        [selectedAssets]="selectedAssets"
        [modelConsents]="modelsData$ | async"
        [project]="project$ | async"
        (setSelection)="onSetSelection($event)"
      ></app-selection-bar>
    </div>
  </mat-sidenav-content>
  <mat-sidenav
    position="end"
    [opened]="selectionBar.modelSelectOpen"
    [fixedInViewport]="!mobileQuery.matches"
    [fixedTopGap]="68"
    [fixedBottomGap]="0"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
  >
    <app-model-select-menu
      [modelConsents]="modelsData$ | async"
      [multiselect]="true"
      [selectedModels]="selectionBar.selectedModelTags"
      (selectModel)="selectionBar.onTagModel($event)"
      (removeModel)="selectionBar.onUntagModel($event)"
      (autoTag)="selectionBar.onAutoTag()"
      (closeMenu)="selectionBar.onCloseModelSelect()"
    ></app-model-select-menu>
  </mat-sidenav>
</mat-sidenav-container>
