import { Injectable, inject } from '@angular/core';
import {
  Timestamp,
  arrayUnion,
  documentId,
  where,
} from '@angular/fire/firestore';
import { CollectionName } from '@verify/shared-components/helpers';
import {
  FormTemplate,
  FormTemplateLanguage,
} from '@verify/shared-components/models';
import { FirestoreService } from '@verify/shared-components/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private firestore = inject(FirestoreService);

  constructor() {}

  getForm(formId: string): Observable<FormTemplate | undefined> {
    return this.firestore.getDocument<FormTemplate>(
      `${CollectionName.forms}/${formId}`,
    );
  }

  getForms(): Observable<FormTemplate[]> {
    return this.firestore.getCollection<FormTemplate>(CollectionName.forms);
  }

  getGenericForms(): Observable<FormTemplate[]> {
    return this.firestore.getQuery<FormTemplate>(
      CollectionName.forms,
      where('generic', '==', true),
    );
  }

  getFormsById(formIds: string[]): Observable<FormTemplate[]> {
    return this.firestore.getQuery<FormTemplate>(
      CollectionName.forms,
      where(documentId(), 'in', formIds),
    );
  }

  addForm(form: Partial<FormTemplate>): Observable<FormTemplate | undefined> {
    return this.firestore.addDocument<FormTemplate>(`${CollectionName.forms}`, {
      ...form,
      creationDate: Timestamp.now(),
      deleted: false,
    });
  }

  addFormVariant(formId: string, formVariant: FormTemplateLanguage): void {
    this.firestore.updateDocument<FormTemplate>(
      `${CollectionName.forms}/${formId}`,
      { variants: arrayUnion(formVariant) },
    );
  }

  updateForm(form: FormTemplate): void {
    this.firestore.updateDocument<FormTemplate>(
      `${CollectionName.forms}/${form.id}`,
      form,
    );
  }

  deleteForm(formId: string): void {
    this.firestore.deleteDocument<FormTemplate>(
      `${CollectionName.forms}/${formId}`,
    );
  }
}
