import { Pipe, PipeTransform } from '@angular/core';
import { Model } from '@verify/shared-components/models';

/**
 * Returns the display name for given model
 *
 * Usage:
 *  {model} | modelName
 */
@Pipe({
  name: 'modelName',
  standalone: true,
})
export class ModelNamePipe implements PipeTransform {
  transform(model: Model): string {
    return model?.firstName || model?.lastName
      ? [model?.firstName, model?.lastName].filter((part) => !!part).join(' ')
      : model?.email;
  }
}
