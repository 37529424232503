@if (modelConsentFormInfo && !askRevoke && !isSigning) {
  <div
    class="container form-container"
    [class.pdf-container]="modelConsentFormInfo.pdf"
  >
    <ng-container *ngIf="modelConsentFormInfo.pdf; else htmlContract">
      <div class="pdf-buttons">
        <button mat-icon-button (click)="zoomIn()">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button (click)="zoomOut()">
          <mat-icon>zoom_out</mat-icon>
        </button>
        <a mat-icon-button [href]="pdfUrl" target="_blank">
          <mat-icon>download</mat-icon>
        </a>
      </div>
      <pdf-viewer
        [src]="modelConsentFormInfo.pdf"
        [render-text]="false"
        [original-size]="false"
        [zoom]="pdfZoom"
        class="pdf-viewer"
      ></pdf-viewer>
      <div
        [class]="
          'pdf-status pdf-status-' + modelConsentFormInfo.status.toLowerCase()
        "
      >
        <span>{{
          "model.status-" + modelConsentFormInfo.status.toLowerCase()
            | translate
        }}</span
        ><span
          *ngIf="modelConsentFormInfo.status === ModelConsentStatus.signed"
        >
          {{ modelConsentFormInfo.signedDate | timestamp: "mediumDate" }}</span
        >
        <span
          *ngIf="modelConsentFormInfo.status === ModelConsentStatus.revoked"
        >
          {{ modelConsentFormInfo.revokedDate | timestamp: "mediumDate" }}</span
        >
      </div>
    </ng-container>

    <ng-template #htmlContract>
      <div [innerHTML]="formText"></div>
      <div class="model-info">
        <img class="model-info-image" [src]="modelConsentFormInfo.selfie" />
        <div class="model-info-container">
          <div class="model-info-row">
            <span>{{ "model.first-name" | translate }}</span
            ><span>{{ modelConsentFormInfo.model?.firstName }}</span>
          </div>
          <div class="model-info-row">
            <span>{{ "model.last-name" | translate }}</span
            ><span>{{ modelConsentFormInfo.model?.lastName }}</span>
          </div>
          <div class="model-info-row">
            <span>{{ "model.email" | translate }}</span
            ><span>{{ modelConsentFormInfo.model?.email }}</span>
          </div>
          <div class="model-info-row" *ngIf="modelConsentFormInfo.signedDate">
            <span>{{ "model.sign-date" | translate }}</span
            ><span>{{
              modelConsentFormInfo.signedDate | timestamp: "mediumDate"
            }}</span>
          </div>
          <div class="model-info-row" *ngIf="modelConsentFormInfo.revokedDate">
            <span>{{ "model.revoke-date" | translate }}</span
            ><span>{{
              modelConsentFormInfo.revokedDate | timestamp: "mediumDate"
            }}</span>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="buttons">
      <button
        *ngIf="!modelConsentFormInfo.signedDate"
        class="sign-button"
        mat-flat-button
        color="primary"
        (click)="onConfirmSign()"
      >
        {{ "model.confirm-sign" | translate }}
      </button>
      <button
        *ngIf="isExtensionRequest"
        class="sign-button"
        mat-flat-button
        color="primary"
        (click)="onConfirmSign()"
      >
        {{
          "model.confirm-extend"
            | translate
              : {
                  date:
                    (modelConsentFormInfo.expirationDate
                    | timestamp: "mediumDate"),
                }
        }}
      </button>
      <button
        *ngIf="
          modelConsentFormInfo.signedDate &&
          modelConsentFormInfo.enableRevoke &&
          !modelConsentFormInfo.revokedDate &&
          !justSigned
        "
        class="sign-button"
        mat-flat-button
        color="primary"
        (click)="onAskRevoke()"
      >
        {{ "model.revoke-consent" | translate }}
      </button>
    </div>
  </div>

  @if (modelConsentFormInfo.assets?.length) {
    <h6>{{ "model.your-photos" | translate }}</h6>
    <div class="assets-grid">
      <app-model-form-asset
        *ngFor="let asset of modelConsentFormInfo.assets"
        [asset]="asset"
        [enableDecline]="
          modelConsentFormInfo.assetApproval &&
          !modelConsentFormInfo.revokedDate
        "
        (declineAsset)="onDeclineAsset($event)"
      ></app-model-form-asset>
    </div>
  }
}

<div class="signing-info" *ngIf="isSigning">
  <div>
    <lib-spinner class="loading-spinner"></lib-spinner>
  </div>
</div>

<div class="revoke-info" *ngIf="askRevoke">
  <ng-container *ngIf="!revoked; else revokedTemplate">
    <h4>{{ "model.revoke-consent-confirm" | translate }}</h4>
    <div>
      <lib-spinner *ngIf="isRevoking" class="loading-spinner"></lib-spinner>
      <button
        *ngIf="!isRevoking"
        mat-flat-button
        color="primary"
        (click)="onRevoke()"
      >
        {{ "model.revoke" | translate }}
      </button>
    </div>
  </ng-container>

  <ng-template #revokedTemplate>
    <h4>{{ "model.revoke-success" | translate }}</h4>
  </ng-template>
</div>
