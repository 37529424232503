import { inject, Pipe, PipeTransform } from '@angular/core';
import { UserRole } from '@verify/shared-components/models';

import { AuthService } from '../auth.service';

/**
 * Returns a boolean indicating whether currently
 * logged in user has given role
 *
 * Usage:
 *  'role-name' | hasRole
 */
@Pipe({
  name: 'hasRole',
  standalone: true,
})
export class HasRolePipe implements PipeTransform {
  private authService: AuthService = inject(AuthService);

  transform(value: UserRole | UserRole[]): boolean {
    let rolesRequired: UserRole[];
    if (!Array.isArray(value)) {
      rolesRequired = [value];
    } else {
      rolesRequired = value;
    }
    for (const role of rolesRequired) {
      if (!this.authService.currentUser?.roles?.includes(role)) {
        return false;
      }
    }
    return true;
  }
}
