export enum FormVariables {
  projectName = 'project_name',
  projectDescription = 'project_description',
  projectPhotoshootDate = 'project_photoshoot_date',
  modelFirstName = 'model_first_name',
  modelLastName = 'model_last_name',
  modelEmail = 'model_email',
  signedDate = 'current_date',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enrichedFormText = (formText: string, values: any) =>
  Object.entries(values).reduce(
    (acc, [name, value]) =>
      acc?.replace(
        new RegExp(
          `{{${FormVariables[name as keyof typeof FormVariables] ? FormVariables[name as keyof typeof FormVariables] : name}}}`,
          'g',
        ),
        value as string,
      ),
    formText,
  );
