import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  Asset,
  AssetStatus,
  ModelConsentStatus,
} from '@verify/shared-components/models';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { ModelNamePipe } from '../../../pipes';
import { AssetTileComponent } from '../../shared/asset-tile/asset-tile.component';
import { AssetFilter, AssetOrderBy } from '../project.component';

@Component({
  selector: 'app-project-assets',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AssetTileComponent,
    ModelNamePipe,
    MatIconModule,
    DragToSelectModule,
    MatButtonModule,
    MatChipsModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  templateUrl: './project-assets.component.html',
  styleUrl: './project-assets.component.scss',
})
export class ProjectAssetsComponent implements OnChanges {
  private router = inject(Router);

  @Input()
  assets: Asset[];
  @Input()
  selectedAssets: string[];
  @Input()
  orderBy: AssetOrderBy;
  @Input()
  filters: AssetFilter[];

  // @Output()
  // selectAsset = new EventEmitter<string>();
  @Output()
  setSelection = new EventEmitter<string[]>();
  @Output()
  setOrderBy = new EventEmitter<AssetOrderBy>();
  @Output()
  addFilter = new EventEmitter<AssetFilter>();
  @Output()
  removeFilter = new EventEmitter<AssetFilter['type']>();

  filteredAssets: Asset[];
  ModelConsentStatus = ModelConsentStatus;
  searchValue = '';

  sortableColumns: Array<{
    field: keyof Asset;
    translation: string;
  }> = [
    {
      field: 'name',
      translation: 'asset.name',
    },
    {
      field: 'creationDate',
      translation: 'asset.creation-date',
    },
    {
      field: 'modificationDate',
      translation: 'asset.modification-date',
    },
    {
      field: 'matches',
      translation: 'asset.matches-found',
    },
  ];

  statusCounts: {
    signed: number;
    revoked: number;
    pending: number;
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['assets'] || changes['orderBy'] || changes['filters']) {
      this.updateFilteredAssets();
    }
  }

  get orderByColumn(): {
    field: string;
    translation: string;
  } {
    return this.sortableColumns.find(
      (column) => column.field === this.orderBy.field,
    );
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
      event.preventDefault();
      this.setSelection.emit(this.assets.map((asset) => asset.id));
    }
  }

  onOrderBy(field: keyof Asset): void {
    this.setOrderBy.emit({ field, direction: 'asc' });
  }

  onOrderByDirection(): void {
    this.setOrderBy.emit({
      field: this.orderBy.field,
      direction: this.orderBy.direction === 'asc' ? 'desc' : 'asc',
    });
  }

  onSearch(): void {
    if (this.searchValue) {
      this.addFilter.emit({ type: 'search', value: this.searchValue });
    } else {
      this.onRemoveFilter('search');
    }
  }

  onOpenAsset(asset: Asset): void {
    this.router.navigate(['asset', asset.id]);
  }

  onSetSelection(assetIds: string[]): void {
    this.setSelection.emit(assetIds);
  }

  onSelectAsset(assetId: string): void {
    if (this.selectedAssets.includes(assetId)) {
      this.selectedAssets = this.selectedAssets.filter(
        (selectionAssetId) => selectionAssetId !== assetId,
      );
    } else {
      this.selectedAssets = [...this.selectedAssets, assetId];
    }
    this.setSelection.emit(this.selectedAssets);
    //this.onLoadModelTags();
  }

  onAddFilter(type: AssetFilter['type'], value: AssetFilter['value']): void {
    this.addFilter.emit({ type, value });
  }

  onRemoveFilter(type: AssetFilter['type']): void {
    this.removeFilter.emit(type);
    if (type === 'search') {
      this.searchValue = '';
    }
  }

  trackByAsset(_: number, asset: Asset): string {
    return asset.id;
  }

  private updateFilteredAssets(): void {
    this.filteredAssets = (this.assets || [])
      .filter((asset) =>
        this.filters
          ? this.filters.every(({ type, value }) => {
              switch (type) {
                case 'model':
                  return asset.modelIds?.includes(value.id);
                case 'status':
                  return asset.modelConsentStatus === value;
                case 'verified':
                  return (asset.status === AssetStatus.verified) === value;
                case 'no-models':
                  return (asset.modelIds || []).length === 0;
                case 'no-exports':
                  return (asset.exports || []).length === 0;
                case 'search':
                  return asset.name.toLowerCase().includes(value);
              }
            })
          : true,
      )
      .sort((a, b) =>
        (a[this.orderBy.field] < b[this.orderBy.field] &&
          this.orderBy.direction === 'asc') ||
        (a[this.orderBy.field] > b[this.orderBy.field] &&
          this.orderBy.direction === 'desc')
          ? -1
          : 1,
      );

    this.statusCounts = {
      signed: (this.filteredAssets || []).filter(
        (asset) => asset.modelConsentStatus === ModelConsentStatus.signed,
      ).length,
      revoked: (this.filteredAssets || []).filter(
        (asset) => asset.modelConsentStatus === ModelConsentStatus.revoked,
      ).length,
      pending: (this.filteredAssets || []).filter(
        (asset) =>
          asset.modelConsentStatus === ModelConsentStatus.pending ||
          asset.modelConsentStatus === ModelConsentStatus.extendRequest,
      ).length,
    };
  }
}
