<app-header [title]="'asset.assets' | translate"> </app-header>

<div class="container">
  <div>
    <mat-form-field subscriptSizing="dynamic" class="search">
      <input
        matInput
        type="text"
        [formControl]="searchInput"
        [placeholder]="'search.search' | translate"
        (keyup.enter)="onSearch()"
      />
      <button matPrefix mat-icon-button color="primary" (click)="onSearch()">
        <mat-icon>search</mat-icon>
      </button>
      <button
        matSuffix
        mat-icon-button
        color="primary"
        (click)="imageUpload.click()"
      >
        <mat-icon>image</mat-icon>
      </button>
    </mat-form-field>
    <input
      class="file-input"
      type="file"
      #imageUpload
      name="imageUpload"
      accept="image/*"
      (change)="onImageSearch($event)"
    />
  </div>

  <lib-spinner class="spinner" *ngIf="loading"></lib-spinner>
  <div class="assets-grid">
    <app-asset-tile
      *ngFor="let asset of (assets$ | async)?.assets; trackBy: trackByAsset"
      [asset]="asset"
      [selectable]="true"
      (openAsset)="onOpenAsset($event)"
    ></app-asset-tile>
  </div>
</div>
