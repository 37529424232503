import { Injectable, inject } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { FunctionName } from '@verify/shared-components/helpers';
import { Observable, from, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FunctionService {
  private functions = inject(Functions);

  constructor() {}

  call<InputT, OutputT>(
    functionName: FunctionName,
    input: InputT,
    options?: { timeout?: number },
  ): Observable<OutputT> {
    const callable = httpsCallable<InputT, OutputT>(
      this.functions,
      functionName,
      options || {},
    );
    return from(callable(input)).pipe(map((result) => result.data));
  }
}
