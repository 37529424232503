import firebaseui from 'firebaseui';
import { firebase } from 'firebaseui-angular';

export const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'redirect', // redirect or popup
  signInOptions: [
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      disableSignUp: { status: true },
    },
  ],

  tosUrl: 'https://joinverify.com/',
  privacyPolicyUrl: 'https://joinverify.com/',
  // If there is only one sign-in provider eligible for the user,
  // whether to show the provider selection page.
  immediateFederatedRedirect: true,
};
