import { Routes } from '@angular/router';
import { LoginComponent } from '@verify/shared-components/components';
import { AuthGuard, AuthResolver } from '@verify/shared-components/helpers';
import { AssetComponent } from './modules/asset/asset.component';
import { AssetsComponent } from './modules/assets/assets.component';
import { ExternalComponent } from './modules/external/external.component';
import { ModelFormComponent } from './modules/external/model-form/model-form.component';
import { RegisterModelComponent } from './modules/external/register-model/register-model.component';
import { LayoutComponent } from './modules/layout/layout.component';
import { ModelsComponent } from './modules/models/models.component';
import { ProjectComponent } from './modules/project/project.component';
import { ProjectsComponent } from './modules/projects/projects.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'projects',
      },
      {
        path: 'projects',
        component: ProjectsComponent,
      },
      {
        path: 'project/:projectId',
        component: ProjectComponent,
      },
      {
        path: 'asset/:assetId',
        component: AssetComponent,
      },
      {
        path: 'assets',
        component: AssetsComponent,
      },
      {
        path: 'models',
        component: ModelsComponent,
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./modules/settings/settings.routes').then(
            (mod) => mod.settingsRoutes,
          ),
      },
    ],
  },
  {
    path: 'ext',
    component: ExternalComponent,
    children: [
      {
        path: 'model-release/:projectId/:formTemplateId',
        component: RegisterModelComponent,
      },
      {
        path: 'model-release/:modelConsentId',
        component: RegisterModelComponent,
      },
      {
        path: 'model-form/:modelConsentId',
        component: ModelFormComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: {
      ready: AuthResolver,
    },
  },
];
