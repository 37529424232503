import { filter, map, Observable, switchMap } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { User, UserRole } from '@verify/shared-components/models';
import {
  AuthService,
  DialogService,
  TimestampPipe,
} from '@verify/shared-components/services';

import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '@verify/shared-components/components';

import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule, Sort } from '@angular/material/sort';
import { UserService } from '../../../services';
import { HeaderComponent } from '../../shared/header/header.component';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';

export interface UserFilter {
  type: 'role' | 'search';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

@Component({
    selector: 'app-users',
    imports: [
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatSortModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatMenuModule,
        FormsModule,
        TimestampPipe,
        TranslateModule,
        HeaderComponent,
    ],
    templateUrl: './users.component.html',
    styleUrl: './users.component.scss'
})
export class UsersComponent {
  private userService = inject(UserService);
  private dialogService = inject(DialogService);
  private snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);
  private authService: AuthService = inject(AuthService);

  users$: Observable<User[]>;
  filteredUsers$: Observable<User[]>;
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'language',
    'sso',
    'roles',
    'creationDate',
    'actions',
  ];
  searchValue = '';
  filters: UserFilter[];
  orderBy: { field: keyof User; direction: 'asc' | 'desc' };

  constructor() {
    this.users$ = this.userService.getUsers();
    if (!(this.authService.tenant?.identityProviders?.length > 0)) {
      this.displayedColumns = this.displayedColumns.filter(
        (column) => column !== 'sso',
      );
    }
    this.updateFilteredUsers();
  }

  get roles(): UserRole[] {
    return Object.values(UserRole) as UserRole[];
  }

  onAddUser(): void {
    this.dialogService
      .openDialog<Partial<User>>(EditUserDialogComponent, {
        width: this.dialogService.widths.medium,
      })
      .pipe(filter((result) => !!result))
      .subscribe(() => {
        this.snackBar.open(
          this.translateService.instant('user.add-success'),
          null,
          { duration: 3000 },
        );
      });
  }

  onEditUser(user: User): void {
    this.dialogService.openDialog<Partial<User>>(EditUserDialogComponent, {
      width: this.dialogService.widths.medium,
      data: { user },
    });
  }

  onDeleteUser(user: User): void {
    this.dialogService
      .openDialog<boolean>(ConfirmDialogComponent, {})
      .pipe(
        filter((confirmed) => !!confirmed),
        switchMap(() => this.userService.deleteUser(user.id)),
      )
      .subscribe(() => {
        this.snackBar.open(
          this.translateService.instant('user.remove-success'),
          null,
          {
            duration: 3000,
          },
        );
      });
  }

  onSortChange(sortState: Sort): void {
    this.orderBy =
      sortState.active && sortState.direction
        ? {
            field: sortState.active as keyof User,
            direction: sortState.direction,
          }
        : null;
    this.updateFilteredUsers();
  }

  onSearch(): void {
    if (this.searchValue) {
      this.onAddFilter('search', this.searchValue);
    } else {
      this.onRemoveFilter('search');
    }
  }

  onAddFilter(type: UserFilter['type'], value: UserFilter['value']): void {
    const filters = (this.filters || []).filter((filt) => filt.type !== type);
    this.filters = [...filters, { type, value }];
    localStorage.setItem(`user-filters`, JSON.stringify(this.filters));
    this.updateFilteredUsers();
  }

  onRemoveFilter(type: UserFilter['type']): void {
    this.filters = (this.filters || []).filter((filt) => filt.type !== type);
    localStorage.setItem(`user-filters`, JSON.stringify(this.filters));
    this.updateFilteredUsers();
    if (type === 'search') {
      this.searchValue = '';
    }
  }

  private updateFilteredUsers(): void {
    this.filteredUsers$ = this.users$.pipe(
      map((users) => {
        const filteredUsers = users.filter((user) =>
          this.filters
            ? this.filters.every(({ type, value }) => {
                switch (type) {
                  case 'role':
                    return user.roles?.includes(value);
                  case 'search':
                    return (
                      user.firstName.toLowerCase().includes(value) ||
                      user.lastName.toLowerCase().includes(value) ||
                      user.email.toLowerCase().includes(value)
                    );
                }
              })
            : true,
        );
        return this.orderBy
          ? filteredUsers.sort((a, b) => {
              const valueA =
                this.orderBy.field === 'creationDate'
                  ? a.creationDate?.toMillis() || 0
                  : a[this.orderBy.field];
              const valueB =
                this.orderBy.field === 'creationDate'
                  ? b.creationDate?.toMillis() || 0
                  : b[this.orderBy.field];
              return (valueA < valueB && this.orderBy.direction === 'asc') ||
                (valueA > valueB && this.orderBy.direction === 'desc')
                ? -1
                : 1;
            })
          : filteredUsers;
      }),
    );
  }
}
