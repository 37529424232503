<div class="project-tile" (click)="onOpenProject()">
  <h6 class="project-tile-name">{{ project.name }}</h6>

  <div class="project-tile-assets">
    @let imageUrls = imageUrls$ | async;
    <div
      class="project-tile-assets-asset"
      [class.project-tile-assets-asset-placeholder]="!(imageUrls$ | async)?.[0]"
    >
      <img [src]="imageUrls?.[0] || 'assets/placeholder.svg'" loading="lazy" />
    </div>

    <div class="project-tile-assets-sub" *ngIf="imageUrls?.length > 1">
      <div
        class="project-tile-assets-asset"
        [class.project-tile-assets-asset-placeholder]="!imageUrls?.[1]"
      >
        <img
          [src]="imageUrls?.[1] || 'assets/placeholder.svg'"
          loading="lazy"
        />
      </div>
      <div
        class="project-tile-assets-asset"
        [class.project-tile-assets-asset-placeholder]="!imageUrls?.[2]"
      >
        <img
          [src]="imageUrls?.[2] || 'assets/placeholder.svg'"
          loading="lazy"
        />
      </div>
    </div>
  </div>

  <div class="project-tile-footer">
    <mat-icon>person</mat-icon>
    <span
      >{{ (models$ | async)?.length || 0 }} {{ "base.total" | translate }}</span
    >
    <mat-icon>image</mat-icon>
    <span
      >{{ (assets$ | async)?.length || 0 }} {{ "base.total" | translate }}</span
    >
    <div class="status-tag-dark">
      <mat-icon class="status-tag-icon">language</mat-icon>
      <span>{{ matchesFound$ | async }}</span>
    </div>
  </div>
</div>
