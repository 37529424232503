<h4 mat-dialog-title>
  {{ "project.extend-project" | translate }}
</h4>

<mat-dialog-content>
  <div class="form" [formGroup]="form" *ngIf="!loading; else loadingTemplate">
    <p>{{ "project.extend-project-info" | translate }}</p>
    <mat-form-field>
      <mat-label>{{ "project.description" | translate }}</mat-label>
      <textarea matInput formControlName="description" rows="4"></textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "project.expiration-date" | translate }}</mat-label>
      <input
        matInput
        formControlName="expirationDate"
        [matDatepicker]="expirationPicker"
      />
      <mat-hint>DD-MM-YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="expirationPicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #expirationPicker></mat-datepicker>
    </mat-form-field>
  </div>
  <ng-template #loadingTemplate>
    <lib-spinner></lib-spinner>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="secondary" (click)="onClose()">
    {{ "base.cancel" | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onExtend()"
    [disabled]="form.invalid"
  >
    {{ "base.request" | translate }}
  </button>
</mat-dialog-actions>
