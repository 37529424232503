import { ɵgetAllInstancesOf as _getAllInstancesOf, ɵgetDefaultInstanceOf as _getDefaultInstanceOf, VERSION, ɵAngularFireSchedulers as _AngularFireSchedulers, ɵzoneWrap as _zoneWrap } from '@angular/fire';
import { timer, from } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, Optional, PLATFORM_ID, NgModule, makeEnvironmentProviders, NgZone, Injector } from '@angular/core';
import { FirebaseApp, FirebaseApps } from '@angular/fire/app';
import { registerVersion } from 'firebase/app';
import { traceUntil as traceUntil$1, traceWhile as traceWhile$1, traceUntilComplete as traceUntilComplete$1, traceUntilFirst as traceUntilFirst$1 } from 'rxfire/performance';
import { getPerformance as getPerformance$1, initializePerformance as initializePerformance$1, trace as trace$1 } from 'firebase/performance';
export * from 'firebase/performance';
class Performance {
  constructor(performance) {
    return performance;
  }
}
const PERFORMANCE_PROVIDER_NAME = 'performance';
class PerformanceInstances {
  constructor() {
    return _getAllInstancesOf(PERFORMANCE_PROVIDER_NAME);
  }
}
const performanceInstance$ = timer(0, 300).pipe(concatMap(() => from(_getAllInstancesOf(PERFORMANCE_PROVIDER_NAME))), distinct());
const PROVIDED_PERFORMANCE_INSTANCES = new InjectionToken('angularfire2.performance-instances');
function defaultPerformanceInstanceFactory(provided, defaultApp,
// eslint-disable-next-line @typescript-eslint/ban-types
platform) {
  if (!isPlatformBrowser(platform)) {
    return null;
  }
  const defaultPerformance = _getDefaultInstanceOf(PERFORMANCE_PROVIDER_NAME, provided, defaultApp);
  return defaultPerformance && new Performance(defaultPerformance);
}
function performanceInstanceFactory(fn) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (zone, platform, injector) => {
    if (!isPlatformBrowser(platform)) {
      return null;
    }
    const performance = zone.runOutsideAngular(() => fn(injector));
    return new Performance(performance);
  };
}
const PERFORMANCE_INSTANCES_PROVIDER = {
  provide: PerformanceInstances,
  deps: [[new Optional(), PROVIDED_PERFORMANCE_INSTANCES]]
};
const DEFAULT_PERFORMANCE_INSTANCE_PROVIDER = {
  provide: Performance,
  useFactory: defaultPerformanceInstanceFactory,
  deps: [[new Optional(), PROVIDED_PERFORMANCE_INSTANCES], FirebaseApp, PLATFORM_ID]
};
class PerformanceModule {
  constructor() {
    registerVersion('angularfire', VERSION.full, 'perf');
  }
  static ɵfac = function PerformanceModule_Factory(t) {
    return new (t || PerformanceModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: PerformanceModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [DEFAULT_PERFORMANCE_INSTANCE_PROVIDER, PERFORMANCE_INSTANCES_PROVIDER]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PerformanceModule, [{
    type: NgModule,
    args: [{
      providers: [DEFAULT_PERFORMANCE_INSTANCE_PROVIDER, PERFORMANCE_INSTANCES_PROVIDER]
    }]
  }], () => [], null);
})();
function providePerformance(fn, ...deps) {
  registerVersion('angularfire', VERSION.full, 'perf');
  return makeEnvironmentProviders([DEFAULT_PERFORMANCE_INSTANCE_PROVIDER, PERFORMANCE_INSTANCES_PROVIDER, {
    provide: PROVIDED_PERFORMANCE_INSTANCES,
    useFactory: performanceInstanceFactory(fn),
    multi: true,
    deps: [NgZone, PLATFORM_ID, Injector, _AngularFireSchedulers, FirebaseApps, ...deps]
  }]);
}

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const traceUntil = _zoneWrap(traceUntil$1, true);
const traceWhile = _zoneWrap(traceWhile$1, true);
const traceUntilComplete = _zoneWrap(traceUntilComplete$1, true);
const traceUntilFirst = _zoneWrap(traceUntilFirst$1, true);

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const getPerformance = _zoneWrap(getPerformance$1, true);
const initializePerformance = _zoneWrap(initializePerformance$1, true);
const trace = _zoneWrap(trace$1, true);

/**
 * Generated bundle index. Do not edit.
 */

export { Performance, PerformanceInstances, PerformanceModule, getPerformance, initializePerformance, performanceInstance$, providePerformance, trace, traceUntil, traceUntilComplete, traceUntilFirst, traceWhile };
