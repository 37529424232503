<h4 mat-dialog-title>{{ "model.add-model" | translate }}</h4>
<mat-dialog-content class="dialog-content">
  <ng-container *ngIf="!loading; else loadingTemplate">
    <mat-form-field class="form-select">
      <mat-label>{{
        "model-release-form.model-release-form" | translate
      }}</mat-label>
      <mat-select [(value)]="selectedFormTemplateId">
        <mat-optgroup
          *ngIf="(genericFormTemplates$ | async)?.length > 0"
          [label]="'model-release-form.generic-forms' | translate"
        >
          <mat-option
            *ngFor="let formTemplate of genericFormTemplates$ | async"
            [value]="formTemplate.id"
            >{{ formTemplate.name }}</mat-option
          >
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "model.add-email" | translate }}</mat-label>
      <input
        type="text"
        matInput
        [formControl]="emailControl"
        (keyup.enter)="onAddEmailInvite()"
      />
      <mat-icon class="clickable-icon" matSuffix (click)="onAddEmailInvite()"
        >add</mat-icon
      >
    </mat-form-field>

    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let emailInvite of emailInvites">
        <mat-icon
          class="clickable-icon"
          matListItemIcon
          (click)="onRemoveEmailInvite(emailInvite)"
          >delete</mat-icon
        >
        <p>{{ emailInvite }}</p></mat-list-item
      >
    </mat-list>
  </ng-container>
  <ng-template #loadingTemplate>
    <lib-spinner></lib-spinner>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="secondary" (click)="onClose()">
    {{ "base.close" | translate }}
  </button>
  <button mat-flat-button color="primary" (click)="onSendEmailInvites()">
    {{ "model.send-invites" | translate }}
  </button>
</mat-dialog-actions>
