import {
  Directive,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AssetFile, AssetFileSize } from '@verify/shared-components/models';
import { StorageService } from '../storage.service';

@Directive({
  selector: '[appImagePreview]',
  standalone: true,
})
export class ImagePreviewDirective implements OnChanges {
  private storageService = inject(StorageService);

  @Input()
  files: AssetFile[];
  @Input()
  fileSize: AssetFileSize;
  @Input()
  fallbackSrc = 'assets/placeholder.svg';

  @Output()
  loaded = new EventEmitter();

  @HostBinding('attr.src')
  src = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['files'] || changes['fileSize']) {
      const file = this.files?.find((file) => file.size === this.fileSize);
      if (file) {
        this.storageService
          .getDownloadUrl(file)
          .subscribe((downloadUrl) => (this.src = downloadUrl));
      } else {
        this.src = this.fallbackSrc;
      }
    }
  }
}
