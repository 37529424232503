import { Timestamp } from '@angular/fire/firestore';
import { AssetFile } from '../shared/asset.model';

export enum UserRole {
  userManagement = 'USER_MANAGEMENT', //User can view and edit all users
  projectAdmin = 'PROJECT_ADMIN', // Gives user access to all projects
  projectManagement = 'PROJECT_MANAGEMENT', // User can edit and add new projects
  projectUploading = 'PROJECT_UPLOADING', // User can upload new assets
  projectDownloading = 'PROJECT_DOWNLOADING', // User can download assets
  projectExport = 'PROJECT_EXPORT', // User can approve and download/export assets
}

export interface User {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  language?: string;
  roles: UserRole[];
  selfie: AssetFile[];
  creationDate: Timestamp;
  modificationDate: Timestamp;
  deleted?: boolean;
  provisioned?: boolean;
}
