import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '@verify/shared-components/components';
import {
  Asset,
  AssetFileSize,
  Project,
} from '@verify/shared-components/models';
import {
  ImagePreviewDirective,
  StorageService,
  TimestampPipe,
} from '@verify/shared-components/services';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AssetService, ProjectService } from '../../../services';
import { HeaderComponent } from '../../shared/header/header.component';

@Component({
    selector: 'app-deleted',
    imports: [
        CommonModule,
        MatButtonModule,
        MatTableModule,
        TranslateModule,
        HeaderComponent,
        TimestampPipe,
        MatPaginatorModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ImagePreviewDirective,
        SpinnerComponent,
    ],
    templateUrl: './deleted.component.html',
    styleUrl: './deleted.component.scss'
})
export class DeletedComponent implements OnDestroy {
  private projectService = inject(ProjectService);
  private assetService = inject(AssetService);
  private storageService = inject(StorageService);

  private destroy$ = new Subject<void>();

  projectsDataSource = new MatTableDataSource<Project>();
  assetsDataSource = new MatTableDataSource<Asset>();
  projectDisplayedColumns: string[] = ['name', 'deleted', 'actions'];
  assetDisplayedColumns: string[] = ['preview', 'name', 'deleted', 'actions'];
  AssetFileSize = AssetFileSize;
  deleting: string[] = [];

  @ViewChild('projectPaginator') projectPaginator: MatPaginator;
  @ViewChild('assetPaginator') assetPaginator: MatPaginator;

  constructor() {
    this.projectService
      .getDeletedProjects()
      .pipe(takeUntil(this.destroy$))
      .subscribe((projects) => {
        this.projectsDataSource.data = projects;
        this.projectsDataSource.paginator = this.projectPaginator;
      });
    this.assetService
      .getDeletedAssets()
      .pipe(takeUntil(this.destroy$))
      .subscribe((assets) => {
        this.assetsDataSource.data = assets;
        this.assetsDataSource.paginator = this.assetPaginator;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getAssetPreview(asset: Asset): Observable<string> {
    const file = asset?.files?.find(
      (file) => file.size === AssetFileSize.size300,
    );
    return file ? this.storageService.getDownloadUrl(file) : null;
  }

  onUndoDeleteProject(projectId: string) {
    this.projectService.undoDeleteProject(projectId);
  }

  onUndoDeleteAsset(assetid: string) {
    this.assetService.undoDeleteAsset(assetid);
  }

  onFilterProjects(value: string) {
    this.projectsDataSource.filter = value;
  }

  onDeleteProject(projectId: string) {
    this.deleting.push(projectId);
    this.projectService.permanentlyDeleteProjects([projectId]).subscribe(() => {
      this.deleting = this.deleting.filter((id) => id != projectId);
    });
  }

  onDeleteAsset(assetId: string) {
    this.deleting.push(assetId);
    this.assetService.permanentlyDeleteAssets([assetId]).subscribe(() => {
      this.deleting = this.deleting.filter((id) => id != assetId);
    });
  }

  onFilterAssets(value: string) {
    this.assetsDataSource.filter = value;
  }
}
