import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterOutlet } from '@angular/router';
import { AssetFileSize, TenantInfo } from '@verify/shared-components/models';
import { AssetUrlPipe, AuthService } from '@verify/shared-components/services';

@Component({
  selector: 'app-external',
  imports: [RouterOutlet, MatToolbarModule, CommonModule, AssetUrlPipe],
  templateUrl: './external.component.html',
  styleUrl: './external.component.scss',
})
export class ExternalComponent {
  private authService = inject(AuthService);

  AssetFileSize = AssetFileSize;

  get tenantInfo(): TenantInfo {
    return this.authService.tenantInfo;
  }
}
