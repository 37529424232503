<ng-container *ngIf="user">
  <div
    class="layout-toolbar"
    [class.layout-toolbar-hover]="menuHover"
    (mouseenter)="onMenuEnter()"
    (mouseleave)="onMenuLeave()"
  >
    <div class="layout-toolbar-logo">
      @let logoSmallUrl =
        (tenantInfo$ | async)?.logoSmall
          | assetUrl: AssetFileSize.size100
          | async;
      @let logoUrl =
        (tenantInfo$ | async)?.logo | assetUrl: AssetFileSize.size100 | async;
      <img
        class="layout-toolbar-logo-image"
        [src]="
          menuHover ? logoUrl : logoSmallUrl || 'assets/verify-logo-small.png'
        "
        routerLink="/"
      />
    </div>
    <div class="layout-toolbar-top">
      <button
        mat-icon-button
        class="layout-toolbar-button"
        (click)="onToggleSearch()"
      >
        <mat-icon>search</mat-icon>
        <span class="layout-toolbar-button-label">{{
          "search.search" | translate
        }}</span>
      </button>
      <button
        mat-icon-button
        class="layout-toolbar-button"
        routerLink="/projects"
      >
        <mat-icon>team_dashboard</mat-icon>
        <span class="layout-toolbar-button-label">{{
          "project.projects" | translate
        }}</span>
      </button>
      <button
        class="layout-toolbar-button"
        mat-icon-button
        (click)="onToggleNotifications(); notificationsSidenav.toggle()"
        [matBadge]="notificationCount$ | async"
        [matBadgeHidden]="(notificationCount$ | async) < 1"
      >
        <mat-icon>notifications</mat-icon>
        <span class="layout-toolbar-button-label">{{
          "notification.notifications" | translate
        }}</span>
      </button>
    </div>

    <div class="layout-toolbar-bottom">
      <button
        mat-icon-button
        class="layout-toolbar-button"
        routerLink="/settings/user-management"
      >
        <mat-icon>settings</mat-icon>
        <span class="layout-toolbar-button-label">{{
          "base.settings" | translate
        }}</span>
      </button>
      <button
        mat-icon-button
        class="layout-toolbar-button"
        routerLink="/settings/profile"
      >
        @let selfieUrl = user?.selfie | assetUrl: AssetFileSize.size100 | async;
        <img
          *ngIf="selfieUrl; else initialsTemplate"
          class="layout-toolbar-profile-imageicon"
          [src]="selfieUrl"
        />
        <ng-template #initialsTemplate
          ><div class="layout-toolbar-profile-icon">
            {{ initials }}
          </div></ng-template
        >
        <span class="layout-toolbar-button-label">{{ user | userName }}</span>
      </button>
    </div>
  </div>

  <!-- <mat-toolbar class="layout-toolbar" [class.search-toggle]="searchToggle">
    <div class="layout-toolbar-left">
      @let logoUrl =
        (tenantInfo$ | async)?.logo | assetUrl: AssetFileSize.size300 | async;
      <img
        class="layout-toolbar-logo"
        [src]="logoUrl || 'assets/logo-darkblue.png'"
        routerLink="/"
      />
    </div>

    <mat-form-field
      subscriptSizing="dynamic"
      class="layout-toolbar-search"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
    >
      <input
        matInput
        type="text"
        [formControl]="searchInput"
        [placeholder]="'search.search' | translate"
      />
      <button
        matPrefix
        mat-icon-button
        color="primary"
        (click)="onSearch(searchInput.value)"
      >
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <button
      *ngIf="searchToggle"
      mat-icon-button
      class="layout-toolbar-search-icon"
      (click)="onToggleSearch()"
    >
      <mat-icon>{{ "close" }}</mat-icon>
    </button>

    <div class="layout-toolbar-right">
      <button
        mat-icon-button
        class="layout-toolbar-search-icon"
        (click)="onToggleSearch()"
      >
        <mat-icon>{{ "search" }}</mat-icon>
      </button>
      <button
        class="layout-toolbar-button"
        mat-icon-button
        color="primary"
        (click)="onToggleNotifications(); notificationsSidenav.toggle()"
        [matBadge]="notificationCount$ | async"
        [matBadgeHidden]="(notificationCount$ | async) < 1"
      >
        <mat-icon>notifications</mat-icon>
      </button>
      <div class="layout-toolbar-profile">
        @let selfieUrl = user?.selfie | assetUrl: AssetFileSize.size100 | async;
        <img
          *ngIf="selfieUrl; else initialsTemplate"
          class="layout-toolbar-profile-imageicon"
          [src]="selfieUrl"
        />
        <ng-template #initialsTemplate
          ><div class="layout-toolbar-profile-icon">
            {{ initials }}
          </div></ng-template
        >
        <h6 class="layout-toolbar-profile-name">{{ user | userName }}</h6>
        <button mat-icon-button [matMenuTriggerFor]="settingsMenu">
          <mat-icon> arrow_drop_down </mat-icon>
        </button>
        <mat-menu #settingsMenu="matMenu">
          <button mat-menu-item routerLink="/settings/profile">
            <mat-icon>person</mat-icon>
            {{ "menu.user-profile" | translate }}
          </button>
          <button
            *ngIf="UserRule.projectAdmin | hasRule"
            mat-menu-item
            routerLink="/settings/forms"
          >
            <mat-icon>contract</mat-icon>
            {{ "menu.model-release-forms" | translate }}
          </button>
          <button
            *ngIf="UserRule.projectAdmin | hasRule"
            mat-menu-item
            routerLink="/settings/custom-fields"
          >
            <mat-icon>variable_add</mat-icon>
            {{ "menu.custom-fields" | translate }}
          </button>
          <button
            *ngIf="UserRule.userManagement | hasRule"
            mat-menu-item
            routerLink="/settings/users"
          >
            <mat-icon>group</mat-icon>
            {{ "menu.user-management" | translate }}
          </button>
          <button
            *ngIf="UserRule.userManagement | hasRule"
            mat-menu-item
            routerLink="/settings/deleted"
          >
            <mat-icon>delete_sweep</mat-icon>
            {{ "menu.deleted-items" | translate }}
          </button>
          <button
            *ngIf="UserRule.userManagement | hasRule"
            mat-menu-item
            routerLink="/settings/tenant"
          >
            <mat-icon>settings_account_box</mat-icon>
            {{ "menu.tenant" | translate }}
          </button>
          <button mat-menu-item (click)="onLogout()">
            <mat-icon>logout</mat-icon>
            {{ "menu.logout" | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar> -->

  <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
    <mat-sidenav-content><router-outlet /></mat-sidenav-content>
    <mat-sidenav
      #notificationsSidenav
      position="end"
      [fixedInViewport]="true"
      [fixedBottomGap]="0"
      ><ng-scrollbar
        visibility="hover"
        orientation="vertical"
        (reachedBottom)="onLoadNotifications()"
        [reachedBottomOffset]="100"
        ><div class="notifications" *ngIf="notificationsOpen">
          <app-notification
            *ngFor="
              let notification of notifications;
              trackBy: trackByNotification
            "
            [notification]="notification"
            (closeNotification)="onToggleNotifications()"
          ></app-notification>
          <p
            *ngIf="(notifications$ | async)?.length < 1"
            class="notifications-notfound text-body-2"
          >
            {{ "notification.no-notifications" | translate }}
          </p>
        </div></ng-scrollbar
      ></mat-sidenav
    >
  </mat-sidenav-container>
</ng-container>
