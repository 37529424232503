<!-- <div class="model-select-menu-icons">
  <mat-icon class="mat-elevation-z3 model-select-menu-icons-drag" cdkDragHandle
    >drag_pan</mat-icon
  >
  <mat-icon
    *ngIf="TenantToggle.assetAutoTagModels | hasToggle"
    class="mat-elevation-z3"
    (click)="onAutoTag()"
    [matTooltip]="'asset.auto-tag-models' | translate"
    >robot_2</mat-icon
  >
  <mat-icon class="mat-elevation-z3" (click)="onClose()">close</mat-icon>
</div> -->

<div class="model-select">
  <div class="model-select-buttons">
    <button
      *ngIf="TenantToggle.assetAutoTagModels | hasToggle"
      mat-stroked-button
      color="primary"
      (click)="onAutoTag()"
    >
      <mat-icon>robot_2</mat-icon>
      {{ "asset.auto-tag-models" | translate }}
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="stroked-icon-button-square"
      (click)="onClose()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-form-field
    class="model-select-search"
    subscriptSizing="dynamic"
    appearance="outline"
  >
    <input
      matInput
      type="text"
      [(ngModel)]="searchValue"
      [placeholder]="'search.search' | translate"
      (keyup)="onSearch()"
    />
    <button matPrefix mat-icon-button (click)="onSearch()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <ng-scrollbar
    visibility="hover"
    orientation="vertical"
    class="model-select-models"
  >
    <ng-container
      *ngFor="
        let modelConsent of filteredModelConsents;
        trackBy: trackByModelConsent
      "
    >
      <div
        mat-menu-item
        class="select-model-item"
        (click)="onSelectModel(modelConsent.model)"
      >
        <div class="select-model-item-container">
          <mat-checkbox
            *ngIf="multiselect"
            class="example-margin"
            [checked]="selectedModels?.includes(modelConsent.model.id)"
            [indeterminate]="false"
          >
          </mat-checkbox>
          <app-model-tile
            class="select-model-item-image"
            [model]="modelConsent.model"
            [modelConsent]="modelConsent.modelConsent"
            size="zoomable"
          ></app-model-tile>
          <p class="select-model-item-name text-subtitle-1">
            {{ modelConsent.model | modelName }}
          </p>
        </div>
      </div>
    </ng-container>
  </ng-scrollbar>
</div>
