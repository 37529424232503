import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterOutlet } from '@angular/router';
import { AssetFileSize } from '@verify/shared-components/models';
import {
  AuthService,
  StorageService,
} from '@verify/shared-components/services';
import { EMPTY, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'app-external',
  standalone: true,
  imports: [RouterOutlet, MatToolbarModule, CommonModule],
  templateUrl: './external.component.html',
  styleUrl: './external.component.scss',
})
export class ExternalComponent implements OnInit {
  private authService = inject(AuthService);
  private storageService = inject(StorageService);

  logoUrl$?: Observable<string>;

  ngOnInit(): void {
    this.logoUrl$ = this.authService.tenant$.pipe(
      switchMap((tenant) => {
        const file =
          tenant.logo?.find((file) => file.size === AssetFileSize.size300) ||
          tenant.logo?.[0];
        if (file) {
          return this.storageService.getDownloadUrl(file);
        } else return EMPTY;
      }),
    );
  }
}
