import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { AssetFileSize, Usage } from '@verify/shared-components/models';
import {
  AuthService,
  StorageService,
} from '@verify/shared-components/services';
import { map, Observable } from 'rxjs';
import { TenantService } from '../../../services/tenant.service';
import { HeaderComponent } from '../../shared/header/header.component';

@Component({
  selector: 'app-tenant',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatSelectModule,
    HeaderComponent,
    MatTableModule,
  ],
  templateUrl: './tenant.component.html',
  styleUrl: './tenant.component.scss',
})
export class TenantComponent implements OnInit {
  private authService = inject(AuthService);
  private formBuilder = inject(FormBuilder);
  private tenantService = inject(TenantService);
  private storageService = inject(StorageService);

  readonly form = this.formBuilder.nonNullable.group({
    name: ['', Validators.required],
    nameSub: [''],
    emailSignature: [''],
    websiteUrl: [''],
    projectExpireNotification: new FormControl<number[]>([]),
  });

  logo?: File;
  logoPreview?: string;
  imageUrl$?: Observable<string>;
  usage$?: Observable<Usage[]>;
  notificationDays = [7, 30, 60, 90, 180, 365];

  displayedColumns: string[] = ['date', 'created', 'removed', 'total', 'quota'];

  ngOnInit(): void {
    this.form.patchValue(this.authService.tenant);
    const file =
      this.authService.tenant.logo?.find(
        (file) => file.size === AssetFileSize.size300,
      ) || this.authService.tenant.logo?.[0];
    if (file) {
      this.imageUrl$ = this.storageService.getDownloadUrl(file);
    }
    this.usage$ = this.tenantService
      .getFprintUsage()
      .pipe(
        map((usage) =>
          usage.filter(
            (month, idx) =>
              idx === usage.length - 1 ||
              month.total !== 0 ||
              month.created !== 0 ||
              month.removed !== 0,
          ),
        ),
      );
    this.usage$.subscribe((usage) => {
      console.log(usage);
    });
  }

  onSave(): void {
    const {
      name,
      nameSub,
      emailSignature,
      websiteUrl,
      projectExpireNotification,
    } = this.form.value;

    this.tenantService.updateTenant({
      name,
      nameSub,
      emailSignature,
      websiteUrl,
      projectExpireNotification,
    });
    if (this.logo) {
      this.tenantService.updateLogo(this.logo);
    }
  }

  onSelectLogo(event: unknown): void {
    const files = (event as { target: { files: FileList } }).target.files;
    if (files.length > 0) {
      this.logo = files[0];
      this.logoPreview = URL.createObjectURL(this.logo);
    }
  }

  getDate(data: Usage): Date {
    return new Date(`${data.y}-${data.m}-01`);
  }
}
