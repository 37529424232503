<h4 mat-dialog-title>{{ "model.add-model" | translate }}</h4>
<mat-dialog-content>
  <mat-tab-group (selectedTabChange)="onTabChange($event)">
    <mat-tab [label]="'model.invite-qrcode' | translate">
      <div class="tab-content">
        <mat-form-field class="form-select">
          <mat-label>{{
            "model-release-form.model-release-form" | translate
          }}</mat-label>
          <mat-select [(value)]="selectedFormTemplateId">
            <mat-option
              *ngFor="let formTemplate of formTemplates$ | async"
              [value]="formTemplate.id"
              >{{ formTemplate.name }}</mat-option
            >
            <mat-optgroup
              *ngIf="(genericFormTemplates$ | async)?.length > 0"
              [label]="'model-release-form.generic-forms' | translate"
            >
              <mat-option
                *ngFor="let formTemplate of genericFormTemplates$ | async"
                [value]="formTemplate.id"
                >{{ formTemplate.name }}</mat-option
              >
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
        <p>{{ "model.scan-qrcode" | translate }}</p>

        <qrcode
          class="qrcode"
          [qrdata]="qrCode"
          [width]="260"
          [errorCorrectionLevel]="'M'"
          [margin]="0"
          (click)="onOpenRegistrationForm()"
        ></qrcode>
      </div>
    </mat-tab>
    <mat-tab
      class="tab-content"
      [label]="'model.invite-email' | translate"
      *ngIf="UserRule.projectEditing | hasRule"
    >
      <div class="tab-content">
        <ng-container *ngIf="!loading; else loadingTemplate">
          <mat-form-field class="form-select">
            <mat-label>{{
              "model-release-form.model-release-form" | translate
            }}</mat-label>
            <mat-select [(value)]="selectedFormTemplateId">
              <mat-option
                *ngFor="let formTemplate of formTemplates$ | async"
                [value]="formTemplate.id"
                >{{ formTemplate.name }}</mat-option
              >
              <mat-optgroup
                *ngIf="(genericFormTemplates$ | async)?.length > 0"
                [label]="'model-release-form.generic-forms' | translate"
              >
                <mat-option
                  *ngFor="let formTemplate of genericFormTemplates$ | async"
                  [value]="formTemplate.id"
                  >{{ formTemplate.name }}</mat-option
                >
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "model.add-email" | translate }}</mat-label>
            <input
              type="text"
              matInput
              [formControl]="emailControl"
              (keyup.enter)="onAddEmailInvite()"
            />
            <mat-icon
              class="clickable-icon"
              matSuffix
              (click)="onAddEmailInvite()"
              >add</mat-icon
            >
          </mat-form-field>

          <mat-list role="list">
            <mat-list-item
              role="listitem"
              *ngFor="let emailInvite of emailInvites"
            >
              <mat-icon
                class="clickable-icon"
                matListItemIcon
                (click)="onRemoveEmailInvite(emailInvite)"
                >delete</mat-icon
              >
              <p>{{ emailInvite }}</p></mat-list-item
            >
          </mat-list>
        </ng-container>
        <ng-template #loadingTemplate>
          <lib-spinner class="spinner"></lib-spinner>
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab
      *ngIf="
        genericModelConsents?.length > 0 && UserRule.projectEditing | hasRule
      "
      [label]="'model.add-generic' | translate"
    >
      <div class="tab-content">
        <mat-form-field>
          <mat-label>{{ "search.search" | translate }}</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="modelFilter"
            (keyup)="onFilterGenericModels()"
          />
        </mat-form-field>
        <div
          class="model-list-item"
          *ngFor="let modelAndConsent of filteredModelConsents"
          (click)="onAddGenericModel(modelAndConsent.modelConsent)"
        >
          <app-model-tile
            class="model-list-item-tile"
            [model]="modelAndConsent.model"
            [modelConsent]="modelAndConsent.modelConsent"
            size="small"
          >
          </app-model-tile
          >{{ modelAndConsent.model | modelName }}
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="secondary" (click)="onClose()">
    {{ "base.close" | translate }}
  </button>
  <button
    *ngIf="selectedTab === 1"
    mat-flat-button
    color="primary"
    (click)="onSendEmailInvites()"
  >
    {{ "model.send-invites" | translate }}
  </button>
</mat-dialog-actions>
