import { inject, Pipe, PipeTransform } from '@angular/core';

import { TenantToggle } from '@verify/shared-components/models';
import { AuthService } from '../auth.service';

/**
 * Returns a boolean indicating whether given
 * toggle is enabled for this tenant
 *
 * Usage:
 *  'role-name' | hasRole
 */
@Pipe({
  name: 'hasToggle',
  standalone: true,
})
export class HasTogglePipe implements PipeTransform {
  private authService: AuthService = inject(AuthService);

  transform(value: TenantToggle): boolean {
    return (this.authService.tenant?.toggles || []).includes(value);
  }
}
