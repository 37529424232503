import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatButtonToggleChange,
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Asset,
  Model,
  ModelConsent,
  ModelConsentStatus,
  Project,
} from '@verify/shared-components/models';
import { ModelConsentService } from '../../../services/model-consent.service';
import { ModelTileComponent } from '../model-tile/model-tile.component';
import { ModelInfoComponent } from './model-info/model-info.component';

@Component({
  selector: 'app-model-list',
  imports: [
    CommonModule,
    ModelTileComponent,
    ModelInfoComponent,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
  ],
  templateUrl: './model-list.component.html',
  styleUrl: './model-list.component.scss',
})
export class ModelListComponent implements OnChanges {
  private modelConsentService = inject(ModelConsentService);
  private snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);

  @Input({ required: true })
  modelsData: Array<{
    model?: Model;
    modelConsent: ModelConsent;
    assets?: Asset[];
  }>;
  @Input()
  assets?: Asset[];
  @Input()
  asset: Asset;
  @Input()
  selectedModel: Model;
  @Input()
  showAllMode = false;
  @Input()
  project?: Project;
  @Input()
  cancelOption?: boolean;

  @Output()
  selectModel = new EventEmitter<Model>();
  @Output()
  removeModelConsent = new EventEmitter<ModelConsent>();

  selectedStatus = '';
  searchValue = '';
  filteredModelsData: Array<{
    model?: Model;
    modelConsent: ModelConsent;
    assets?: Asset[];
  }>;
  statusCounts = { signed: 0, revoked: 0, pending: 0 };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modelsData']) {
      this.statusCounts = {
        signed: (this.modelsData || []).filter(
          (modelData) =>
            modelData.modelConsent.status === ModelConsentStatus.signed,
        ).length,
        revoked: (this.modelsData || []).filter(
          (modelData) =>
            modelData.modelConsent.status === ModelConsentStatus.revoked,
        ).length,
        pending: (this.modelsData || []).filter(
          (modelData) =>
            modelData.modelConsent.status === ModelConsentStatus.pending ||
            modelData.modelConsent.status === ModelConsentStatus.extendRequest,
        ).length,
      };
      this.updateFilteredModels();
    }
  }

  onSelectModel(model: Model): void {
    if (!this.selectedModel) {
      this.showAllMode = false;
    }
    this.selectModel.emit(model);
  }

  onRemoveModelConsent(modelConsent: ModelConsent): void {
    this.removeModelConsent.emit(modelConsent);
  }

  onRevokeModelConsent(modelConsent: ModelConsent): void {
    this.modelConsentService.revokeModelConsent(modelConsent);
  }

  onUnrevokeModelConsent(modelConsent: ModelConsent): void {
    this.modelConsentService.unrevokeModelConsent(modelConsent);
  }

  onSendReminder(modelConsent: ModelConsent): void {
    this.modelConsentService.resendEmail(modelConsent).subscribe(() => {
      this.snackBar.open(
        this.translateService.instant('model.reminder-sent'),
        null,
        { duration: 5000 },
      );
    });
  }

  onShowAll(): void {
    this.showAllMode = !this.showAllMode;
  }

  onChangeStatus(event: MatButtonToggleChange): void {
    this.selectedStatus = event.value;
    this.updateFilteredModels();
  }

  onSearch(): void {
    this.updateFilteredModels();
  }

  trackByModelConsent(
    _: number,
    modelConsent: { modelConsent: ModelConsent },
  ): string {
    return modelConsent.modelConsent.modelId;
  }

  private updateFilteredModels(): void {
    this.filteredModelsData =
      this.selectedStatus || this.searchValue
        ? this.modelsData.filter(
            (modelData) =>
              (this.selectedStatus
                ? modelData.modelConsent.status === this.selectedStatus
                : true) &&
              (this.searchValue
                ? modelData.model.firstName
                    .toLowerCase()
                    .includes(this.searchValue) ||
                  modelData.model.lastName
                    .toLowerCase()
                    .includes(this.searchValue) ||
                  modelData.model.email.toLowerCase().includes(this.searchValue)
                : true),
          )
        : this.modelsData;
  }
}
