<mat-form-field
  subscriptSizing="dynamic"
  class="layout-toolbar-search"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
>
  <input
    matInput
    type="text"
    [autofocus]="true"
    [formControl]="searchInput"
    [placeholder]="'search.search' | translate"
  />
  <button
    matPrefix
    mat-icon-button
    color="primary"
    (click)="onSearch(searchInput.value)"
  >
    <mat-icon>search</mat-icon>
  </button>
</mat-form-field>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="searchOpen"
  [cdkConnectedOverlayDisposeOnNavigation]="true"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayPositionStrategy]=""
  [cdkConnectedOverlayPanelClass]="'search-results'"
  [cdkConnectedOverlayViewportMargin]="8"
  (overlayOutsideClick)="onCloseSearch()"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    },
  ]"
>
  <app-search-results [searchValue]="searchValue"></app-search-results>
</ng-template>
