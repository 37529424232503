import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { UserRole } from '@verify/shared-components/models';
import { AuthService } from '../auth.service';

export const hasRoleGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const authService: AuthService = inject(AuthService);
  const roles = route.data['roles'];

  let rolesRequired: UserRole[];
  if (!Array.isArray(roles)) {
    rolesRequired = [roles];
  } else {
    rolesRequired = roles;
  }
  for (const role of rolesRequired) {
    if (!authService.currentUser?.roles?.includes(role)) {
      return false;
    }
  }
  return true;
};
