import { Injectable, inject } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable, first, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  private auth: Auth = inject(Auth);

  constructor(private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.secure();
  }
  canActivateChild(): Observable<boolean> {
    return this.secure();
  }
  private secure(): Observable<boolean> {
    return authState(this.auth).pipe(
      first(),
      map((user) => {
        if (!user) {
          this.router.navigateByUrl('/login');
          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
