<mat-dialog-content>
  <ng-container *ngIf="pdfUrl$; else htmlContract">
    <div class="pdf-container">
      <div class="pdf-buttons">
        <button mat-icon-button (click)="zoomIn()">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button (click)="zoomOut()">
          <mat-icon>zoom_out</mat-icon>
        </button>
        <a mat-icon-button [href]="pdfUrl$ | async" target="_blank">
          <mat-icon>download</mat-icon>
        </a>
      </div>
      <pdf-viewer
        [src]="pdfUrl$ | async"
        [render-text]="false"
        [original-size]="false"
        [zoom]="pdfZoom"
        class="pdf-viewer"
      ></pdf-viewer>
      <div
        [class]="
          'pdf-status pdf-status-' +
          dialogData.modelConsent.status.toLowerCase()
        "
      >
        <span>{{
          "model.status-" + dialogData.modelConsent.status.toLowerCase()
            | translate
        }}</span
        ><span
          *ngIf="dialogData.modelConsent.status === ModelConsentStatus.signed"
        >
          {{
            dialogData.modelConsent.signedDate | timestamp: "mediumDate"
          }}</span
        >
        <span
          *ngIf="dialogData.modelConsent.status === ModelConsentStatus.revoked"
        >
          {{
            dialogData.modelConsent.revokedDate | timestamp: "mediumDate"
          }}</span
        >
      </div>
    </div>
  </ng-container>

  <ng-template #htmlContract>
    <div class="form" [innerHTML]="formText"></div>
    <div class="model-info">
      <app-model-tile
        class="model-info-selfie"
        [model]="dialogData.model"
        [modelConsent]="dialogData.modelConsent"
      ></app-model-tile>
      <div class="model-info-container">
        <div class="model-info-row">
          <span>{{ "model.first-name" | translate }}</span
          ><span>{{ dialogData.model.firstName }}</span>
        </div>
        <div class="model-info-row">
          <span>{{ "model.last-name" | translate }}</span
          ><span>{{ dialogData.model.lastName }}</span>
        </div>
        <div class="model-info-row">
          <span>{{ "model.email" | translate }}</span
          ><span>{{ dialogData.model.email }}</span>
        </div>
        <div class="model-info-row">
          <span>{{ "model.sign-date" | translate }}</span
          ><span>{{
            dialogData.modelConsent.signedDate.toDate()
              | timestamp: "mediumDate"
          }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" (click)="onClose()">
    {{ "base.close" | translate }}
  </button>
</mat-dialog-actions>
