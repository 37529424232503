<h4 mat-dialog-title>
  {{ (dialogData?.text ? dialogData.text : "base.confirm") | translate }}
</h4>

<mat-dialog-actions align="end">
  <button mat-flat-button color="secondary" (click)="onClose()">
    {{ "base.no" | translate }}
  </button>
  <button mat-flat-button color="primary" (click)="onConfirm()">
    {{ "base.yes" | translate }}
  </button>
</mat-dialog-actions>
